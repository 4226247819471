import React from 'react';
import classNames from 'classnames';
import { useSharedData } from '../Shared';

export default function MobileDividerIcon({ large, className }) {
  const { divider } = useSharedData();

  return large && divider === 'ltg' ? (
    <svg
      width="900"
      height="50"
      viewBox="0 0 900 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMax slice"
      className={classNames('divider--ltg', className)}
    >
      <path
        d="M145.1 0.100813C137.3 0.100813 129.8 3.22582 124.3 8.77017L108.2 25L124.5 41.4315C129.9 46.875 137.3 50 145 50H900V0H145.1V0.100813Z"
        fill="#F8423A"
      />
      <path
        d="M92 8.66936C86.5 3.12501 79 0 71.2 0H0V50H71.4C79.1 50 86.4 46.875 91.9 41.4315L108.2 25L92 8.66936Z"
        fill="black"
      />
    </svg>
  ) : divider === 'ltg' ? (
    <svg
      width="900"
      height="16"
      viewBox="0 0 900 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMax slice"
      className={classNames('divider--ltg', className)}
    >
      <path
        d="M97.3865 0.0321951C94.8754 0.0321951 92.4609 1.03018 90.6903 2.80081L85.5072 7.9839L90.7547 13.2314C92.4931 14.9698 94.8754 15.9678 97.3543 15.9678L900 15.9685V0.000730515L97.3865 0V0.0321951Z"
        fill="#F8423A"
      />
      <path
        d="M77.2918 2.80059C75.5211 1.02996 73.1067 0.0319729 70.5956 0.0319729L0 0.0311718V15.999L70.66 15.9998C73.1388 15.9998 75.4889 15.0018 77.2596 13.2634L82.5071 8.01588L77.2918 2.80059Z"
        fill="black"
      />
    </svg>
  ) : (
    <hr className={classNames('divider--default', className)} />
  );
}
