import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import Page from './Page';
import usePage from '../hooks/usePage';
import ContentLayout from '../common/Layout/ContentLayout';
import CompanyInformationForm from '../components/Forms/CompanyInformationForm';
import ContactInformationForm from '../components/Forms/ContactInformationForm';
import BankInformationForm from '../components/Forms/BankInformationForm';
import { useFormik } from 'formik';
import useMobx from '@Shared/hooks/useMobx';
import * as Yup from 'yup';
import Button from '../components/Button';
import { BusinessFormContent } from '../../helpers/api/requests';
import CheckIcon from '../icons/CheckIcon';
import RadioButton from '@Shared/components/inputs/RadioButton';
import MaxWidth from '@Shared/components/ui/MaxWidth';

const BusinessFormPage = observer(({ ...props }) => {
  const page = usePage();
  const data = page.data;

  const {
    form: { company, contact, bank },
  } = useMobx();

  const [formSent, setFormSent] = useState(false);

  const submitForm = async values => {
    const response = await BusinessFormContent.sendFormData(values, page.id);
    if (response.body.success) {
      setFormSent(true);
      window?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const formik = useFormik({
    initialValues: { ...company, ...contact, ...bank },
    validationSchema: Yup.object({
      companyName: Yup.string().required(`${data.field_required}`),
      companyCode: Yup.string().required(`${data.field_required}`),
      vatCode: Yup.string().required(`${data.field_required}`),
      companyAddress: Yup.string().required(`${data.field_required}`),
      postalCode: Yup.string().required(`${data.field_required}`),
      bankName: Yup.string().required(`${data.field_required}`),
      accountNumber: Yup.string().required(`${data.field_required}`),
      managerName: Yup.string().required(`${data.field_required}`),
      contactAddress: Yup.string().required(`${data.field_required}`),
      companyPhone: Yup.string().required(`${data.field_required}`),
      email: Yup.string().email(`${data.invalid_email}`).required(`${data.field_required}`),
      invoiceEmail: Yup.string().email(`${data.invalid_email}`).required(`${data.field_required}`),
      contactPerson: Yup.string().required(`${data.field_required}`),
      contactPhone: Yup.string().required(`${data.field_required}`),
      signature: Yup.boolean().required(`${data.field_required}`),
    }),
    onSubmit: values => {
      submitForm(values);
    },
  });

  const formFields = (
    <form onSubmit={formik.handleSubmit}>
      <div className="fields-wrapper">
        <CompanyInformationForm
          heading={data.company_information_labels?.subtitle}
          labels={data.company_information_labels}
          formik={formik}
        />
      </div>
      <div className="fields-wrapper">
        <ContactInformationForm
          heading={data.contact_information_labels?.subtitle}
          labels={data.contact_information_labels}
          formik={formik}
        />
      </div>
      <div className="fields-wrapper">
        <BankInformationForm
          heading={data.bank_information_labels?.subtitle}
          labels={data.bank_information_labels}
          formik={formik}
        />
      </div>
      <div className="fields-wrapper">
        <div className="_label">{data.signature_label}</div>
        <div className="radio-buttons-wrapper">
          <RadioButton
            name="signature-yes"
            height="20px"
            checked={formik.values.signature === true}
            onClick={() => formik.setFieldValue('signature', true)}
          >
            {data.yes}
          </RadioButton>
          <RadioButton
            name="signature-no"
            height="20px"
            checked={formik.values.signature === false}
            onClick={() => formik.setFieldValue('signature', false)}
          >
            {data.no}
          </RadioButton>
        </div>
      </div>
      <Button className={'form semi-rounded'} type="submit">
        {data.send}
      </Button>
    </form>
  );

  const successView = (
    <div className="success-view">
      <div className="success-wrapper">
        <div className="title">{data.account_registered}</div>
        <div className="icon-wrapper">
          <CheckIcon />
        </div>
        <div className="success-text">{data.success_text}</div>
      </div>
    </div>
  );

  return (
    <Page route={props.route} className={`business-form-page ${formSent ? '-success' : ''}`}>
      <MaxWidth>
        <ContentLayout title={data?.title}>
          <ContentLayout.Body>
            {!formSent && data && formFields}
            {formSent && successView}
          </ContentLayout.Body>
        </ContentLayout>
      </MaxWidth>
    </Page>
  );
});

export default BusinessFormPage;
