import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import MenuItemComponent from '../menus/MenuItemComponent';
import { useSharedData } from '../../Shared';

function FooterMenu({ menu }) {
  const footerMenuItems = useMemo(() => (menu.menuItems ?? []).filter(m => !!m.enabled), [menu]);
  const { t } = useSharedData();

  if (footerMenuItems.length <= 0) return null;

  return (
    <nav className="shared-footer-menu">
      <h2 className="sr-only">{t('components.footerMenu.aria.title')}</h2>
      <ul className="footer-menu">
        {footerMenuItems.map(item => (
          <li className="footer-menu-wrapper" key={item.id}>
            <MenuItemComponent className="footer-menu__item" menuItem={item} />
            <ul className="footer-menu__children">
              {item.children &&
                item.children.length > 0 &&
                item.children.map(child => (
                  <li key={child.id}>
                    <MenuItemComponent className="footer-menu__child" menuItem={child} toWebsite />
                  </li>
                ))}
            </ul>
          </li>
        ))}
      </ul>
    </nav>
  );
}
export default observer(FooterMenu);
