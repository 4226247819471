import React, { useState, useRef, useEffect } from 'react';
import Chevron from '../icons/Chevron';

export default function Accordion(props) {
  const [active, setActive] = useState('');
  const [height, setHeight] = useState('0px');

  const content = useRef(null);

  useEffect(() => {
    props.forceOpen && toggleAccordion();
  }, []);

  const toggleAccordion = () => {
    setActive(active === '' ? 'active' : '');
    setHeight(active === 'active' ? '0px' : `${content.current.scrollHeight}px`);
  };

  return (
    <button className="accordion-wrapper" onClick={toggleAccordion} tabIndex="0">
      <div className={`accordion ${active}`}>
        <div className="accordion-title">{props.title}</div>
        <Chevron className="accordion-icon" rotate={active ? '0' : '180'} color={'#676d6d'} />
      </div>
      <div ref={content} style={{ maxHeight: `${height}` }} className="accordion-content">
        <div className="accordion-text" dangerouslySetInnerHTML={{ __html: props.content }} />
      </div>
    </button>
  );
}
