import React from 'react';
import { LINK_MENU_TYPES, TYPES } from '../constants';
import { useSharedData } from '../Shared';
import { isExternalUrl, sanitizeUrl } from '../utils/urls';
import { Link, NavLink } from 'react-router-dom';

export function getMenuItemUrl(menuItem, toWebsite = false) {
  const { isWebshop, websiteUrl, webshopUrl } = useSharedData();
  return getMenuItemUrlBase({ menuItem, toWebsite, isWebshop, websiteUrl, webshopUrl });
}

export const getMenuItemUrlBase = ({ menuItem, toWebsite = false, isWebshop, websiteUrl, webshopUrl }) => {
  const baseValue = getMenuItemValue(menuItem);
  const isExternal = isExternalUrl(baseValue);
  const appUrl = sanitizeUrl(isWebshop ? webshopUrl : websiteUrl); // Ensure URL does not end with /

  let url = baseValue;

  if (!url) {
    // Fallback to current site to still show the text.
    return '#';
  }

  if (isExternal && isWebshop && url.startsWith(appUrl)) {
    url = url.slice(appUrl?.length) || '/';
  }

  if (!isExternal && toWebsite && isWebshop) {
    url = `${sanitizeUrl(websiteUrl)}/${sanitizeUrl(baseValue)}`;
  }

  return url;
};

export const getMenuItemValue = menuItem => {
  switch (menuItem?.type) {
    case TYPES.STATIC_URL:
      return menuItem.value;
    case TYPES.TEXT:
      return void 0;
    case TYPES.PAGE:
    case TYPES.PAGE_WITH_ICON:
      return menuItem.value;
    case TYPES.LINK_WITH_ICON:
      return menuItem.value;
    default:
      return null;
  }
};

export const isMenuItemLinkType = menuItem => {
  if (!menuItem) return false;
  return LINK_MENU_TYPES.includes(menuItem.type);
};

export const hasAnyEnabledChildren = menuItem => {
  if (!menuItem) return false;
  return Object.values(menuItem.children).find(child => child.enabled);
};

export function MenuItemLinkComponent({ url, menuItem, children, type = 'link', ...props }) {
  const isExternal = isExternalUrl(url);

  const LinkComponent = isExternal ? 'a' : type === 'link' ? Link : NavLink;
  const linkProps = isExternal ? { href: url, rel: 'noopener noreferrer' } : { to: url };

  return (
    <LinkComponent {...linkProps} {...props}>
      {children}
    </LinkComponent>
  );
}
