import config from '../config';
import asyncRetry from '../async-retry';
import { universalBtoa as btoa } from '../atob';

const isObjectEmpty = obj => Object.entries(obj).length === 0;

const CUSTOM_OPTS = {
  queryParams: {},
  jsonBody: {},
  formBody: {},
  forceText: false, // Load response as text instead of JSON even if content type says so
};

const DEFAULT_OPTS = {
  mode: 'cors',
  // credentials: 'include',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

const get = async (path, opts = CUSTOM_OPTS) => await fetchWithRetry(path, { ...opts, method: 'GET' });
const post = async (path, opts = CUSTOM_OPTS) => await fetchWithRetry(path, { ...opts, method: 'POST' });
const put = async (path, opts = CUSTOM_OPTS) => await fetchWithRetry(path, { ...opts, method: 'PUT' });
const patch = async (path, opts = CUSTOM_OPTS) => await fetchWithRetry(path, { ...opts, method: 'PATCH' });
const del = async (path, opts = CUSTOM_OPTS) => await fetchWithRetry(path, { ...opts, method: 'DELETE' });

const fetchWithRetry = async (path, customOpts) => {
  return await asyncRetry(async bail => await _doFetchRequest(bail, path, customOpts), {
    retries: 8,
    maxTimeout: 5000,
  });
};

const _doFetchRequest = async (bail, path, customOpts = {}) => {
  const opts = { ...DEFAULT_OPTS, ...customOpts, ...{ headers: { ...DEFAULT_OPTS.headers, ...customOpts.headers } } };

  // JSON
  if (opts.jsonBody && !isObjectEmpty(opts.jsonBody)) opts.body = JSON.stringify(opts.jsonBody);

  // FormData
  if (opts.formBody && !isObjectEmpty(opts.formBody)) {
    const formData = new FormData();
    Object.keys(opts.formBody).forEach(key => {
      const val = opts.formBody[key];
      if (!val) return;

      if (typeof val === 'object') {
        Object.values(val).forEach(item => {
          formData.append(`${key}[]`, item);
        });
      } else {
        formData.append(key, val);
      }
    });
    opts.body = formData;
    delete opts.headers['Content-Type']; // Let fetch generate boundaries automatically
  }

  // Fetch client w/ URL
  const _fetch = typeof window !== 'undefined' ? window.fetch : require('node-fetch').default;
  let url = path.slice(0, 4) === 'http' ? path : `${config.CMS_URL}/${path}`;

  // Fix basic auth for Fetch
  const basicAuthData = url.match(/(?:\/\/)(.*)(?:@)/);
  if (basicAuthData && basicAuthData[1]) {
    const credentials = basicAuthData[1];
    opts.headers.Authorization = `Basic ${btoa(credentials)}`;
    url = url.replace(`${credentials}@`, '');
  }

  // Query parameters
  if (opts.queryParams && !isObjectEmpty(opts.queryParams)) {
    const queryParams = Object.keys(opts.queryParams)
      .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(opts.queryParams[k]))
      .join('&');
    url += (url.indexOf('?') === -1 ? '?' : '&') + queryParams;
  }

  try {
    const res = await _fetch(url, opts);
    if (!res.ok) throw res;

    const contentType = res.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1 && !opts.forceText) {
      if (res.status === 204) return { response: res, body: void 0 };
      return { response: res, body: await res.json() };
    }

    return res.text().then(text => ({ response: res, body: text }));
  } catch (err) {
    if (!err.status || err.status !== 401) {
      const level = (err.status || 500) >= 500 ? 'error' : 'warn';
      console[level](`** API request failed for ${url}:`, err);
    }

    if (!err.status) return bail(_errBody);

    if (typeof err.text !== 'function' && typeof err.json !== 'function') {
      const _errBody = { response: err, body: {} };
      if (err.status >= 400 && err.status <= 500) return bail(_errBody);
      throw _errBody;
    }

    const contentType = err.headers && err.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const _errBody = { response: err, body: await err.json() };
      if (err.status >= 400 && err.status <= 500) return bail(_errBody);
      throw _errBody;
    }

    const _errBody = { response: err, body: await err.text() };
    if (err.status >= 400 && err.status <= 500) return bail(_errBody);
    throw _errBody;
  }
};

export default {
  get,
  post,
  del,
  put,
  patch,
};
