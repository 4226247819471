import BaseCookieStore from '@Shared/mobx/BaseCookieStore';
import { isProduction } from 'app/js/helpers/utils';

export default class Cookies extends BaseCookieStore {
  constructor(hydrateStore) {
    super();
    this.secure = isProduction;

    // We want to refresh cookies on client-side.
    hydrateStore(this, ['universalCookie']);
  }
}
