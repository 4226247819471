import * as dayjs from 'dayjs';
import { captureException } from '@sentry/browser';

export default class DateTime {
  constructor(hydrateStore, rootStore) {
    hydrateStore(this);
    this.setLocale(rootStore.i18n.locale);
  }

  setLocale(locale) {
    dayjs.locale(locale);
  }

  format(date, format = 'DD.MM.YYYY', locale) {
    const localizedFormat = require('dayjs/plugin/localizedFormat');
    dayjs.extend(localizedFormat);

    require('dayjs/locale/lt');
    const originalDate = date;

    try {
      date = dayjs(date);
      if (!date.isValid()) return originalDate;
      if (locale) return date.locale(locale).format(format);
      return date.format(format);
    } catch (e) {
      console.warn('Failed to parse date.', e);
      captureException(e);
      return originalDate;
    }
  }
}
