import React from 'react';

// eslint-disable-next-line no-unused-vars
export default function MenuItemText({ menuItem, className = '', toWebsite = false, isButton, ...props }) {
  const Tag = isButton ? 'button' : 'div';

  return (
    <Tag className={['menu-item__text', className].join(' ')} {...props}>
      {menuItem.name}
    </Tag>
  );
}
