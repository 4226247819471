import { get } from 'lodash';
import React from 'react';
import ButtonSection from './Sections/ButtonSection';
import DividerSection from './Sections/DividerSection';
import ImagesSection from './Sections/ImagesSection';
import LinksSection from './Sections/LinksSection';
import ScriptSection from './Sections/ScriptSection';
import StepsSection from './Sections/StepsSection';
import TextImageSection from './Sections/TextImageSection';
import WysiwygSection from './Sections/WysiwygSection';

const ContentSections = {
  wysiwyg: 'wysiwyg_section',
  images: 'image_section',
  links: 'links_section',
  steps: 'steps_section',
  divider: 'divider',
  script: 'script_section',
  button: 'button_section',
  text_image: 'text_image_section',
};

export default function ContentSection({ section }) {
  const data = get(section, 'attributes', {});
  const layout = get(section, 'layout', null);

  switch (layout) {
    case ContentSections.wysiwyg:
      return <WysiwygSection {...data} />;
    case ContentSections.images:
      return <ImagesSection images={data.images} />;
    case ContentSections.links:
      return <LinksSection links={data.links} />;
    case ContentSections.steps:
      return <StepsSection steps={data.steps} />;
    case ContentSections.divider:
      return <DividerSection />;
    case ContentSections.script:
      return <ScriptSection {...data} />;
    case ContentSections.button:
      return <ButtonSection button={data} />;
    case ContentSections.text_image:
      return <TextImageSection data={data.texts_images} />;
    default:
      return null;
  }
}
