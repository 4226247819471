import React, { useMemo, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import MobilePopup from '../ui/MobilePopup';
import MenuItemComponent from '../menus/MenuItemComponent';
import MenuPopdown from '../menus/MenuPopdown';
import { useClickAway } from 'react-use';
import MenuMobilePopupContent from '../menus/MenuMobilePopupContent';
import HeaderMenuSeoLinks from './HeaderMenuSeoLinks';
import useMobx from '../../hooks/useMobx';

function HeaderMenu({ menu, mobile, closeModals: parentCloseModals }) {
  const headerRef = useRef();
  const canOpenNewMenu = useRef(true);
  const [activeMenu, setActiveMenu] = React.useState(null);
  const [showMenu, setShowMenu] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const { ui, t } = useMobx();

  useClickAway(headerRef, () => {
    if (mobile) return;
    setShowMenu(false);
    setTimeout(() => setActiveMenu(void 0), 250);
  });

  const closeModals = () => {
    if (parentCloseModals) parentCloseModals();
    setShowMenu(false);
    setIsOpen(false);
    setTimeout(() => setActiveMenu(void 0), 250);
  };

  const headerMenuItems = useMemo(() => (menu.menuItems || []).filter(m => !!m.enabled), [menu]);
  if (headerMenuItems.length <= 0) return null;

  const onLinkClick = item => {
    if (!mobile) {
      setShowMenu(false);
      setTimeout(() => setActiveMenu(void 0), 250);
    } else if (item.children?.length > 0) {
      setActiveMenu(item);
      setIsOpen(true);
    }
  };

  const onMainLinkClick = item => {
    if (item.id === activeMenu?.id || !canOpenNewMenu.current) return;

    if (item && item.children.length > 0) {
      canOpenNewMenu.current = false;
      if (!mobile && activeMenu) setShowMenu(false);
      ui.setScreenReaderAnnouncement(t('aria.header.menu_expanded'));
      setTimeout(
        () => {
          setActiveMenu(item);

          if (mobile) {
            setIsOpen(true);
          } else {
            setShowMenu(true);
          }

          setTimeout(() => (canOpenNewMenu.current = true), 125);
        },
        activeMenu ? 125 : 0
      );
    } else {
      setShowMenu(false);
      setActiveMenu(void 0);
      closeModals();
    }
  };

  return (
    <nav className="header-menu" ref={headerRef}>
      <HeaderMenuSeoLinks menuItems={headerMenuItems} />
      {headerMenuItems.map(item => (
        <MenuItemComponent
          key={item.id}
          menuItem={item}
          onClick={() => onMainLinkClick(item)}
          className={`header-menu__item${activeMenu?.id === item.id && showMenu ? ' active' : ''}`}
          aria-expanded={activeMenu?.id === item.id && showMenu}
          isButton
        />
      ))}

      {mobile && activeMenu && (
        <MobilePopup
          show={isOpen}
          className="popup-header -secondary"
          header
          isNestedModal
          closeModals={closeModals}
          toggle={() => {
            setIsOpen(false);
            setTimeout(() => setActiveMenu(void 0), 250);
          }}
          withDivider
        >
          <MenuMobilePopupContent
            item={activeMenu}
            closeModals={closeModals}
            toggle={() => {
              setIsOpen(false);
              setTimeout(() => setActiveMenu(void 0), 250);
            }}
          />
        </MobilePopup>
      )}

      {!mobile && (
        <MenuPopdown
          show={showMenu}
          menu={activeMenu}
          onLinkClick={onLinkClick}
          close={() => {
            setShowMenu(false);
            setTimeout(() => setActiveMenu(void 0), 250);
          }}
        />
      )}
    </nav>
  );
}

export default observer(HeaderMenu);
