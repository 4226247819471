import { observer } from 'mobx-react-lite';
import React, { useMemo } from 'react';
import MenuItemIcon from '@Shared/components/menus/menu-items/MenuItemIcon';
import useMobx from '@Shared/hooks/useMobx';
import { IMAGE_SIZES } from '@Shared/constants';

function CommonMenu({ className, close }) {
  const { i18n, content } = useMobx();

  const commonMenu = useMemo(() => content.getMenu('common-menu', i18n.locale) ?? [], [i18n.locale]);
  const commonMenuItems = useMemo(() => (commonMenu.menuItems ?? []).filter(m => !!m.enabled), [i18n.locale]);
  if (!commonMenuItems) return null;

  return (
    <div className={['common-menu', className].join(' ')}>
      {commonMenuItems.map(item => {
        return (
          item.value && (
            <div className="common-menu__item" key={item.id} onClick={close}>
              <MenuItemIcon menuItem={item} size={IMAGE_SIZES.ICON_LARGE} />
            </div>
          )
        );
      })}
    </div>
  );
}

export default observer(CommonMenu);
