const APP_AUTH_TOKEN_KEY = 'turnit_app_auth_access_token';

const callFlutterHandler = (...args) => {
  if (window?.flutter_inappwebview?.callHandler) {
    window.flutter_inappwebview.callHandler(...args);
  }
};

const getMobileAppAccessToken = () => {
  return window.localStorage.getItem(APP_AUTH_TOKEN_KEY);
};

const logoutMobileApp = () => {
  window.postMessage('turnitLogOut', '*'); // Origin can be any - we don't send any sensitive data
  callFlutterHandler('LogOut');
};

const isTurnitApp = () => {
  return window.navigator.userAgent.includes('Turnit-App');
};

export { callFlutterHandler, isTurnitApp, logoutMobileApp, getMobileAppAccessToken };
