import Content from './Content';
import DateTime from './DateTime';
import Device from './Device';
import I18n from './I18n';
import Notices from './Notices';
import Stops from './Stops';
import Form from './Form';
import Banners from './Banners';
import Cookies from './Cookies';
import Settings from './Settings';
import Ui from './Ui';
import Toasts from './Toasts';
import Search from './Search';

export default {
  content: Content,
  i18n: I18n,
  device: Device,
  notices: Notices,
  datetime: DateTime,
  stops: Stops,
  form: Form,
  banners: Banners,
  cookies: Cookies,
  settings: Settings,
  ui: Ui,
  toasts: Toasts,
  search: Search,
};

export const helpers = store => ({
  t: store.i18n.translate,
  date: store.datetime.format,
});
