import { action, makeObservable, observable } from 'mobx';
import { supportsWebp } from '../../helpers/feature-detection';

export default class Device {
  constructor(hydrateStore) {
    hydrateStore(this);
    makeObservable(this);

    if (typeof window !== 'undefined') supportsWebp(webpSupport => this.setWebpSupport(webpSupport));
  }

  @observable webpSupport = false;

  @action setWebpSupport(webpSupport) {
    this.webpSupport = webpSupport;
  }
}
