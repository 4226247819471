import React, { useRef, useCallback } from 'react';
import { isClickEvent } from '../../utils/wcag';

export default function RadioButton({
  name,
  value,
  onChange,
  checked = false,
  children,
  height = '24px',
  className,
  disabled,
  color,
  onClick,
  ...props
}) {
  const inputRef = useRef();

  const classNames = useCallback(
    (base = []) => {
      const _classNames = base;
      if (disabled) _classNames.push('-disabled');
      if (checked) _classNames.push('-checked');
      return _classNames.join(' ');
    },
    [disabled, checked]
  );

  return (
    <li
      role="radio"
      className={classNames(['radio-button', className])}
      {...props}
      onClick={onClick}
      onKeyDown={e => {
        if (isClickEvent(e)) {
          onClick(e);
        }
      }}
      tabIndex={0}
      aria-checked={checked}
      aria-owns={name}
      aria-controls={name}
      aria-labelledby={`label_${name}`}
    >
      <div style={{ height, width: height }} className={classNames(['radio-button__input'])}>
        <span className={classNames(['radio-button__check'])} />
      </div>

      {/* Hidden input to handle state */}
      <input
        style={{ display: 'none' }}
        ref={inputRef}
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        type="radio"
        tabIndex={-1}
      />

      <label id={`label_${name}`} className={classNames(['radio-button__label'])}>
        {children}
      </label>
    </li>
  );
}
