import React, { useContext, useEffect, useState } from 'react';
import { PwaModalProvider } from './components/pwa/PwaModalProvider';

const SharedDataContext = React.createContext();

const defaultSharedData = {
  isWebshop: false,
  isTurnitApp: false,
  webshopUrl: '',
  websiteUrl: '',
  locale: '',
  locales: {},
  t: () => null,
  setLocale: () => null,
  showBasket: false,
  basketValidUntilUTC: null,
  isLoggedIn: false,
  handleLogout: () => null,
  client: 'default',
  logoSize: 'default',
};

function setupSharedData(sharedData = defaultSharedData) {
  const [props, setProps] = useState(sharedData);

  useEffect(() => {
    setProps(sharedData);
  }, [JSON.stringify(sharedData)]);

  return props;
}

/**
 * @returns {defaultSharedData}
 */
function useSharedData() {
  const sharedData = useContext(SharedDataContext);
  return { ...defaultSharedData, ...sharedData };
}

const SharedDataProvider = ({ children, ...props }) => {
  return (
    <SharedDataContext.Provider {...props}>
      <PwaModalProvider>{children}</PwaModalProvider>
    </SharedDataContext.Provider>
  );
};

export { SharedDataProvider, setupSharedData, useSharedData };
