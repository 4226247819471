import { action, computed, makeObservable, observable } from 'mobx';
import { NoticeContent } from '../../helpers/api/requests';
import BaseNoticeStore from '@Shared/mobx/BaseNoticeStore';

export const NOTICE_VISIBILITY = {
  HOME: 'HOME',
  URGENT: 'URGENT', // Urgent notice
};

export default class Notices extends BaseNoticeStore {
  constructor(hydrateStore) {
    super();
    hydrateStore(this);
    makeObservable(this);
  }
  @observable data = {};
  @observable error = null;

  @action saveNotice(notice) {
    this.data[notice.id] = notice;
  }

  @action saveNotices(notices = []) {
    notices.forEach(notice => this.saveNotice(notice));
  }

  @action setError(error = {}) {
    this.error = error;
  }

  @action clearError() {
    this.setError(null);
  }

  @action async fetchNotices(options = { params: {} }) {
    try {
      // Attempt to request notices
      const response = await NoticeContent.getAll({ queryParams: options.params });
      this.saveNotices(response.body);
    } catch (error) {
      // Log to errors
      console.error('[fetchNotices]', error);
      this.error = error;
    }
  }

  @action async fetchNoticeBySlug(slug) {
    const translatedSlug = typeof slug === 'object' ? this.rootStore.t(slug) : slug;
    if (!translatedSlug) return null;

    // Check for existing notice;
    const existingNotice = this.getNoticeBySlug(slug);
    if (existingNotice && existingNotice.id) return existingNotice;

    try {
      const response = await NoticeContent.get(translatedSlug, { queryParams: { locale: this.rootStore.i18n.locale } });

      if (response.body && response.body.id) {
        console.log(`Notice fetched [${response.body.id}]`);
        this.saveNotice(response.body);

        return response.body;
      }
    } catch (error) {
      console.warn('[fetchNoticeBySlug]', error);
      this.error = error;
    }

    return null;
  }

  @action async fetchLatestNotice() {
    try {
      const response = await NoticeContent.getLatest();
      this.saveNotice(response.body);

      return response.body;
    } catch (error) {
      console.warn('[fetchLatestNotice]', error);
      this.error = error;

      return null;
    }
  }

  @computed get notices() {
    return Object.values(this.data)
      .filter(notice => {
        const title = this.rootStore.t(notice.title, { fallback: false });
        const summary = this.rootStore.t(notice.summary, { fallback: false });

        return title && summary;
      })
      .sort((a, b) => {
        return a.priority > b.priority ? 1 : -1;
      });
  }

  @computed get urgentNotices() {
    return this.notices.filter(notice => (notice.visibility ?? []).includes(NOTICE_VISIBILITY.URGENT));
  }

  getNoticesByVisibility(visibility) {
    return this.notices.filter(notice => (notice.visibility ?? []).includes(visibility));
  }

  getNoticeBySlug(slug) {
    if (typeof slug === 'object') console.warn('[MOBX getNoticeBySlug] Translated slug value expected');
    return this.notices.find(notice => this.rootStore.t(notice.slug) === slug);
  }
}
