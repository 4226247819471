import retrier from 'retry';

const retry = (fn, opts) => {
  const run = (resolve, reject) => {
    const options = opts || {};

    // Default `randomize` to true
    if (!('randomize' in opts)) opts.randomize = true;

    const op = retrier.operation(options);

    // We allow the user to abort retrying
    // this makes sense in the cases where
    // knowledge is obtained that retrying
    // would be futile (e.g.: auth errors)

    const bail = err => reject(err || new Error('Aborted'));

    const onError = (err, num) => {
      if (err.bail) {
        bail(err);
        return;
      }

      if (!op.retry(err)) {
        reject(op.mainError());
      } else if (options.onRetry) {
        options.onRetry(err, num);
      }
    };

    const runAttempt = num => {
      let val = void 0;

      try {
        val = fn(bail, num);
      } catch (err) {
        onError(err, num);
        return;
      }

      Promise.resolve(val)
        .then(resolve)
        .catch(err => onError(err, num));
    };

    op.attempt(runAttempt);
  };

  return new Promise(run);
};

export default retry;
