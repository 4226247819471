const supportsWebp = callback => {
  if (typeof self === 'undefined') {
    callback(false);
    return;
  }

  const img = new Image();
  img.onload = () => callback(true);
  img.onerror = () => callback(false);
  img.src = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
};

export { supportsWebp };
