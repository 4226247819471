import React from 'react';
import classNames from 'classnames';
import Image from '../ui/media/Image';
import Video from '../ui/media/Video';

export default function HeroBackground({ media, breakpoint }) {
  if (!media || !media.id) return null;
  const isMediaVideo = media.is_video;

  return (
    <div
      className={classNames('hero__background', {
        [`-${breakpoint}`]: breakpoint,
        '-video': isMediaVideo,
        '-image': !isMediaVideo,
      })}
    >
      {isMediaVideo && <Video video={media} loop autoPlay playsInline muted />}
      {!isMediaVideo && <Image img={media} showPlaceholder={false} />}
    </div>
  );
}
