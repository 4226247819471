import { observable, action, runInAction } from 'mobx';
import { SharedCookieKeys } from '../constants';
import { hasGivenCookieConsent } from '../components/gdpr/utils';

export default class BaseUIStore {
  @observable screenReaderAnnouncement = '';
  @observable showCookieNotice = false;
  @observable siteWrapperPadding = false;
  announcerTimeoutId = 0;

  constructor(rootStore) {
    this.showCookieNotice = !hasGivenCookieConsent(rootStore.cookies.get(SharedCookieKeys.COOKIE_CONSENT));
  }

  @action setScreenReaderAnnouncement(string) {
    if (this.announcerTimeoutId) {
      clearTimeout(this.announcerTimeoutId);
      this.announcerTimeoutId = 0;
      this.screenReaderAnnouncement = '';
    }
    this.announcerTimeoutId = setTimeout(() => {
      runInAction(() => {
        this.screenReaderAnnouncement = string;
      });
    }, 1000);
  }

  @action setShowCookieNotice(show = true) {
    this.showCookieNotice = show;
  }

  @action setSiteWrapperPadding(value) {
    this.siteWrapperPadding = value;
  }
}
