import { useField } from 'formik';
import React, { forwardRef, useCallback, useMemo, useRef, useState } from 'react';
import { FORMIK_KEYS_TO_REMOVE } from '../../../helpers/utils';
import useMobx from '@Shared/hooks/useMobx';

const TextInput = forwardRef(
  (
    {
      label,
      name,
      value,
      type = 'text',
      error,
      onBlur,
      onFocus,
      onChange = () => {},
      required,
      disabled,
      hideError = false,
      ...props
    },
    ref
  ) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isTouched, setIsTouched] = useState(false);
    const inputRef = useRef();

    const {
      i18n: { translate },
    } = useMobx();

    const showError = !isFocused && isTouched && error;
    const _onFocus = useCallback(
      e => {
        setIsFocused(true);
        setIsTouched(false);
        if (onFocus) onFocus(e);
      },
      [onFocus]
    );

    const _onBlur = useCallback(
      e => {
        setIsFocused(false);
        setIsTouched(true);
        if (onBlur) onBlur(e);
      },
      [onBlur]
    );

    const inputStateClasses = useMemo(() => {
      const classNames = [props.className];
      classNames.push(isFocused ? 'focus' : 'blur');
      classNames.push(!value ? 'empty' : 'not-empty');
      if (showError) classNames.push('error');
      if (required) classNames.push('required');
      if (disabled) classNames.push('disabled');
      if (isTouched) classNames.push('touched');
      return classNames.join(' ');
    }, [isFocused, showError, value, required, disabled, isTouched, props.className]);

    return (
      <div
        ref={ref}
        className={`input-group ${inputStateClasses}`}
        onClick={() => (inputRef.current ? inputRef.current.focus() : void 0)}
      >
        <label>
          <div className="_label" htmlFor={name}>
            {label || ''}
          </div>
          <div className="input-wrapper">
            <input
              type={type}
              name={name}
              value={value || ''}
              onBlur={_onBlur}
              onFocus={_onFocus}
              ref={inputRef}
              required={required}
              disabled={disabled}
              onChange={onChange}
              {...props}
              className={'_input'}
            />
          </div>
        </label>

        <div
          className="_error-text"
          dangerouslySetInnerHTML={{ __html: showError && !hideError ? translate(error) || '' : '' }}
        />
      </div>
    );
  }
);

export default TextInput;

export const FormikTextInput = forwardRef(({ error, onChange, ...props }, ref) => {
  const safeProps = { ...props };
  const [field, meta] = useField(safeProps.name);

  FORMIK_KEYS_TO_REMOVE.forEach(key => delete safeProps[key]);
  if (!safeProps.label) console.warn('TextInput component is missing a label.');
  if (!safeProps.name) console.warn('TextInput is missing a name.');

  return <TextInput {...field} {...safeProps} ref={ref} value={field.value} error={error || meta.error} />;
});
