import React from 'react';

export default function Chevron({ className, rotate }) {
  const transform = `rotate(${rotate}deg)`;
  return (
    <svg
      className={className || ''}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform }}
    >
      <path
        d="M13.1387 11.8067C13.2649 11.6815 13.3359 11.5111 13.3359 11.3333C13.3359 11.1556 13.2649 10.9852 13.1387 10.86L8.47207 6.19333C8.3469 6.06712 8.1765 5.99613 7.99874 5.99613C7.82098 5.99613 7.65058 6.06712 7.52541 6.19333L2.85874 10.86C2.68963 11.0291 2.62359 11.2756 2.68549 11.5066C2.74738 11.7376 2.92782 11.918 3.15882 11.9799C3.38982 12.0418 3.6363 11.9758 3.80541 11.8067L7.99874 7.60667L12.1921 11.8067C12.3172 11.9329 12.4876 12.0039 12.6654 12.0039C12.8432 12.0039 13.0136 11.9329 13.1387 11.8067Z"
        fill="currentColor"
      />
    </svg>
  );
}
