import { SiteContent } from '../helpers/api/requests';
import logWarning from '../helpers/log';
import { SELECTED_COOKIE_NAME } from './stores/I18n';

/**
 * Called in react/Main.js
 *
 * Populate mobx with initial data before first render.
 * This function should be called once on the server side.
 */
export async function hydrateInitialStore({ store, previewToken }) {
  try {
    // Cache initial response for 60 seconds (cache is disabled in dev)
    const response = await SiteContent.getInitial({ preview: previewToken })
      .then(res => res.body)
      .catch(() => null);

    console.debug('Fetched initial content');

    if (!response) {
      logWarning('No initial content returned from CMS!');
    } else {
      await hydrateContent(store.content, response);
      await hydrateI18n(store, response);
    }
  } catch (e) {
    logWarning('Initial store hydration failed on server!', e);
  }

  return store;
}

async function hydrateContent(content, initialData = {}) {
  content.setRoutes(initialData.routes || []);
  content.setSeo(initialData.seo || {});
  content.setMenus(initialData.menus || {});
  content.setRegions(initialData.regions || {});
  content.setMeta(initialData.meta || {});
  content.setTranslations(initialData.translations || {});
  content.setScripts(initialData.scripts);
}

async function hydrateI18n({ i18n, cookies }, initialData) {
  i18n.locales = initialData.locales;
  const defaultLocale = initialData.locales.find(l => l.default)?.slug;
  await i18n.setLocale(cookies.get(SELECTED_COOKIE_NAME) || defaultLocale || i18n.fallbackLocale);
  await i18n.loadInitialTranslationFiles();
}
