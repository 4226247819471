import React from 'react';

function ScreenReaderAnnouncer({ message }) {
  return (
    <div aria-live="assertive" className="sr-only">
      {message}
    </div>
  );
}

export default ScreenReaderAnnouncer;
