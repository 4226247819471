/**
 *  TODO:
 * Probably should remove the usage of btoa and atob since it's
 * giving a deprecation notice. I was unable to get the buffer package working
 * outside of local environment. Polyfilling is required.
 *
 * @param {string|Object} value
 * @returns
 */
export const encode = value => {
  const encodedValue = encodeURIComponent(typeof value === 'object' ? JSON.stringify(value) : value);
  return typeof window === 'undefined' ? Buffer.from(encodedValue).toString('base64') : window.btoa(encodedValue);
};

/**
 * @param {string} value Encoded value
 * @returns {string|Object} Decoded value
 */
export const decode = value => {
  const decodedValue = decodeURIComponent(
    typeof window === 'undefined' ? Buffer.from(value, 'base64').toString() : window.atob(value)
  );

  try {
    return JSON.parse(decodedValue);
  } catch {
    // String can't be parsed
    return decodedValue;
  }
};
