import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { SkipNavContent } from '@reach/skip-nav';

export default function Page({ route, className, children }) {
  const template = (route && route.template) ?? null;
  const { hash } = useLocation();
  const scrolledRef = React.useRef(false);

  useEffect(() => {
    // Scrolls to hash in URL if target exists
    if (hash && !scrolledRef.current) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
        scrolledRef.current = true;
      }
    }
  });

  return (
    <main className={['page', className, template].join(' ')}>
      <SkipNavContent />
      {children}
    </main>
  );
}
