import React from 'react';

export default function LanguageIcon({ size = '16', color = 'var(--nav-icon-color)' }) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 1.33334C4.31808 1.33334 1.33331 4.31811 1.33331 8.00001C1.33331 11.6819 4.31808 14.6667 7.99998 14.6667C11.6819 14.6667 14.6666 11.6819 14.6666 8.00001C14.6666 6.2319 13.9643 4.53621 12.714 3.28596C11.4638 2.03572 9.76809 1.33334 7.99998 1.33334ZM13.2866 7.33334H12C11.9384 6.02894 11.6021 4.75224 11.0133 3.58668C12.2729 4.45217 13.1007 5.81642 13.2866 7.33334ZM7.33331 7.33334V2.87334C6.38665 3.44001 5.50665 5.13334 5.33331 7.33334H7.33331ZM7.33331 8.66668V13.1267C6.38665 12.56 5.50665 10.8667 5.33331 8.66668H7.33331ZM8.66665 8.66668V13.1267C9.61331 12.56 10.4933 10.8667 10.6666 8.66668H8.66665ZM8.66665 7.33334V2.87334C9.61331 3.44001 10.4933 5.13334 10.6666 7.33334H8.66665ZM3.99998 7.33334C4.06782 6.02748 4.41087 4.75069 5.00665 3.58668C3.7218 4.43867 2.86848 5.80495 2.66665 7.33334H3.99998ZM2.71331 8.66668H3.99998C4.0616 9.97108 4.39781 11.2478 4.98665 12.4133C3.72706 11.5479 2.89928 10.1836 2.71331 8.66668ZM12 8.66668C11.9321 9.97254 11.5891 11.2493 10.9933 12.4133C12.2782 11.5614 13.1315 10.1951 13.3333 8.66668H12Z"
        fill={color}
      />
    </svg>
  );
}
