import UniversalCookie from 'universal-cookie';
import { getTopLevelCookieDomain } from '../utils/cookies';

const ONE_YEAR = 365 * 24 * 60 * 60;
const DEFAULT_OPTIONS = {
  sameSite: 'Lax',
  path: '/',
  httpOnly: false,
  maxAge: ONE_YEAR,
};

/**
 * TODO: Since we don't really need mobx observe logic here
 * we should move this to a regular context provider instead.
 * Currently kept the mobx approach to save time.
 */
export default class BaseCookieStore {
  universalCookie = new UniversalCookie();
  secure = false;

  setUniversalCookie(universalCookie) {
    this.universalCookie = universalCookie;
  }

  _createChangeListener(cookieName, callback) {
    return ({ name, value, ...params }) => {
      if (cookieName === name) {
        try {
          value = JSON.parse(value);
        } catch {
          // Oh well.
        }

        callback({ name, value, ...params });
      }
    };
  }

  addChangeListener(name, callback) {
    this.universalCookie.addChangeListener(this._createChangeListener(name, callback));
  }

  removeChangeListener(name, callback) {
    this.universalCookie.removeChangeListener(this._createChangeListener(name, callback));
  }

  setSharedCookie(name, value, options = {}) {
    // Hmm, might need to find a solution for this.
    // currently window is required for getTopLevelCookieDomain function.
    if (typeof window === 'undefined') return;
    this.set(name, value, { ...options, domain: getTopLevelCookieDomain() });
  }

  set(name, value, options = {}) {
    this.universalCookie.set(name, value, {
      secure: this.secure,
      ...DEFAULT_OPTIONS,
      ...options,
    });
  }

  get(name) {
    const value = this.universalCookie.get(name);
    if (value) return value;
    return void 0;
  }

  delete(name, options = {}) {
    this.universalCookie.remove(name, options);
  }

  deleteSharedCookie(name, options = {}) {
    // Hmm, might need to find a solution for this.
    // currently window is required for getTopLevelCookieDomain function.
    if (typeof window === 'undefined') return;
    this.delete(name, { ...options, domain: getTopLevelCookieDomain() });
  }
}
