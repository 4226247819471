import { trim } from './str';
import { useSharedData } from '../Shared';

const isExternalUrl = url => {
  return (
    url &&
    (url.startsWith('https://') || url.startsWith('http://') || url.startsWith('tel:') || url.startsWith('mailto:'))
  );
};

const sanitizeUrl = url => {
  return trim(url, '/');
};

const isWebshopUrl = url => {
  const { webshopUrl } = useSharedData();
  return url.startsWith(webshopUrl);
};

const isWebsiteUrl = url => {
  const { websiteUrl } = useSharedData();
  return url.startsWith(websiteUrl);
};

const changeElementLinksTarget = (element, target = '_blank') => {
  if (element) {
    const links = element.getElementsByTagName('a');

    for (let i = 0; i < links?.length; i++) {
      links[i].target = target;
    }
  }
};

export { isExternalUrl, sanitizeUrl, isWebshopUrl, isWebsiteUrl, changeElementLinksTarget };
