import React, { useRef, useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';

const CollapsibleWrapper = props => {
  const duration = props.duration || 500;
  const [animateHeight, setAnimateHeight] = useState(false);

  const collapsibleRef = useRef(null);

  const handleOpen = () => {
    if (props.focusDropdown && !props.isOpen && collapsibleRef && window.innerWidth < 768) {
      const scrollOptions = { block: 'start', inline: 'nearest', behavior: 'smooth' };
      setTimeout(() => collapsibleRef.current.scrollIntoView(scrollOptions), 250);
    }
    props.clicked(!props.isOpen);
  };

  // Used to save timeout ID across render without using state
  const prevTimeoutRef = useRef();
  useEffect(() => {
    // if we clicked before animation ended we clear timeout to stay in sync with our isOpen prop
    if (prevTimeoutRef.current) clearTimeout(prevTimeoutRef.current);
    if (props.isOpen) {
      // isOpen was false in the previous render so we set state for animating children in
      prevTimeoutRef.current = setTimeout(() => {
        setAnimateHeight(true);
      });
    } else if (!props.isOpen) {
      // isOpen was true in the previous render so we set state for animating children out before unmount
      prevTimeoutRef.current = setTimeout(() => {
        setAnimateHeight(false);
      }, duration);
    }
  }, [props.isOpen]);

  const label = (
    <>
      {props.icon && <div className="_icon">{props.icon}</div>}
      <div className="_label">{props.label}</div>
      {props.alwaysOpen || props.disableChevron ? null : (
        <div className="collapsible-chevron" aria-hidden>
          <div className="chevron-wrapper">
            <Chevron rotate={props.isOpen ? 180 : 0} color={props.chevronColor} />
          </div>
        </div>
      )}
    </>
  );

  let labelWrapper;
  if (props.alwaysOpen && props.label) {
    labelWrapper = props.label && (
      <div className="collapsible-label open" id={`${props.name}-label`}>
        {label}
      </div>
    );
  } else if (props.label) {
    labelWrapper = props.label && (
      <button
        onClick={handleOpen}
        className={`collapsible-label btn ${props.isOpen ? 'open' : ''}`}
        aria-controls={`${props.name}-panel`}
        aria-expanded={props.isOpen}
        id={`btn-${props.name}`}
        aria-label={props.ariaLabel}
      >
        {label}
      </button>
    );
  }

  let height = animateHeight ? 'auto' : 0;
  if (animateHeight && !props.isOpen) height = 0;
  if (props.alwaysOpen) height = 'auto';

  return (
    <div className={`collapsible ${props.className}`} ref={collapsibleRef}>
      <div className="collapsible-content-wrapper">
        {labelWrapper}
        <AnimateHeight
          duration={duration}
          height={height}
          className="collapsible-content"
          aria-expanded={props.isOpen}
          aria-label={props.ariaLabel}
          id={`${props.name}-panel`}
        >
          {props.isOpen || animateHeight || props.alwaysOpen ? props.children : <div />}
        </AnimateHeight>
      </div>
    </div>
  );
};

const Chevron = ({ className = '', color = 'var(--primary)', rotate }) => {
  let style = {};
  if (rotate !== null) style = { transform: `rotate(${rotate}deg)` };

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M4.29 9.29C4.10069 9.47777 3.9942 9.73336 3.9942 10C3.9942 10.2666 4.10069 10.5222 4.29 10.71L11.29 17.71C11.4778 17.8993 11.7334 18.0058 12 18.0058C12.2666 18.0058 12.5222 17.8993 12.71 17.71L19.71 10.71C19.9637 10.4563 20.0627 10.0866 19.9699 9.74012C19.877 9.39362 19.6064 9.12297 19.2599 9.03012C18.9134 8.93728 18.5437 9.03634 18.29 9.29L12 15.59L5.71 9.29C5.52223 9.10069 5.26664 8.9942 5 8.9942C4.73336 8.9942 4.47777 9.10069 4.29 9.29Z"
        fill={color}
      />
    </svg>
  );
};

CollapsibleWrapper.propTypes = {
  // Emits warning if "name" property is not defined - required for a11y
  name: props => {
    if (!props?.name) {
      console.warn(
        `Collapse (${props.className}) is missing "name" property! Please define unique "name" for every collapse.`
      );
    }
  },
};

export default CollapsibleWrapper;
