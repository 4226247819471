import React from 'react';

export default function TicketIcon({ color = 'var(--nav-icon-color)', innerColor = 'transparent' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g>
        <path d="M16,9H14V7H4v3l2,2L4,14v3H14V15h2v2h4V14l-2-2,2-2V7H16Zm0,4H14V11h2Z" fill={innerColor} />
        <path
          d="M20.09,5H3.9A1.9,1.9,0,0,0,2,7v4l.5.5L3,12l-.5.5L2,13v4a1.9,1.9,0,0,0,1.9,2H20.09A1.9,1.9,0,0,0,22,17.2V13l-.5-.5L21,12l.5-.5L22,11V6.8A1.9,1.9,0,0,0,20.09,5ZM20,10l-2,2,2,2v3H16V15H14v2H4V14l2-2L4,10V7H14V9h2V7h4Z"
          fill={color}
        />
        <rect x="14" y="11" width="2" height="2" fill={color} />
      </g>
    </svg>
  );
}
