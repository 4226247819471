import React, { useCallback, useMemo } from 'react';
import ExclamationIcon from '../../icons/ExclamationIcon';
import CloseIconSmall from '../../icons/CloseIconSmall';
import { createBreakpoint } from 'react-use';
import useMobx from '@Shared/hooks/useMobx';

const useBreakpoint = createBreakpoint({ Mobile: 768, Desktop: 769 });

export default function Alert({ title, body, variant = 'error', children, onClick, onClose }) {
  const classNames = useMemo(() => {
    const _className = ['alert'];
    if (onClick) _className.push('-has-action');
    if (variant) _className.push(`-${variant}`);
    return _className.join(' ');
  }, [onClick, variant]);

  const { t } = useMobx();

  const bp = useBreakpoint();

  const onAlertClose = useCallback(e => {
    /**
      Due to having nested onClicks,
      we use stopPropagation to avoid triggering parent's onClick
     */
    e.stopPropagation();
    e.preventDefault();
    onClose(e);
  });

  const isMobile = bp === 'Mobile';
  let bodyText = isMobile ? body.substring(0, 230) : body;
  if (isMobile && body.length > 230) bodyText += '...';

  return (
    <div className={classNames} onClick={onClick}>
      <div className="alert__actions">
        <h3 className={`alert__title -${variant}`}>
          <div className="alert__icon">
            {/* {variant === 'error' && <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />} */}
            {/* {variant === 'success' && <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />} */}
            {(variant === 'info' || variant === 'warning') && <ExclamationIcon aria-hidden="true" />}
          </div>
          {title}
        </h3>
        {onClose && (
          <button className="alert__close" onClick={onAlertClose}>
            <span className="sr-only">{t('components.alert.aria.dismiss')}</span>
            <CloseIconSmall aria-hidden />
          </button>
        )}
      </div>

      <div className={`alert__body -${variant}`}>{children || (body && <p>{bodyText}</p>)}</div>
    </div>
  );
}
