import React from 'react';
import { observer } from 'mobx-react-lite';
import Image from '../ui/media/Image';
import { useSharedData } from '../../Shared';
import useMobx from '../../hooks/useMobx';
import PhoneIcon from '../../icons/PhoneIcon';
import MailIcon from '../../icons/MailIcon';
import SettingsIcon from '../../icons/SettingsIcon';
import { callFlutterHandler } from '../../utils/turnit-app';

const FooterRegion = ({ region }) => {
  const { ui } = useMobx();
  const { locale, mainLogo, isCookieConsentEnabled, isTurnitApp, t } = useSharedData();

  const handleManageCookiesClick = () => ui.setShowCookieNotice(true);

  const footerData = region && region.data && region.data[locale];
  if (!footerData) return null;

  const openExternalLink = () => {
    callFlutterHandler('OpenExternalPage');
  };

  return (
    <div className="footer__region">
      <div className="footer__region__content">
        <ul className="footer__region__list contact">
          <FooterRegionLink
            href={footerData.phone}
            extension="tel:"
            ariaLabel={footerData.phone_label}
            icon={<PhoneIcon />}
          />
          <FooterRegionLink
            href={footerData.email}
            extension="mailto:"
            ariaLabel={footerData.email_label}
            icon={<MailIcon />}
          />
        </ul>

        {isCookieConsentEnabled && !isTurnitApp && (
          <ul className="footer__region__list actions">
            <FooterRegionAction
              onClick={handleManageCookiesClick}
              icon={<SettingsIcon />}
              label={t('components.footerRegion.manageCookies')}
            />
          </ul>
        )}

        <ul className="footer__region__list social">
          {footerData.social_media_content.map((item, idx) => (
            <FooterRegionLink
              key={idx}
              href={item.attributes?.url}
              label={
                <Image
                  src={item.attributes?.logo?.image_sizes.original?.url}
                  alt={item.attributes?.logo?.alt}
                  size="icon"
                />
              }
              ariaLabel={`${item.attributes?.logo?.title} ${t('components.footerRegion.social_media_link')}`}
              onClick={openExternalLink}
            />
          ))}
        </ul>
      </div>

      <div className="footer__region__logo" aria-hidden="true">
        <Image img={mainLogo} />
      </div>
    </div>
  );
};

const FooterRegionAction = ({ onClick, label, icon }) => {
  return (
    <li>
      <button aria-label={label} type="button" className="btn footer__region__action" onClick={onClick}>
        {icon}
        {label}
      </button>
    </li>
  );
};

const FooterRegionLink = ({ href, label, icon, extension = '', ariaLabel, onClick }) => {
  if (!href) return null;

  return (
    <li onClick={onClick}>
      <a
        className="footer__region__link"
        rel="noopener noreferrer"
        aria-label={ariaLabel}
        target="_blank"
        href={`${extension}${href}`}
      >
        {icon}
        {label || href}
      </a>
    </li>
  );
};

export default observer(FooterRegion);
