export const TYPES = {
  STATIC_URL: 'static-url',
  TEXT: 'text',
  PAGE: 'page-link',
  PAGE_WITH_ICON: 'page-icon',
  LINK_WITH_ICON: 'link-icon',
  TEXT_WITH_ICON: 'text-icon',
};

export const LINK_MENU_TYPES = [TYPES.STATIC_URL, TYPES.PAGE, TYPES.PAGE_WITH_ICON, TYPES.LINK_WITH_ICON];

export const IMAGE_SIZES = {
  ICON_SMALL: 'icon-small',
  ICON_LARGE: 'icon-large',
};

export const SharedCookieKeys = {
  BASKET: 'turnit_basket',
  A11Y_WIDGET: 'turnit_a11y_widget',
  ACCESS_TOKEN: 'turnit_webshop_access_token',
  COOKIE_CONSENT: 'turnit_gdpr_cookie_consent',
  LOCALE: 'selected_public_locale',
  CURRENCY: 'turnit_selected_currency',
  AFFILIATE_ID: 'affiliate',
};
