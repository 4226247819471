import React from 'react';

export default function ChevronIcon({ className, rotate, color = 'var(--primary)' }) {
  const transform = `rotate(${rotate}deg)`;

  return (
    <svg
      className={className || ''}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform, color }}
    >
      <path
        d="M13.1362 10.4697C13.2624 10.3447 13.3333 10.1745 13.3333 9.997C13.3333 9.81947 13.2624 9.64929 13.1362 9.52428L8.47246 4.86362C8.34736 4.73757 8.17707 4.66667 7.99942 4.66667C7.82177 4.66667 7.65148 4.73757 7.52638 4.86362L2.8626 9.52428C2.6936 9.69317 2.6276 9.93933 2.68946 10.17C2.75132 10.4007 2.93164 10.5809 3.1625 10.6428C3.39336 10.7046 3.63968 10.6386 3.80868 10.4697L7.99942 6.27513L12.1902 10.4697C12.3153 10.5958 12.4855 10.6667 12.6632 10.6667C12.8408 10.6667 13.0111 10.5958 13.1362 10.4697Z"
        fill="currentColor"
      />
    </svg>
  );
}
