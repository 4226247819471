import { nanoid } from 'nanoid';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { callFlutterHandler } from '../../utils/turnit-app';

export const PwaModalContext = React.createContext([]);

export const usePwaModalListener = () => {
  const id = useRef(nanoid());
  const { openModals, setOpenModals, setFlutterDialog, resetFlutter, setFlutterModalCloseCallback } =
    useContext(PwaModalContext);

  const addModalPwaListener = callback => {
    if (typeof callback !== 'function') {
      throw new Error('Invalid type of callback provided');
    }

    if (!openModals.find(m => m.id === id.current)) {
      setFlutterDialog(true);
      setFlutterModalCloseCallback(callback);
      setOpenModals([...openModals, { id: id.current, callback }]);
    } else {
      console.warn('Duplicate id provided to listener.');
    }
  };

  const removeModalPwaListener = () => {
    const newOpenModals = openModals.filter(m => m.id !== id.current);
    setOpenModals(newOpenModals);

    if (newOpenModals.length > 0) {
      const lastCallback = newOpenModals[newOpenModals.length - 1].callback;
      setFlutterModalCloseCallback(lastCallback);
    } else {
      resetFlutter();
    }
  };

  return { addModalPwaListener, removeModalPwaListener };
};

export const PwaModalProvider = ({ children }) => {
  const [openModals, setOpenModals] = useState([]);
  const pathname = typeof window === 'undefined' ? null : window.location.pathname;

  const setFlutterDialog = state => {
    callFlutterHandler('SetInCloseableDialog', state);
  };

  const setFlutterModalCloseCallback = callback => {
    // TODO: confirm the global function name with app developers.
    if (!window.turnit_modals) window.turnit_modals = {};
    window.turnit_modals.dismiss = callback;
  };

  const resetFlutter = () => {
    setFlutterDialog(false);
    setFlutterModalCloseCallback(() => {});
  };

  useEffect(() => {
    setOpenModals([]);
    resetFlutter();
  }, [pathname]);

  useEffect(() => {
    if (openModals.length <= 0) resetFlutter();
  }, [openModals]);

  return (
    <PwaModalContext.Provider
      value={{ openModals, setOpenModals, resetFlutter, setFlutterModalCloseCallback, setFlutterDialog }}
    >
      {children}
    </PwaModalContext.Provider>
  );
};
