import React, { useState } from 'react';
import useMobx from '@Shared/hooks/useMobx';
import CloseIcon from '../../../../shared/js/icons/CloseIconSmall';
import MapIcon from '../icons/MapIcon';
import SearchIcon from '../icons/SearchIcon';
import { generateWebshopUrl } from '../../helpers/go-to';
import Image from '@Shared/components/ui/media/Image';
import DividerIcon from '@Shared/icons/DividerIcon';
import MailIcon from '@Shared/icons/MailIcon';
import PhoneIcon from '@Shared/icons/PhoneIcon';
import { useSharedData } from '@Shared/Shared';

export default function SidePanelContent({
  selectedStation,
  close,
  serviceSubtitle,
  serviceNearSubtitle,
  searchStation,
  selectSubtitle,
}) {
  const { i18n, stops, content } = useMobx();
  const locale = i18n.locale;
  const [selectValue, setSelectValue] = useState(null);
  const { divider } = useSharedData();

  const stopOptions = () =>
    stops.allStops
      ?.filter(stp => stp.id !== selectedStation.id)
      .map((stop, key) => {
        return (
          <option key={key} value={stop?.turnit_stop?.stop_id}>
            {stop?.name[locale]}
          </option>
        );
      });

  return (
    selectedStation && (
      <div className="side-panel-content-wrapper">
        <div className="image-wrapper">
          <Image img={selectedStation.stop_image} />
          <div className="station-name-wrapper">
            {selectedStation.name[locale]}
            <button className="close-button" aria-label="close" onClick={close}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className="lower-content">
          {divider === 'ltg' && <DividerIcon medium />}
          <div className="info-section">
            <div className="contacts-wrapper">
              {selectedStation.turnit_stop.address.street && (
                <div className="contact">
                  <div className="icon">
                    <MapIcon />
                  </div>
                  <div className="value">{selectedStation.turnit_stop.address.street}</div>
                </div>
              )}
              {selectedStation.phone && (
                <div className="contact">
                  <div className="icon">
                    <PhoneIcon />
                  </div>
                  {
                    <a aria-label="phone number" href={`tel:${selectedStation.phone}`}>
                      {selectedStation.phone}
                    </a>
                  }
                </div>
              )}
              {selectedStation.email && (
                <div className="contact">
                  <div className="icon">
                    <MailIcon />
                  </div>
                  {
                    <a aria-label="email" href={`mailto:${selectedStation.email}`}>
                      {selectedStation.email}
                    </a>
                  }
                </div>
              )}
            </div>
            {selectedStation.map_image?.image_sizes.original?.url && (
              <div className="map-wrapper">
                <a
                  aria-label="station's location on google maps"
                  target="_blank"
                  href={`http://www.google.com/maps/place/${selectedStation.turnit_stop.coordinates.latitude},${selectedStation.turnit_stop.coordinates.longitude}`}
                >
                  <Image img={selectedStation.map_image} />
                </a>
              </div>
            )}
          </div>

          {selectedStation.additional_image?.image_sizes.original?.url && (
            <div className="additional_image">
              <Image img={selectedStation.additional_image} />
            </div>
          )}

          <div className="services-section">
            {selectedStation.services.length > 0 && <div className="subtitle">{serviceSubtitle}</div>}
            <div className="services">
              {selectedStation.services.map((service, key) => {
                return (
                  <div key={key} className="service-wrapper">
                    <Image className="icon" img={service.icon} />
                    <div className="service-name">{service.name[locale]}</div>
                  </div>
                );
              })}
            </div>

            {selectedStation.services_near.length > 0 && <div className="subtitle">{serviceNearSubtitle}</div>}
            <div className="services">
              {selectedStation.services_near?.map((service, key) => {
                return (
                  <div key={key} className="service-wrapper">
                    <Image className="icon" img={service.icon} />
                    <div className="service-name">{service.name[locale]}</div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="subtitle">{selectSubtitle}</div>
          <div className="destination-selection">
            <select
              name="departures"
              id="departures"
              defaultValue="baseValue"
              onChange={newValue => {
                setSelectValue(newValue.target.value);
              }}
            >
              <option disabled value="baseValue">
                {searchStation}
              </option>
              {stopOptions()}
            </select>

            {content.webshopUrl && (
              <a
                href={generateWebshopUrl(
                  content.webshopUrl
                    ? `${content.webshopUrl}/journeys?oStop=${selectedStation?.turnit_stop.stop_id}&dStop=${selectValue}`
                    : '/'
                )}
              >
                <div className="button primary">
                  <SearchIcon />
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    )
  );
}
