import React from 'react';

export default function PhoneIcon({ color = 'currentColor' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="icon">
        <path
          d="M15 1H8.29C6.47 1 5 2.47 5 4.29V19.06C5 20.87 6.47 22.35 8.29 22.35H15.55C16.29 22.35 17.01 22.05 17.52 21.52C18.03 20.99 18.3 20.31 18.28 19.58V4.29C18.28 2.47 16.81 1 15 1ZM7.04 4.29C7.04 3.6 7.6 3.04 8.29 3.04H15C15.69 3.04 16.25 3.6 16.25 4.29V4.76H7.05V4.29H7.04ZM7.04 6.8H16.24V14.81H7.04V6.8ZM16.06 20.09C15.93 20.23 15.75 20.3 15.55 20.3H8.29C7.6 20.3 7.04 19.74 7.04 19.05V16.84H16.24V19.58C16.24 19.59 16.24 19.6 16.24 19.61C16.25 19.85 16.13 20.01 16.06 20.09Z"
          fill={color}
        />
        <path
          d="M12.37 17.5498H10.92C10.36 17.5498 9.89999 18.0098 9.89999 18.5698C9.89999 19.1298 10.36 19.5898 10.92 19.5898H12.37C12.93 19.5898 13.39 19.1298 13.39 18.5698C13.39 18.0098 12.93 17.5498 12.37 17.5498Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
