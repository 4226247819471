import React, { useMemo } from 'react';

function Heading({ children, level = 'h1', visual, underline, tag, className, color }) {
  const Tag = tag || level;
  const visualLevel = visual || level;

  const classNames = useMemo(() => {
    const _classNames = ['heading', className, visualLevel];
    if (underline) _classNames.push('underline');
    return _classNames.join(' ');
  }, [visualLevel, underline, className]);

  return (
    <Tag className={classNames}>
      <span style={{ color }}>{children}</span>
    </Tag>
  );
}

export default Heading;
