import MaxWidth from '@Shared/components/ui/MaxWidth';
import { computed } from 'mobx';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import useMobx from '@Shared/hooks/useMobx';
import { NOTICE_VISIBILITY } from '../../mobx/stores/Notices';
import Hero from '../common/Hero/Hero';
import NoticeAlerts from '../common/Notices/NoticesAlert';
import NoticesBoard from '../common/Notices/NoticesBoard';
import Banner, { BANNER_ACTION_TYPES } from '../components/Banners/Banner';
import Banners from '../components/Banners/Banners';
import DestinationGuide from '../components/Pages/Home/DestinationGuide';
import CommonMenu from '../components/Menus/CommonMenu';
import usePage from '../hooks/usePage';
import Page from './Page';
import ContentLayout from '../common/Layout/ContentLayout';

function HomePage({ ...props }) {
  const { notices, banners, i18n } = useMobx();
  const page = usePage();
  const locale = i18n.locale;

  useEffect(() => {
    const fetchNotices = async () => await notices.fetchNotices();
    fetchNotices();
    const fetchBanners = async () => await banners.fetchBanners();
    banners.data.length < 1 && fetchBanners();

    return () => {
      notices.clearError();
      banners.clearError();
    };
  }, []);

  const HomePageNotices = computed(() => notices.getNoticesByVisibility(NOTICE_VISIBILITY.HOME)).get();

  const getBannerType = data => {
    if (data?.slider_title[locale] || data?.slider_subtitle[locale]) {
      return BANNER_ACTION_TYPES.HOVER;
    }
    if (data?.button_text[locale]) {
      return BANNER_ACTION_TYPES.BUTTON;
    }
    return BANNER_ACTION_TYPES.NONE;
  };

  const bannersFiltered = banners.data.filter(banner => {
    return banner.visibility?.find(e => {
      return e === page.path;
    });
  });

  return (
    <Page route={props.route} className="home-page">
      <NoticeAlerts />
      <Hero {...page.data.hero} />
      <MaxWidth>
        <ContentLayout>
          <ContentLayout.Body>
            <div className="home-content">
              <CommonMenu className="home-page" />
              <NoticesBoard
                notices={HomePageNotices}
                title={page.data?.notices?.title}
                seeMore={page.data?.notices?.see_more}
              />
              <Banners className="home-page" group="large">
                {bannersFiltered.map((banner, i) => (
                  <Banner data={banner} key={i} locale={locale}>
                    <Banner.Action type={getBannerType(banner)} data={banner} locale={locale} />
                  </Banner>
                ))}
              </Banners>
              {page.data?.destination_guide && <DestinationGuide data={page.data?.destination_guide} />}
            </div>
          </ContentLayout.Body>
        </ContentLayout>
      </MaxWidth>
    </Page>
  );
}

export default observer(HomePage);
