import React, { useState } from 'react';
import Alert from './Alert';
import { createPortal } from 'react-dom';
import { useIsomorphicLayoutEffect } from 'react-use';

export default function PopupAlert({ ...props }) {
  const [canRender, setCanRender] = useState(false);
  useIsomorphicLayoutEffect(() => setCanRender(true), []);

  return canRender && createPortal(<Alert {...props} />, document.querySelector('.popup-alerts'));
}

export function PopupAlerts() {
  return <div className="popup-alerts"></div>;
}
