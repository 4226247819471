import React from 'react';

export default function SlimCheckmarkIcon({ color = 'var(--primary)', rotate = 0 }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${rotate}deg)` }}
    >
      <path
        d="M19.29 6.29L10 15.59L5.71 11.29C5.31788 10.8979 4.68212 10.8979 4.29 11.29C3.89788 11.6821 3.89788 12.3179 4.29 12.71L9.29 17.71C9.47777 17.8993 9.73337 18.0058 10 18.0058C10.2666 18.0058 10.5222 17.8993 10.71 17.71L20.71 7.71C21.1021 7.31788 21.1021 6.68212 20.71 6.29C20.3179 5.89788 19.6821 5.89788 19.29 6.29Z"
        fill={color}
      />
    </svg>
  );
}
