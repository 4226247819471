import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import InfoIcon from '../../icons/InfoIcon';
import WarningIcon from '../../icons/WarningIcon';

const TypeIcons = {
  warning: <WarningIcon />,
  error: <WarningIcon />,
  info: <InfoIcon />,
  success: <InfoIcon />,
};

const BaseToast = ({ icon: IconComponent, type: overrideType, toast, children, onHide, className }) => {
  const timeoutRef = useRef(null);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight('auto');
    timeoutRef.current = setTimeout(() => handleHide(), 6000);
    return () => timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  const handleHide = () => {
    setHeight(0);
    onHide();
  };

  const onMouseEnter = () => {
    clearTimeout(timeoutRef.current);
  };

  const onMouseLeave = () => {
    timeoutRef.current = setTimeout(() => handleHide(), 6000);
  };

  const type = overrideType || toast.type.toLowerCase();
  const Icon = IconComponent || TypeIcons[type];

  return (
    <div className="shared-toast-wrapper">
      <div
        className={classNames('toast', { [`_${type}`]: true }, className)}
        onClick={handleHide}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <AnimateHeight
          contentClassName={'toast_content'}
          duration={250}
          height={height}
          applyInlineTransitions={false}
          animateOpacity
        >
          {Icon && <div className="_icon">{Icon}</div>}
          <div className="_text-content">
            <div className="_content">{children}</div>
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

export default BaseToast;
