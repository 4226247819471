import React from 'react';
import { useSharedData } from '../../Shared';
import RadioButton from '../inputs/RadioButton';

export default function HeaderCurrencySelectorItems({ onItemClick }) {
  const { currency, currencies, setCurrency } = useSharedData();

  return (
    <ul className="remove-list-styles" role="radiogroup">
      {currencies.map(cKey => (
        <RadioButton
          key={cKey.code}
          height="16px"
          onClick={() => {
            setCurrency(cKey.code);
            if (onItemClick) onItemClick();
          }}
          name={`locale_${cKey.code}`}
          checked={cKey.code === currency}
        >
          {cKey.label}
        </RadioButton>
      ))}
    </ul>
  );
}
