import { getAppConfig } from './utils';

const { IS_PRODUCTION = true, DISPLAY_WARNINGS = false } = getAppConfig();

export default function logWarning(message, ...args) {
  if (typeof window !== 'undefined' && (!IS_PRODUCTION || DISPLAY_WARNINGS)) {
    window.console.warn(message, ...args);
  } else if (typeof window === 'undefined' && (!IS_PRODUCTION || (IS_PRODUCTION && DISPLAY_WARNINGS))) {
    console.warn(message, ...args);
  }
}
