import MaxWidth from '@Shared/components/ui/MaxWidth';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import useMobx from '@Shared/hooks/useMobx';
import NoticePageContent from '../../common/Notices/NoticePageContent';
import Page from '../Page';

function NoticesDetailPage(props) {
  const { notices } = useMobx();

  useEffect(() => {
    return () => {
      notices.clearError();
    };
  }, []);

  return (
    <Page {...props}>
      <MaxWidth>
        <NoticePageContent />
      </MaxWidth>
    </Page>
  );
}

NoticesDetailPage.getPageData = async ({ store, match }) => {
  const { notices } = store;
  const noticeSlug = match.params.slug;
  let notice;

  if (!noticeSlug) notice = await notices.fetchLatestNotice();
  else notice = await notices.fetchNoticeBySlug(noticeSlug);

  if (!notice || !notice.id) return { status: 'NOT_FOUND' };

  await notices.fetchNotices();

  store.content.setCurrentMatch({
    id: notice.id,
    paths: notice.slug,
  });

  if (!noticeSlug) return { notice, status: 'UPDATE_PATH' };
  return { notice, status: 'FOUND' };
};

export default observer(NoticesDetailPage);
