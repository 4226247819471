import DividerIcon from '@Shared/icons/DividerIcon';
import MobileDividerIcon from '@Shared/icons/MobileDividerIcon';
import React from 'react';

export default function DividerSection() {
  return [
    <DividerIcon key="desktop" className="content__divider -desktop" />,
    <MobileDividerIcon key="mobile" className="content__divider -mobile" />,
  ];
}
