import React from 'react';

export default function StepsSection({ steps = [] }) {
  return (
    <div className="content__steps-section">
      <ol>
        {steps.map((step, key) => {
          return <li key={key}>{step.attributes.text}</li>;
        })}
      </ol>
    </div>
  );
}
