import Image from '@Shared/components/ui/media/Image';
import { get } from 'lodash';
import React from 'react';
import Heading from '../../../Heading/Heading';

export default function TextImageSection({ data = [] }) {
  return (
    <div className="content__text-image-section">
      {data.map((item, key) => {
        return (
          <div className="textimage" key={key}>
            <div className="image-wrapper">
              <Image className="image" img={get(item, 'attributes.img')} />
            </div>
            <Heading tag="div" visual="h4">
              {get(item, 'attributes.heading')}
            </Heading>
            <div className="description">{get(item, 'attributes.description')}</div>
          </div>
        );
      })}
    </div>
  );
}
