import React from 'react';

export default function InfoIcon({ color = 'currentColor', invert = false, className = '' }) {
  return (
    <svg className={className} width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <g fill={invert ? 'none' : color}>
        <path
          fill={invert ? color : 'none'}
          d="M12,20a8,8,0,1,0-8-8A8,8,0,0,0,12,20ZM12,6a1,1,0,1,1-1,1A1,1,0,0,1,12,6Zm-1,4a1,1,0,0,1,2,0v7a1,1,0,0,1-2,0Z"
        />
        <path d="M12,22A10,10,0,1,0,2,12,10,10,0,0,0,12,22ZM12,4a8,8,0,1,1-8,8A8,8,0,0,1,12,4Z" />
        <path d="M12,18a1,1,0,0,0,1-1V10a1,1,0,0,0-2,0v7A1,1,0,0,0,12,18Z" />
        <circle cx="12" cy="7" r="1" />
      </g>
    </svg>
  );
}
