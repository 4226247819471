import BaseToast from '@Shared/components/toasts/BaseToast';
import useMobx from '@Shared/hooks/useMobx';
import React from 'react';

export default function Toast({ toast }) {
  const { toasts, t } = useMobx();

  const handleHide = () => setTimeout(() => toasts.hideToast(toast.id), 250);

  return (
    <BaseToast toast={toast} onHide={handleHide}>
      {t(toast.body) ? `${t(toast.body)} [${toast.errorCode}]` : toast.bodySlug}
    </BaseToast>
  );
}
