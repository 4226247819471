import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory, useRouteMatch } from 'react-router';
import useMobx from '@Shared/hooks/useMobx';
import config from '../../../helpers/config';
import { observer } from 'mobx-react-lite';
import SeoLink from '@Shared/components/links/SeoLink';

const LocaleRouteProvider = observer(({ route, children }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [alternatePaths, setAlternatePaths] = useState([]);
  const { i18n, content, t } = useMobx();

  useEffect(() => {
    setAlternatePaths(content.getPageAlternatePaths(route, match.params));
  }, [route, match.params, i18n.locale]);

  useEffect(() => {
    if (i18n.locale !== route.locale) {
      let localePath;
      const alternatePath = t(alternatePaths, { fallback: false });
      if (!alternatePath || !alternatePath.path) localePath = content.getPagePathByTemplate('home-page');
      else localePath = alternatePath.path;
      if (history) history.replace(localePath);
    }
  }, [i18n.locale]);

  return (
    <>
      <Helmet>
        {Object.entries(alternatePaths).map(([locale, path]) => (
          <link rel="alternate" key={locale} hrefLang={locale} href={`${config.APP_URL}${path.path}`} />
        ))}
      </Helmet>
      {Object.entries(alternatePaths).map(([locale, path]) => {
        // Alternate lang paths for crawlers
        return (
          <SeoLink key={locale} href={`${config.APP_URL}${path.path}`}>
            {locale}
          </SeoLink>
        );
      })}

      {children}
    </>
  );
});

export default LocaleRouteProvider;
