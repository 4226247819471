import React from 'react';
import MenuItemText from './MenuItemText';
import { getMenuItemUrl, MenuItemLinkComponent } from '../../../utils/menu';

export default function MenuItemLink({ menuItem, onClick, className = '', toWebsite = false, ...props }) {
  const url = getMenuItemUrl(menuItem, toWebsite);
  if (!url) return null;

  return (
    <MenuItemLinkComponent
      url={url}
      target={menuItem.target}
      className={['menu-item__icon', className].join(' ')}
      onClick={onClick}
      {...props}
    >
      <MenuItemText menuItem={menuItem} onClick={onClick} />
    </MenuItemLinkComponent>
  );
}
