import React from 'react';
import MaxWidth from '../ui/MaxWidth';
import FooterMenu from './FooterMenu';
import FooterRegion from './FooterRegion';
import { observer } from 'mobx-react-lite';
import DividerIcon from '../../icons/DividerIcon';
import MobileDividerIcon from '../../icons/MobileDividerIcon';
import { useSharedData } from '../../Shared';

const Footer = ({ menu, region }) => {
  const { divider } = useSharedData();

  return (
    <footer className={`shared-footer -divider-${divider}`}>
      <MaxWidth>
        <FooterMenu menu={menu} />
        <FooterRegion region={region} />
        <DividerIcon className="divider -desktop" large />
        <MobileDividerIcon className="divider -mobile" large />
      </MaxWidth>
    </footer>
  );
};
export default observer(Footer);
