import { parseDomain, ParseResultType, fromUrl } from 'parse-domain';

// Expiry
export const EXPIRES_NEXT_YEAR = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
export const MAX_AGE_FOREVER = 2147483647;

export const getTopLevelCookieDomain = () => {
  const host = window.location.href;
  const parsedDomain = parseDomain(fromUrl(host));

  switch (parsedDomain.type) {
    case ParseResultType.Invalid:
      return null;
    case ParseResultType.Reserved:
      // Localhost
      return `.${parsedDomain.labels.slice(-2).join('.')}`;
    case ParseResultType.Listed: {
      // ngrok does not allow setting cookies on subdomains
      if (['ngrok.io'].find(h => parsedDomain.hostname.includes(h))) return parsedDomain.hostname;

      // Validate that domain is not already top domain (webshop.test)
      if (parsedDomain.labels.length <= 2) {
        return `.${parsedDomain.labels.join('.')}`;
      }

      // Re-combile the domain to get only main domain
      // webshop.dev.turnit.tech -> .dev.turnit.tech
      return `.${parsedDomain.labels.splice(1).join('.')}`;
    }
    case ParseResultType.Ip:
      return parsedDomain.hostname;
    default:
      throw new Error(`Invalid domain: ${window.location.href}`);
  }
};
