import React, { useEffect, useRef, useState } from 'react';
import { useMountedState } from 'react-use';
import getMediaSrc from '../../../utils/media';

function Image({ img, src, size = 'original', show = true, showPlaceholder = true, webp, ...props }, ref) {
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [renderEmpty, setRenderEmpty] = useState(false);
  const isMounted = useMountedState();
  const isFirstRender = useRef(true);

  useEffect(() => {
    setFailedToLoad(false);
  }, [img && img.id]);

  // Get images data
  const alt = (img && img.alt) || ''; // Use empty string instead "undefined" for screen reader
  const title = (img && img.title) || void 0;
  const imageUrl = getMediaSrc(img, size, webp) || src;

  useEffect(() => {
    if (!isFirstRender.current) {
      if (isMounted()) setRenderEmpty(true);
      setTimeout(() => isMounted() && setRenderEmpty(false), 0);
    }
    isFirstRender.current = false;
  }, [imageUrl]);

  if (renderEmpty) return null;

  if ((img || src) && !failedToLoad && imageUrl)
    return (
      <img
        onError={() => setFailedToLoad(true)}
        ref={ref}
        src={show ? imageUrl : void 0}
        alt={alt}
        title={title}
        {...props}
      />
    );

  if (showPlaceholder && (failedToLoad || !imageUrl)) return <div className="image -placeholder" />;

  return null;
}

export default React.forwardRef(Image);
