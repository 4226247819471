import { action, makeObservable, observable, remove, values } from 'mobx';

export default class BaseToastStore {
  @observable toasts = observable.object({});

  constructor() {
    makeObservable(this);
  }

  onToastAdd = () => {
    // Impliment optionally
  };

  @action addToast = toast => {
    // If toast is already in the list then don't add duplicate
    if (!this.hasToastWithMessage(toast?.bodySlug)) {
      this.toasts = { ...this.toasts, [toast.id]: toast };
    }

    this.rootStore.ui.setScreenReaderAnnouncement(toast.bodySlug);
    this.onToastAdd(toast);
  };

  @action hideToast = toastId => {
    remove(this.toasts, toastId);
  };

  hasToastWithMessage = textSlug => {
    return !!values(this.toasts).find(exToast => exToast?.bodySlug === textSlug);
  };

  showSuccessToast = textSlug => {
    this.addToast(createToast({ textSlug, type: 'SUCCESS' }));
  };

  showErrorToast = textSlug => {
    this.addToast(createToast({ textSlug, type: 'ERROR' }));
  };

  showInfoToast = textSlug => {
    this.addToast(createToast({ textSlug, type: 'INFO' }));
  };

  showWarningToast = textSlug => {
    this.addToast(createToast({ textSlug, type: 'WARNING' }));
  };
}

export const createToast = ({ textSlug, body, errorCode, type = 'SUCCESS' }) => ({
  id: Math.random().toString(36).substr(2, 10),
  type,
  titleSlug: `toast.${type}`,
  bodySlug: textSlug,
  body,
  errorCode,
});
