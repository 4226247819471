import useMobx from '@Shared/hooks/useMobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import HeroBackground from '@Shared/components/hero/HeroBackground';
import Search from './Search';

function Hero({ background_desktop, background_mobile, background_color, title_color }) {
  const { settings } = useMobx();

  return (
    <div className="hero" style={{ backgroundColor: background_color }}>
      {!settings.highContrast && (
        <>
          <HeroBackground media={background_desktop} breakpoint="desktop" />
          <HeroBackground media={background_mobile} breakpoint="mobile" />
        </>
      )}
      <Search titleColor={title_color} />
    </div>
  );
}

export default observer(Hero);
