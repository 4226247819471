import { action, makeObservable, observable } from 'mobx';

export default class Form {
  @observable company = {
    companyName: '',
    companyCode: '',
    vatCode: '',
    companyAddress: '',
    postalCode: '',
  };
  @observable contact = {
    contactAddress: '',
    companyPhone: '',
    email: '',
    invoiceEmail: '',
    contactPerson: '',
    contactPhone: '',
  };
  @observable bank = {
    bankName: '',
    accountNumber: '',
    managerName: '',
    cardCount: '1',
    signature: true,
  };

  constructor(hydrateStore) {
    hydrateStore(this);
    makeObservable(this);
  }

  @action setSignature(value) {
    this.signature = value;
  }
  @action setCompanyValues(values) {
    this.company = values;
  }

  @action setContactValues(values) {
    this.contact = values;
  }

  @action setBankValues(values) {
    this.bank = values;
  }
}
