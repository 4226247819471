import React from 'react';

export default function SearchIcon({ color = 'currentColor' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.9999 17C17.8659 17 20.9999 13.866 20.9999 10C20.9999 6.13401 17.8659 3 13.9999 3C10.1339 3 6.99994 6.13401 6.99994 10C6.99994 13.866 10.1339 17 13.9999 17ZM13.9999 5C16.7614 5 18.9999 7.23858 18.9999 10C18.9999 12.7614 16.7614 15 13.9999 15C11.2385 15 8.99994 12.7614 8.99994 10C8.99994 7.23858 11.2385 5 13.9999 5ZM2.99414 20C2.99414 19.7334 3.10063 19.4778 3.28994 19.29L7.28994 15.29L8.70994 16.71L4.70994 20.71C4.52217 20.8993 4.26658 21.0058 3.99994 21.0058C3.7333 21.0058 3.47771 20.8993 3.28994 20.71C3.10063 20.5222 2.99414 20.2666 2.99414 20Z"
        fill={color}
      />
    </svg>
  );
}
