import React from 'react';

export default function CloseIcon({ ...props }) {
  return props.large ? (
    <svg width="32" height="32" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 3L34.6049 35" stroke="currentColor" strokeWidth="6" />
      <path d="M35 3L3.39506 35" stroke="currentColor" strokeWidth="6" />
    </svg>
  ) : (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 6L30 30M30 6L6 30" stroke="currentColor" strokeWidth="2" />
    </svg>
  );
}
