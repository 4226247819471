import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import HeaderRightButtons from './HeaderRightButtons';
import MobilePopup from '../ui/MobilePopup';
import HeaderMenu from './HeaderMenu';
import HamburgerIcon from '../../icons/HamburgerIcon';
import MaxWidth from '../ui/MaxWidth';
import HeaderBasketButton from './HeaderBasketButton';
import HeaderLogo from './HeaderLogo';
import { SkipNavLink } from '@reach/skip-nav';
import { useSharedData } from '../../Shared';
import HeaderAccountArea from './HeaderAccountArea';
import { callFlutterHandler } from '@Shared/utils/turnit-app';

function Header({ menu = {}, BasketComponent, SignInComponent, isAdminAccount }) {
  const { t, isTurnitApp, client } = useSharedData();
  const [isOpen, setIsOpen] = useState(false);
  const mobileMenuBtn = useRef();

  useEffect(() => {
    if (!window.turnit_app) window.turnit_app = {};
    window.turnit_app.closeBurgerMenu = () => {
      setIsOpen(false);
    };
  }, []);

  const handleModalOpenState = value => {
    setIsOpen(value);
    callFlutterHandler('BurgerMenuOpened', value);
  };

  return (
    <>
      <header className="shared-header">
        <MaxWidth className="_content">
          <HeaderLogo />
          <SkipNavLink>{t('buttons.skip_to_content')}</SkipNavLink>
          <HeaderMenu menu={menu} />
          {!!BasketComponent ? <BasketComponent /> : <HeaderBasketButton />}
          <HeaderRightButtons SignInComponent={SignInComponent} isAdminAccount={isAdminAccount} />

          {isTurnitApp && client === 'ltg' ? (
            <HeaderAccountArea device="mobile" SignInComponent={SignInComponent} />
          ) : (
            <>
              <button
                className="_hamburger-btn"
                onClick={() => handleModalOpenState(true)}
                aria-label={t('components.mobileMenu.toggleOpen.aria.label')}
                aria-expanded="false"
                ref={mobileMenuBtn}
              >
                <HamburgerIcon />
              </button>

              <MobilePopup
                show={isOpen}
                parentRef={mobileMenuBtn}
                className="popup-header"
                withDivider
                header
                toggle={() => handleModalOpenState(!isOpen)}
              >
                <HeaderMenu menu={menu} mobile closeModals={() => handleModalOpenState(false)} />
                <HeaderRightButtons
                  closeModals={() => handleModalOpenState(false)}
                  device="mobile"
                  SignInComponent={SignInComponent}
                  isAdminAccount={isAdminAccount}
                />
              </MobilePopup>
            </>
          )}
        </MaxWidth>
      </header>
    </>
  );
}

export default observer(Header);
