import React from 'react';
import MenuItemIcon from './menu-items/MenuItemIcon';
import MenuItemLink from './menu-items/MenuItemLink';
import MenuItemText from './menu-items/MenuItemText';
import MenuItemTextIcon from './menu-items/MenuItemTextIcon';
import { TYPES } from '../../constants';

export default function MenuItemComponent({ menuItem = {}, toWebsite = false, isButton, ...props }) {
  if (!menuItem) return null;

  switch (menuItem.type) {
    case TYPES.STATIC_URL:
    case TYPES.PAGE:
      return <MenuItemLink menuItem={menuItem} toWebsite={toWebsite} {...props} />;
    case TYPES.TEXT:
      return <MenuItemText menuItem={menuItem} toWebsite={toWebsite} isButton={isButton} {...props} />;
    case TYPES.TEXT_WITH_ICON:
      return <MenuItemTextIcon menuItem={menuItem} toWebsite={toWebsite} {...props} />;
    case TYPES.PAGE_WITH_ICON:
      // case TYPES.CATEGORY_WITH_ICON:
      return <MenuItemIcon toWebsite={toWebsite} {...props} />;
    default:
      return null;
  }
}
