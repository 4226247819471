/* eslint-disable no-unused-vars */
import React from 'react';
import Image from '../../ui/media/Image';
import { IMAGE_SIZES } from '../../../constants';

export default function MenuItemTextIcon({
  menuItem,
  size = IMAGE_SIZES.ICON_SMALL,
  className = '',
  toWebsite = false,
  ...props
}) {
  const icon = (menuItem.data && menuItem.data.icon) ?? null;

  return (
    <div aria-disabled className={['menu-item__text_icon', className].join(' ')} {...props}>
      <Image img={icon} size={size} />
      {menuItem.name}
    </div>
  );
}
