// Polyfills
import 'core-js/stable';
import 'expose-loader?exposes=moment!moment';
import Main from 'app/js/react/Main';
import { render } from '@optimistdigital/create-frontend/universal-react/client';
import * as Sentry from './helpers/sentry';
import { getRoutes, isProduction } from './helpers/utils';
import { createStore } from './mobx';
import * as ServiceWorker from './helpers/register-service-worker';

// Register Sentry
Sentry.init();

// Register service worker
ServiceWorker.register();

function hydrate() {
  if (!isProduction) console.info('Initial store:', window.__OCF_APP_DATA__.pageData?.store);
  render(Main, document.getElementById('react-app'), {
    routes: getRoutes(),
    store: createStore(),
  });
}

hydrate();

/**
 * Hot module replacement
 * Uncomment the code to enable HMR in your app. Documentation:
 * https://webpack.js.org/api/hot-module-replacement/
 */
if (module.hot) {
  module.hot.accept(['app/js/react/Main', './mobx', './helpers/utils', './helpers/log'], () => {
    hydrate();
  });
}
