import React from 'react';
import Image from '../../ui/media/Image';
import MenuItemText from './MenuItemText';
import { IMAGE_SIZES } from '../../../constants';
import { getMenuItemUrl, MenuItemLinkComponent } from '../../../utils/menu';

export default function MenuItemIcon({
  menuItem,
  size = IMAGE_SIZES.ICON_SMALL,
  onClick,
  className = '',
  toWebsite = false,
  ...props
}) {
  const icon = (menuItem.data && menuItem.data.icon) ?? null;
  const url = getMenuItemUrl(menuItem, toWebsite);
  if (!url) return null;

  return (
    <MenuItemLinkComponent
      url={url}
      target={menuItem.target}
      className={['menu-item__icon', className].join(' ')}
      onClick={onClick}
      {...props}
    >
      {icon && <Image loading="lazy" className="icon" img={icon} size={size} showPlaceholder={false} />}
      <MenuItemText menuItem={menuItem} onClick={onClick} />
    </MenuItemLinkComponent>
  );
}
