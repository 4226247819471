/**
 * This is the app config file. It's defined in the server, so you can access process.env.
 * In React, useAppData can be used to access this config in both the client and server.
 */
module.exports = () => ({
  SENTRY_DSN: process.env.SENTRY_DSN || null,
  APP_URL: process.env.APP_URL || '',
  CMS_URL: process.env.CMS_URL || null,
  DISPLAY_WARNINGS: process.env.DISPLAY_WARNINGS ?? null,
  IS_PRODUCTION: process.env.APP_URL.startsWith('https:'),
  disableCache: process.env.DISABLE_CACHE,
  BUILD_VERSION: process.env.BUILD_VERSION || '9.9.9',
});
