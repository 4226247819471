// GENERAL HELPERS

const focusElements = ['a', 'input', 'button', 'select', 'submit', '[role][tabindex]', '[tabindex]'];
const ignoreModifiers = [
  ':not([aria-hidden="true"])',
  ':not([data-lastelement="true"])',
  ':not([data-firstelement="true"])',
];

const queryString = focusElements.map(element => `${element}${ignoreModifiers.join('')}`).join(', ');

/**
 * Returns first, last or all (depending on "opts" prop) element(s) that can have focus including targetElement itself
 * This method was created because no similar native function is available
 *
 * @returns {array|null}
 * @param targetElement DOM node
 * @param opts
 */
export function findFocusableElement(targetElement, opts = {}) {
  const { lastElement = false, returnAll = false } = opts;

  // Test root element for match
  if (focusElements.includes(targetElement?.type) || targetElement?.hasAttribute('tabindex')) return targetElement;

  // If fromEnd is true then find matching element starting from end of targetElement
  if (lastElement) {
    const allElements = targetElement?.querySelectorAll(queryString) || null;
    // Return the last element
    if (allElements) return allElements[allElements.length - 1];

    return null;
  }

  if (returnAll) {
    return Array.from(targetElement?.querySelectorAll(queryString)) || [];
  }

  // Find first child that matches query
  return targetElement?.querySelector(queryString) || null;
}

// EVENT HELPERS

// Ex. usage (applies to all functions below)
// const runOnClick = e => isClickEvent(e) && do some logic etc...
// <div role="button" onClick={runOnClick} onKeyDown={runOnClick} />
// <button onClick={runOnClick} />

// Handles keyboard actions for click/select
// In some situations you may want to ignore "space" as click/select event ex. in searchable select where space
// can't select because it will be used as a whitespace in search input
export function isClickEvent(e, opts = {}) {
  const { ignoreSpace = false } = opts;
  if (e.type === 'click') return true;
  // Some browsers may use " " as a value for key when spacebar is pressed
  return e.key === 'Enter' || (!ignoreSpace && (e.key === 'Space' || e.key === ' '));
}

// Ex. use on input when you want to submit the form with enter
export function isSubmitEvent(e) {
  return e.key === 'Enter';
}

// Any input that was intended to change value of some interactive target - ex. all keys that print characters without
// any modifier keys other than shift
export function isInputEvent(e) {
  // Modifier key was used - ignore input event
  if (e.ctrlKey || e.metaKey || e.altKey) return false;

  // Check if space or backspace was used
  if (e.key === 'Space' || e.key === ' ' || e.key === 'Backspace') return true;

  // Assumption - key code with character length of 1 is probably regular input character
  return (e.key || '').length === 1;
}

// Use when listening cancel event from keyboard
export function isCloseEvent(e) {
  return e.key === 'Escape' || e.key === 'Esc';
}

export function isNavigationEvent(e) {
  return e.key === 'ArrowUp' || e.key === 'ArrowDown' || e.key === 'ArrowLeft' || e.key === 'ArrowRight';
}

export function isFocusChangeEvent(e) {
  return e.key === 'Tab';
}

export function isSelectEvent(e) {
  return e.key === 'Space' || e.key === ' ';
}
