import React, { useMemo, useRef, useState } from 'react';
import CloseIcon from '../../icons/CloseIcon';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox/Checkbox';
import useMobx from '@Shared/hooks/useMobx';
import { observer } from 'mobx-react-lite';
import { Portal } from 'react-portal';
import { SharedCookieKeys } from '@Shared/constants';
import { hasGivenCookieConsent } from '@Shared/components/gdpr/utils';

const OPTIONAL_COOKIES = { analytics: true };
const NEXT_YEAR = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
// Regex to find cookie page link text between double brackets: {{Link to terms}}
const COOKIE_PAGE_LINK_REGEX = /{{(.*)}}/i;

function CookieNotice() {
  const { t, content, cookies, ui } = useMobx();
  const defaultCookies = useMemo(() => cookies.get(SharedCookieKeys.COOKIE_CONSENT) || OPTIONAL_COOKIES, []);
  const [showCookieManager, setShowCookieManager] = useState(false);
  const cookieOptions = useRef(defaultCookies);

  const cookieRegion = useMemo(() => content.getRegion('gdpr-cookie-region') || [], []);
  const cookieRegionData = t(cookieRegion.data);

  const cookieReadMoreLink = useMemo(() => {
    if (!cookieRegionData) return null;

    const linkText = cookieRegionData.description.match(COOKIE_PAGE_LINK_REGEX)?.pop();
    if (!linkText) return null;

    const pagePath = content.getPagePathById(cookieRegionData?.read_more_page);
    if (!pagePath) return linkText;

    return `<a target="_blank" href="${pagePath}">${linkText}</a>`;
  }, [cookieRegionData?.read_more_page]);

  const cookieDescription = useMemo(() => {
    if (!cookieReadMoreLink) return '';
    const cookieDescriptionWithLink = cookieRegionData.description.replace(COOKIE_PAGE_LINK_REGEX, cookieReadMoreLink);
    return { __html: cookieDescriptionWithLink };
  }, [cookieReadMoreLink]);

  const saveCookie = () => {
    cookies.setSharedCookie(SharedCookieKeys.COOKIE_CONSENT, cookieOptions.current, {
      expires: NEXT_YEAR,
    });

    ui.setShowCookieNotice(false);
  };

  const handleDecline = () => {
    declineOptionalCookies();
    saveCookie();
    setShowCookieManager(false);
  };

  const declineOptionalCookies = () => {
    const declinedCookies = { ...OPTIONAL_COOKIES };
    Object.keys(declinedCookies).forEach(key => (declinedCookies[key] = false));

    cookieOptions.current = declinedCookies;
  };

  const acceptAllOptionalCookies = () => {
    const acceptedCookies = { ...OPTIONAL_COOKIES };
    Object.keys(acceptedCookies).forEach(key => (acceptedCookies[key] = true));

    cookieOptions.current = acceptedCookies;
  };

  const handleAcceptAll = () => {
    acceptAllOptionalCookies();
    saveCookie();
  };

  const handleCookieConsentChange = ({ key, value }) => {
    const changedCookies = { ...cookieOptions.current, [key]: value };
    cookieOptions.current = changedCookies;
  };

  const handleManage = () => {
    if (!hasGivenCookieConsent(cookies.get(SharedCookieKeys.COOKIE_CONSENT))) {
      // Make all optional cookies unchecked by default if it's first time setting consent
      declineOptionalCookies();
    }

    setShowCookieManager(true);
  };

  const handleAgree = options => {
    cookieOptions.current = options;
    saveCookie();
  };

  // Hide notice
  if (!ui.showCookieNotice) return null;

  return (
    <Portal>
      <div className="gdpr__cookie-notice">
        <div className="cookie-notice__body">
          {cookieRegionData.title && <span className="cookie-notice__title">{cookieRegionData.title}</span>}
          {cookieDescription && (
            <p className="cookie-notice__description" dangerouslySetInnerHTML={cookieDescription} />
          )}
        </div>

        {showCookieManager ? (
          <CookieManager
            translations={cookieRegionData.translations}
            onAgree={handleAgree}
            onChange={handleCookieConsentChange}
            cookieOptions={cookieOptions.current}
          />
        ) : (
          <CookieButtons
            translations={cookieRegionData.translations}
            onManage={handleManage}
            onDecline={handleDecline}
            onAcceptAll={handleAcceptAll}
          />
        )}

        <Button
          variant="ghost"
          height={16}
          className="cookie-notice__close"
          onClick={handleDecline}
          aria-label={t('buttons.close')}
        >
          <CloseIcon />
        </Button>
      </div>
    </Portal>
  );
}

function CookieManager({ onAgree, cookieOptions: baseCookieOptions, translations = {} }) {
  const [cookieOptions, setCookieOptions] = useState(baseCookieOptions);

  const onChange = ({ key, value }) => {
    const changedCookies = { ...cookieOptions, [key]: value };
    setCookieOptions(changedCookies);
  };

  const handleOnAgreeClick = () => {
    onAgree(cookieOptions);
  };

  return (
    <div className="cookie-notice__manager">
      <fieldset>
        <Checkbox size="large" key="essential" label={translations.essential} checked disabled />
        {Object.keys(cookieOptions).map(consent => (
          <Checkbox
            size="large"
            key={consent}
            label={translations[consent]}
            checked={cookieOptions[consent]}
            onClick={checked => onChange({ value: checked, key: consent })}
          />
        ))}
      </fieldset>

      <Button height="32" onClick={handleOnAgreeClick}>
        {translations.agree}
      </Button>
    </div>
  );
}

function CookieButtons({ onAcceptAll, onDecline, onManage, translations = {} }) {
  return (
    <div className="cookie-notice__buttons">
      <Button height="32" onClick={onAcceptAll}>
        {translations.accept_all}
      </Button>
      <Button height="32" onClick={onDecline} variant="secondary" inverted>
        {translations.decline_all}
      </Button>
      <Button height="32" onClick={onManage} variant="secondary" inverted>
        {translations.manage}
      </Button>
    </div>
  );
}

export default observer(CookieNotice);
