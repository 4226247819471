import React, { useEffect, useRef, useState } from 'react';
import { useMountedState } from 'react-use';
import getMediaSrc from '../../../utils/media';

function Video({ video, src, ...props }, ref) {
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [renderEmpty, setRenderEmpty] = useState(false);
  const isMounted = useMountedState();
  const isFirstRender = useRef(true);

  useEffect(() => {
    setFailedToLoad(false);
  }, [video && video.id]);

  // Get video data
  const alt = (video && video.alt) || void 0;
  const title = (video && video.title) || void 0;
  const mimeType = (video && video.mime_type) || void 0;
  const videoUrl = getMediaSrc(video) || src;

  useEffect(() => {
    if (!isFirstRender.current) {
      if (isMounted()) setRenderEmpty(true);
      setTimeout(() => isMounted() && setRenderEmpty(false), 0);
    }
    isFirstRender.current = false;
  }, [videoUrl]);

  if (renderEmpty) return null;

  if ((video || src) && !failedToLoad && videoUrl)
    return (
      <video title={title || alt} ref={ref} onError={() => setFailedToLoad(true)} {...props}>
        <source src={videoUrl} type={mimeType} />
        Sorry, your browser doesn't support embedded videos.
      </video>
    );

  // TODO: Video placeholder

  return null;
}

export default React.forwardRef(Video);
