import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import useMobx from '@Shared/hooks/useMobx';
import { SharedCookieKeys } from '@Shared/constants';
import { decode } from '@Shared/utils/base64';
import { setupSharedData, SharedDataProvider } from '@Shared/Shared';
import { sanitizeUrl } from '@Shared/utils/urls';
import config from 'app/js/helpers/config';

const SharedWebsiteProvider = observer(({ children }) => {
  const { cookies, content, settings, i18n } = useMobx();
  const accessToken = cookies.get(SharedCookieKeys.ACCESS_TOKEN);
  const [accountInfo, setAccountInfo] = useState(null);
  const [basket, setBasket] = useState(null);

  const fetchAccountInfoIfPossible = async () => {
    if (accessToken) {
      try {
        const response = await fetch(`${content.webshopUrl}/api/v2021/user-account`, {
          method: 'get',
          headers: new Headers({
            'Turnit-Auth': `Bearer ${accessToken}`,
          }),
        });

        const body = await response.json();
        setAccountInfo(body);
      } catch (e) {
        // Fail silently
      }
    }
  };

  const attemptUserLogout = () => {
    if (accessToken) {
      try {
        fetch(`${content.webshopUrl}/api/logout`, {
          method: 'post',
          headers: new Headers({
            'Turnit-Auth': `Bearer ${accessToken}`,
          }),
        });

        // Manually clear the info
        setAccountInfo(null);
        cookies.deleteSharedCookie(SharedCookieKeys.ACCESS_TOKEN);
      } catch (e) {
        // Fail silently
      }
    }
  };

  const fetchBasketIfPossible = async () => {
    const basketId = cookies.get(SharedCookieKeys.BASKET);
    if (basketId) {
      try {
        const response = await fetch(`${content.meta.webshopUrl}/api/v2021/booking/${decode(basketId)}`);
        const body = await response.json();
        setBasket(body);
      } catch (e) {
        // Fail silently
      }
    }
  };

  useEffect(() => {
    fetchAccountInfoIfPossible();
    fetchBasketIfPossible();
  }, []);

  const sharedAccountInfo = useMemo(() => {
    if (Object.keys(accountInfo || []).length <= 0) {
      return {
        isLoggedIn: false,
      };
    }

    const isCorproate = accountInfo?.CorporateClient || false;

    return {
      isLoggedIn: true,
      userName: isCorproate ? accountInfo.CorporateClient.Name : `${accountInfo?.FirstName}`,
      isCorporateAccount: isCorproate,
      handleLogout: attemptUserLogout,
    };
  }, [accountInfo]);

  const sharedDataValue = setupSharedData({
    isTurnitApp: settings.isTurnitApp,
    locale: i18n.locale,
    locales: i18n.localeKeyValues,
    setLocale: i18n.setLocale,
    currency: null,
    currencies: [],
    t: i18n.translate,
    isWebshop: false,
    webshopUrl: sanitizeUrl(content.meta.webshopUrl),
    websiteUrl: sanitizeUrl(config.APP_URL),
    navLogo: content.meta.navLogo,
    mainLogo: content.meta.mainLogo,
    divider: content.meta.divider,
    logoDestination: content.meta.logoDestination,
    isCookieConsentEnabled: content.meta.isCookieConsentEnabled,
    showBasket: !!basket,
    basketValidUntilUTC: basket && basket.ValidUntilInUtc,
    client: content.meta.client,
    logoSize: content.meta.logoSize,
    ...sharedAccountInfo,
  });

  return <SharedDataProvider value={sharedDataValue}>{children}</SharedDataProvider>;
});

export default SharedWebsiteProvider;
