import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo, useState } from 'react';
import useMobx from '@Shared/hooks/useMobx';

function PageLoader() {
  const { content } = useMobx();
  const [loading, setLoading] = useState(content.loading);
  const [finished, setFinished] = useState(!content.loading);

  const classNames = useMemo(() => {
    const _classNames = ['page-loader'];
    if (loading) _classNames.push('loading');
    if (finished) _classNames.push('finished');
    return _classNames.join(' ');
  }, [loading, finished]);

  useEffect(() => {
    if (!content.loading) {
      setFinished(true);
      setTimeout(() => setLoading(false), 800);
    } else {
      setFinished(false);
      setLoading(true);
    }
  }, [content.loading]);

  if (!loading) return null;
  return (
    <div className={classNames}>
      <div className="page-loader__bar">
        <div className="page-loader__progress"></div>
      </div>
    </div>
  );
}

export default observer(PageLoader);
