// Define template components that match template slug from CMS
import HomePage from '../react/pages/HomePage';
import FaqPage from '../react/pages/FaqPage';
import ContentPage from '../react/pages/ContentPage';
import NoticesDetailPage from '../react/pages/notices/NoticesDetailPage';
import StationsPage from '../react/pages/StationsPage';
import BusinessFormPage from '../react/pages/BusinessFormPage';
import FeedbackFormPage from '../react/pages/FeedbackFormPage';

export default {
  'home-page': HomePage,
  'faq-page': FaqPage,
  'content-page': ContentPage,
  'notices-page': NoticesDetailPage,
  'stations-page': StationsPage,
  'business-form-page': BusinessFormPage,
  'feedback-form-page': FeedbackFormPage,
};
