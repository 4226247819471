import React from 'react';
import Heading from '../Heading/Heading';
import TextInput from '../Inputs/TextInput';

export default function CompanyInformationForm({ heading, labels, formik }) {
  return (
    <section>
      <Heading className="section__title" level="h4" visual="h3">
        {heading}
      </Heading>
      {labels && formik && (
        <div className="inputs-container">
          <TextInput
            name="companyName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyName}
            error={formik.errors.companyName}
            label={labels.company_name}
            required
          />
          <TextInput
            name="companyCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyCode}
            error={formik.errors.companyCode}
            label={labels.company_code}
            required
          />
          <TextInput
            name="vatCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.vatCode}
            error={formik.errors.vatCode}
            label={labels.vat_code}
            required
          />
          <TextInput
            name="companyAddress"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyAddress}
            error={formik.errors.companyAddress}
            label={labels.registered_address}
            required
          />
          <TextInput
            name="postalCode"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.postalCode}
            error={formik.errors.postalCode}
            label={labels.postal_code}
            required
          />
          <TextInput
            name="managerName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.managerName}
            error={formik.errors.managerName}
            label={labels.manager_name}
            required
          />
        </div>
      )}
    </section>
  );
}
