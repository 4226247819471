import React, { useMemo } from 'react';

function Body({ children, large, small, strong, asHtml = false, className, ...props }) {
  const classNames = useMemo(() => {
    const _classNames = ['body', className];
    if (large) _classNames.push('large');
    if (small) _classNames.push('small');
    if (strong) _classNames.push('strong');

    return _classNames.join(' ');
  }, [large, small, strong, className]);

  const bodyProps = useMemo(() => {
    const options = {};
    if (asHtml) options.dangerouslySetInnerHTML = { __html: children };
    else options.children = children;

    return options;
  }, [asHtml, children]);

  return <div className={classNames} {...props} {...bodyProps} />;
}

export default Body;
