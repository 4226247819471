import React, { useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import usePage from '../hooks/usePage';
import useMobx from '@Shared/hooks/useMobx';
import Page from './Page';
import ContentLayout from '../common/Layout/ContentLayout';
import Station from '../components/Station';
import SidePanelContent from '../components/SidePanelContent';
import Backdrop from '@Shared/components/ui/modal/Backdrop';
import MaxWidth from '@Shared/components/ui/MaxWidth';
import MobilePopup from '@Shared/components/ui/MobilePopup';
import SeoLink from '@Shared/components/links/SeoLink';
import { generateWebshopUrl } from 'app/js/helpers/go-to';

const StationsPage = observer(({ ...props }) => {
  const page = usePage();
  const data = page.data;
  const parentRef = useRef();
  const { stops, i18n, content, t } = useMobx();
  const locale = i18n.locale;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedStation, setSelectedStation] = useState(null);

  useEffect(() => {
    stops.allStops.length < 1 && stops.fetchStops();
  }, []);

  const alphabet = data?.alphabet?.replace(/\s+/g, '').toUpperCase(); // Parse alphabet from CMS
  const alphabetArray = alphabet && Array.from(alphabet);

  const findStationByLetter = letter => {
    // Check if there are any stations that start with current letter
    const found = stops.allStops?.some(element => element.name[locale].charAt(0).toUpperCase() === letter);
    return found;
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const groupStationsByLetter = (letter, key) => {
    // Group all stations together that start with corresponding letter
    let filteredStations = null;
    filteredStations = stops.allStops?.filter(x => {
      return x.name[locale].charAt(0).toUpperCase() === letter;
    });

    return (
      filteredStations.length > 0 && (
        <div className="stations-container" key={key} id={letter}>
          <div className="station-letter" aria-hidden="true">
            {letter}
          </div>
          <div className="stations-wrapper">
            {filteredStations?.map(station => {
              const stationLink = `${content.webshopUrl}/timetable?oStop=${station.turnit_stop.stop_id}`;
              const stationRef = selectedStation?.id === station.id ? parentRef : void 0;

              return [
                <SeoLink key={`${station.id}-link`} href={generateWebshopUrl(stationLink)}>
                  {t(station.name)}
                </SeoLink>,
                <button
                  key={`${station.id}-button`}
                  className="station-wrapper"
                  tabIndex="0"
                  ref={stationRef}
                  onClick={() => {
                    setIsOpen(true);
                    setSelectedStation(station);
                  }}
                >
                  <Station data={station} serviceSubtitle={data.all_services_subtitle} />
                </button>,
              ];
            })}
          </div>
        </div>
      )
    );
  };

  return (
    <Page route={props.route} className="stations-page">
      <MaxWidth>
        <ContentLayout title={data.title}>
          <nav className="alphabet">
            {alphabetArray?.map((char, key) => {
              findStationByLetter(char);
              return (
                <a
                  key={key}
                  href={(findStationByLetter(char) && `#${char}`) || ''}
                  className={`character ${(findStationByLetter(char) && 'active') || ''}`}
                  aria-hidden={!findStationByLetter(char)}
                  tabIndex={!findStationByLetter(char) ? '-1' : '0'}
                >
                  {char}
                </a>
              );
            })}
          </nav>
          <ContentLayout.Body>
            {alphabetArray?.map((letter, key) => groupStationsByLetter(letter, key))}
            <MobilePopup
              show={isOpen}
              className="stations-side-panel"
              parentRef={parentRef}
              toggle={() => setTimeout(setIsOpen(!isOpen), 250)}
              visible={isOpen}
            >
              <SidePanelContent
                selectedStation={selectedStation}
                serviceSubtitle={data.service_subtitle}
                serviceNearSubtitle={data.service_near_subtitle}
                selectSubtitle={data.select_subtitle}
                searchStation={data.search_station}
                close={() => setIsOpen(false)}
              />
            </MobilePopup>
          </ContentLayout.Body>
        </ContentLayout>
      </MaxWidth>
      <Backdrop show={isOpen} click={handleClose} />
    </Page>
  );
});
export default StationsPage;
