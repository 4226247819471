import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../ui/media/Image';
import { useSharedData } from '../../Shared';

export default function HeaderLogo() {
  const { isTurnitApp, isWebshop, navLogo, t, logoDestination, websiteUrl, webshopUrl, logoSize } = useSharedData();
  const className = `_logo ${logoSize !== 'default' ? '_large' : ''}`;

  if (isTurnitApp) {
    // Turnit App => Always redirect to webshop
    return (
      <a href={logoDestination ?? webshopUrl} className={className}>
        <Image loading="eager" img={navLogo} />
      </a>
    );
  }

  if (isWebshop) {
    return (
      // Webshop Web => Always redirect to website
      <a href={logoDestination ?? websiteUrl} tabIndex={0} className={className}>
        <Image loading="eager" img={navLogo} alt={t('components.header.home')} />
      </a>
    );
  }

  return (
    // Website Web => Always go to website home
    logoDestination ? (
      <a href={logoDestination} className={className}>
        <Image loading="eager" img={navLogo} />
      </a>
    ) : (
      <Link to="/" className={className}>
        <Image loading="eager" img={navLogo} />
      </Link>
    )
  );
}
