import { getAppConfig } from './utils';

const FRONTEND_SAFE_KEYS = ['APP_URL', 'CMS_URL', 'SENTRY_DSN', 'BUILD_VERSION', 'IS_PRODUCTION'];

const getFrontendConfig = () => {
  const _config = {};
  FRONTEND_SAFE_KEYS.forEach(key => {
    _config[key] = getAppConfig()[key];
  });
  return _config;
};

export default typeof window === 'undefined' ? require('../../../server/config')() : getFrontendConfig();
