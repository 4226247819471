import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import Page from './Page';
import ContentLayout from '../common/Layout/ContentLayout';
import useMobx from '@Shared/hooks/useMobx';
import Banner, { BANNER_ACTION_TYPES } from '../components/Banners/Banner';
import Banners from '../components/Banners/Banners';
import usePageData from '../hooks/usePageData';
import Image from '@Shared/components/ui/media/Image';
import MenuItemText from '@Shared/components/menus/menu-items/MenuItemText';
import MaxWidth from '@Shared/components/ui/MaxWidth';
import { getMenuItemUrl, MenuItemLinkComponent } from '@Shared/utils/menu';
import ContentSection from '../components/Pages/Content/ContentSection';

const ContentPage = observer(({ ...props }) => {
  const { content, banners, i18n } = useMobx();
  const { page } = usePageData();

  const pageContent = page.data?.page_content?.page_content || [];
  const sidebarMenu = content.getMenuById(page.data.menu)?.menuItems;
  const locale = i18n.locale;

  useEffect(() => {
    const fetchBanners = async () => await banners.fetchBanners();
    banners.data.length < 1 && fetchBanners();

    return () => {
      banners.clearError();
    };
  }, []);

  const bannersFiltered = banners.data.filter(banner => {
    return banner.visibility?.find(e => {
      return e === page.path;
    });
  });

  const getBannerType = data => {
    if (data?.slider_title[locale] || data?.slider_subtitle[locale]) {
      return BANNER_ACTION_TYPES.HOVER;
    }
    if (data?.button_text[locale]) {
      return BANNER_ACTION_TYPES.BUTTON;
    }
    return BANNER_ACTION_TYPES.NONE;
  };

  return (
    <Page route={props.route} className="content-page">
      <MaxWidth>
        {page.data.hero_image && <Image className="hero-banner" img={page.data.hero_image} />}
        <ContentLayout title={page.data.title}>
          <ContentLayout.Nav>
            {sidebarMenu &&
              sidebarMenu.map(menuItem => {
                const menuItemUrl = getMenuItemUrl(menuItem);
                if (!menuItemUrl) return <MenuItemText menuItem={menuItem} />;

                return (
                  <MenuItemLinkComponent
                    key={menuItem.id}
                    url={menuItemUrl}
                    target={menuItem.target}
                    type="navlink"
                    exact
                  >
                    {menuItem.name}
                  </MenuItemLinkComponent>
                );
              })}
          </ContentLayout.Nav>
          <ContentLayout.Body>
            {pageContent.map((section, i) => (
              <ContentSection section={section} key={i} />
            ))}
            <Banners className="home-page" group="large">
              {bannersFiltered.map((banner, i) => (
                <Banner data={banner} key={i} locale={locale}>
                  <Banner.Action type={getBannerType(banner)} data={banner} locale={locale} />
                </Banner>
              ))}
            </Banners>
          </ContentLayout.Body>
        </ContentLayout>
      </MaxWidth>
    </Page>
  );
});

export default ContentPage;
