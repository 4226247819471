import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import PowerIcon from '../../icons/PowerIcon';
import UserIcon from '../../icons/UserIcon';
import IconButton from '../inputs/IconButton';
import WebshopLink from '../links/WebshopLink';
import { useSharedData } from '../../Shared';
import { callFlutterHandler } from '@Shared/utils/turnit-app';
import TicketIcon from '../../icons/TicketIcon';
import { UserDropdown } from './UserDropdown';

export default function HeaderAccountArea({
  device = 'desktop',
  closeModals = () => null,
  SignInComponent,
  isAdminAccount,
}) {
  const { t, handleLogout, isLoggedIn, isTurnitApp, client, isWebshop, userName } = useSharedData();
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const queryParams = parseQueryString(location.search);

  let loginLink = `/account/login`;
  if (queryParams.basketId) loginLink += `?basketId=${queryParams.basketId}`;

  return (
    <nav className={`shared-header__account-area -${device}`}>
      {isLoggedIn ? (
        <>
          {!isTurnitApp && (
            <UserDropdown closeModals={closeModals} isAdminAccount={isAdminAccount} setShowModal={setShowModal} />
          )}
          {(isTurnitApp ? client !== 'ltg' : true) && (
            <>
              <WebshopLink
                className="_link -mobile-only"
                to="/account/dashboard/tickets/future"
                key="future"
                onClick={closeModals}
              >
                <div className="_icon">
                  <TicketIcon />
                </div>
                {t('components.header.myAccount')}
              </WebshopLink>

              {isAdminAccount && (
                <WebshopLink
                  className="_link -mobile-only"
                  to="/admin/dashboard"
                  key="admin-dashboard"
                  onClick={closeModals}
                >
                  <div className="_icon">
                    <UserIcon />
                  </div>
                  {t('components.header.business-account')}
                </WebshopLink>
              )}
              <Link
                className="_link -mobile-only"
                onClick={async () => {
                  await handleLogout();
                  closeModals();
                  setShowModal(false);
                }}
                to="/"
                key="home"
              >
                <div className="_icon">
                  <PowerIcon />
                </div>
                {t('components.header.signout')}
              </Link>
            </>
          )}
          {isTurnitApp && client === 'ltg' && (
            <WebshopLink
              className="_link -mobile-only"
              to="/account/dashboard/tickets/future"
              key="future"
              onClick={closeModals}
            >
              <div className="_icon">
                <TicketIcon />
              </div>
              {userName || t('components.header.myAccount')}
            </WebshopLink>
          )}
        </>
      ) : isWebshop ? (
        <>
          <IconButton
            icon={<UserIcon />}
            label={t('components.header.signin')}
            onClick={() => {
              setShowModal(true);
              callFlutterHandler('SignInClicked');
            }}
          />
          <SignInComponent
            show={showModal}
            device={device}
            closeModal={() => setShowModal(false)}
            closeBurgerModal={closeModals}
          />
        </>
      ) : (
        <WebshopLink className="_link" to={loginLink} onClick={closeModals}>
          <IconButton icon={<UserIcon />} label={t('components.header.signin')} />
        </WebshopLink>
      )}
    </nav>
  );
}

const parseQueryString = url => {
  const urlParams = {};
  url.replace(new RegExp('([^?=&]+)(=([^&]*))?', 'g'), ($0, $1, $2, $3) => (urlParams[$1] = $3));
  return urlParams;
};
