import { useCallback, useEffect, useState } from 'react';
import useMobx from '../../../hooks/useMobx';
import { SharedCookieKeys } from '../../../constants';

export const useScriptPermission = type => {
  const { cookies } = useMobx();
  const [allowScripts, setAllowScripts] = useState(false);

  const updateAllowScripts = useCallback(({ value }) => {
    const isAllowed = value?.[type] === true;
    setAllowScripts(isAllowed);
  }, []);

  useEffect(() => {
    const permissionCookie = cookies.get(SharedCookieKeys.COOKIE_CONSENT);

    updateAllowScripts({ value: permissionCookie });
    cookies.addChangeListener(SharedCookieKeys.COOKIE_CONSENT, updateAllowScripts);
    return () => {
      setAllowScripts(false);
      cookies.removeChangeListener(SharedCookieKeys.COOKIE_CONSENT, updateAllowScripts);
    };
  }, []);

  return allowScripts;
};
