import SharedToasts from '@Shared/components/toasts/Toasts';
import useMobx from '@Shared/hooks/useMobx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import Toast from './Toast';

function ToastContainer() {
  const { toasts } = useMobx();

  return <SharedToasts Comp={Toast} toasts={toasts.toasts} />;
}

export default observer(ToastContainer);
