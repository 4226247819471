import React from 'react';
import Heading from '../../components/Heading/Heading';

function ContentLayout({ title, children }) {
  return (
    <div className="content-layout">
      {title && (
        <Heading className="content-layout__heading" visual="h2" underline>
          {title}
        </Heading>
      )}

      <div className="content-layout__content">{children}</div>
    </div>
  );
}

function ContentLayoutNav({ children }) {
  return (
    <>
      <div className="content-layout__nav-wrapper">
        <div className="content-layout__nav">{children}</div>
      </div>
    </>
  );
}

function ContentLayoutBody({ children }) {
  return <div className="content-layout__body">{children}</div>;
}

ContentLayout.Nav = ContentLayoutNav;
ContentLayout.Body = ContentLayoutBody;
export default ContentLayout;
