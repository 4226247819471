import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { SiteContent } from '../../helpers/api/requests';
import { keyBy } from 'lodash';
import { generatePath } from 'react-router';

class Content {
  @observable seo = {};
  @observable routes = [];
  @observable pages = {};
  @observable menus = [];
  @observable regions = [];
  @observable meta = {};
  @observable loading = false;
  @observable translations = {};
  @observable scripts = {};

  @observable currentPage = {};
  @observable currentMatch = null;

  constructor(hydrateStore) {
    hydrateStore(this);
    makeObservable(this);
  }

  @action setLoading(loading) {
    this.loading = loading;
  }

  @action setSeo(seo) {
    this.seo = seo;
  }

  @action setRoutes(routes) {
    this.routes = routes;
  }

  @action setRegions(regions) {
    this.regions = regions;
  }

  @action setMenus(menus) {
    this.menus = menus;
  }

  @action setMeta(data) {
    this.meta = data;
  }

  @action setTranslations(translations) {
    this.translations = translations;
  }

  @action setScripts(scripts = {}) {
    this.scripts = scripts;
  }

  @action setCurrentPage(page = {}) {
    this.currentPage = page;
  }

  @action setCurrentMatch(match = null) {
    this.currentMatch = match;
  }

  getRegion(identifier, lang) {
    if (!identifier) return null;
    const locale = lang || this.rootStore.i18n.locale;

    return this.regions.find(region => region.template === identifier && region.locales.includes(locale));
  }

  @action getMenu(identifier) {
    if (!identifier) return null;

    let menuColumn = null;
    // Allows developer to search for menu by either slug or id.
    if (typeof identifier === 'string') menuColumn = 'slug';
    if (typeof identifier === 'number') menuColumn = 'id';

    const locale = this.rootStore.i18n.locale;
    return this.menus.find(menu => menu[menuColumn] === identifier && menu.locale === locale);
  }

  getMenuById = (menuId = null) => {
    const locale = this.rootStore.i18n.locale;
    if (!menuId) return null;
    return this.menus.find(menu => Number(menu.id) === Number(menuId) && menu.locale === locale);
  };

  @action async fetchPageById(id, params) {
    this.loading = true;

    const page = (await SiteContent.getPage(id, params)).body;
    page.data = page.data || {};

    console.debug(`Page fetched [${id}]`);

    const { i18n } = this.rootStore;
    i18n.setLocale(page.locale);
    this.setSeo(page.seo || {});

    runInAction(() => {
      this.pages[id] = page;
      this.loading = false;
    });

    return page;
  }

  getPagePathById = (id, params, localized = true) => {
    if (!id) return void 0;
    const route = this.routes.find(r => String(r.id) === String(id));

    if (localized && this.rootStore.i18n.locale !== route.locale) {
      const correctTranslationId = this.rootStore.t(route.localeIds);
      if (correctTranslationId) return this.getPagePathById(correctTranslationId);
    }

    return route ? generatePath(route.path, params) : void 0;
  };

  // A.K.A getKnownPagePath
  getPagePathByTemplate(template, fallback = '#') {
    const locale = this.rootStore.i18n.locale;
    const page = Object.values(this.routes).find(r => r.template === template && r.locale === locale);

    if (!page || !page.id) return fallback;
    return page.path;
  }

  getPageAlternatePaths(route, params = {}) {
    if (!route) return {};

    const activeLocale = this.rootStore.i18n.locale;
    const localeIds = Object.entries(route.localeIds).filter(([locale]) => locale !== activeLocale);
    const localePaths = localeIds.map(([locale, id]) => {
      if (this.currentMatch) {
        if (route.template === 'notices-page') {
          const localeSlug = this.currentMatch.paths[locale];
          if (localeSlug) params.slug = localeSlug;
        }
      }

      return {
        locale,
        id,
        path: this.getPagePathById(id, params, false),
      };
    });

    // Return array of objets as [en: {}, et: {}];
    return keyBy(localePaths, o => o.locale);
  }

  // ----------
  // COMPUTED
  // ----------

  // Backwards compability
  @computed get webshopUrl() {
    return this.meta.webshopUrl;
  }
}

export default Content;
