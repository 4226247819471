import React from 'react';
import HeaderAccountArea from './HeaderAccountArea';
import HeaderCurrencySelector from './HeaderCurrencySelector';
import HeaderLocaleSelector from './HeaderLocaleSelector';

export default function HeaderRightButtons({ device = 'desktop', closeModals, SignInComponent, isAdminAccount }) {
  return (
    <div className={`shared-header__right-buttons -${device}`}>
      <HeaderLocaleSelector device={device} closeModals={closeModals} />
      <HeaderCurrencySelector device={device} />
      <HeaderAccountArea
        device={device}
        closeModals={closeModals}
        SignInComponent={SignInComponent}
        isAdminAccount={isAdminAccount}
      />
    </div>
  );
}
