import React from 'react';

export default function MailIcon({ color = 'currentColor' }) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 7C22 5.89543 21.1046 5 20 5H4C2.89543 5 2 5.89543 2 7V18C2 19.1046 2.89543 20 4 20H20C21.1046 20 22 19.1046 22 18V7ZM18.33 7L12 11.75L5.67 7H18.33ZM4 8.25V18H20V8.25L12 14.25L4 8.25Z"
        fill={color}
      />
    </svg>
  );
}
