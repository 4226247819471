import React, { useMemo } from 'react';
import SeoLink from '../links/SeoLink';
import { uniqBy } from 'lodash';
import { useSharedData } from '../../Shared';
import { getMenuItemUrlBase, hasAnyEnabledChildren, isMenuItemLinkType } from '../../utils/menu';

export default function HeaderMenuSeoLinks({ menuItems: parentMenuItems = [] }) {
  const { isWebshop, websiteUrl, webshopUrl } = useSharedData();

  const createRecrusiveMenuLinks = menuItem => {
    let links = [];

    if (isMenuItemLinkType(menuItem) && menuItem.enabled) {
      const menuItemLink = getMenuItemUrlBase({ menuItem, isWebshop, websiteUrl, webshopUrl });
      links.push({ link: menuItemLink, name: menuItem.name });
    }

    if (hasAnyEnabledChildren(menuItem)) {
      Object.values(menuItem.children).forEach(child => {
        const childMenuLinks = createRecrusiveMenuLinks(child);
        links = links.concat(childMenuLinks);
      });
    }

    return links;
  };

  const uniqueRecrusiveLinks = useMemo(() => {
    const allRecrusiveLinks = Object.values(parentMenuItems).flatMap(parentMenuItem => {
      return createRecrusiveMenuLinks(parentMenuItem);
    });

    return uniqBy(allRecrusiveLinks, 'link');
  }, [parentMenuItems]);

  return (
    <div tabIndex="-1" className="header-menu__seo-links">
      {uniqueRecrusiveLinks.map(({ link, name }) => {
        return (
          <SeoLink key={link} href={link}>
            {name}
          </SeoLink>
        );
      })}
    </div>
  );
}
