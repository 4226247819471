import { hydrateStore } from '../helpers/hydrate';
import stores, { helpers } from './stores/index';

// Default keys in store classes that will be ignored in server sided serialization
const ignoreKeys = ['rootStore'];

const circularDependencies = [
  'rootStore',
  // This value is a callback (setTimeout), which is considered a circular dependency.
  'announcerTimeoutId',
];

export const createStore = () => {
  const mobxStore = {};
  // Instantiate stores
  for (const storeKey in stores) {
    const hydrateStoreWrapper = (storeSlice, keysToIgnore = []) => {
      return hydrateStore(storeSlice, storeKey, [...ignoreKeys, ...keysToIgnore]);
    };

    mobxStore[storeKey] = new stores[storeKey](hydrateStoreWrapper, mobxStore);
    mobxStore[storeKey].rootStore = mobxStore;
  }

  const mobxHelpers = helpers(mobxStore);
  for (const helperKey in mobxHelpers) {
    mobxStore[helperKey] = mobxHelpers[helperKey];
  }

  return mobxStore;
};

export const cleanCircularDependencies = (store = []) => {
  return Object.keys(store).reduce((newStore, storeKey) => {
    newStore[storeKey] = store[storeKey];
    for (const keyToDelete of circularDependencies) delete newStore[storeKey][keyToDelete];
    return newStore;
  }, {});
};
