import { action, makeObservable, observable } from 'mobx';
import { StopsContent } from '../../helpers/api/requests';

export default class Stops {
  @observable allStops = [];
  @observable singleStop = {};
  @observable error = null;

  constructor(hydrateStore) {
    hydrateStore(this);
    makeObservable(this);
  }

  @action saveStop(stop) {
    this.singleStop = stop;
  }

  @action saveStops(stops = []) {
    const { i18n } = this.rootStore;
    const locale = i18n.locale;

    this.allStops = stops.sort((a, b) => a.name[locale].localeCompare(b.name[locale]));
  }

  @action setError(error = {}) {
    this.error = error;
  }

  @action clearError() {
    this.setError(null);
  }

  @action async fetchStops(...props) {
    try {
      // Attempt to request notices
      const response = await StopsContent.getAll(...props);
      this.saveStops(response.body);
    } catch (error) {
      // Log to errors
      console.error('[fetchStops]', error);
      this.error = error;
    }
  }

  @action async fetchStopById(id) {
    let fetchedStop;

    try {
      const response = await StopsContent.get(id);
      if (response.body && response.body.id) {
        fetchedStop = response.body;
        console.log(`Stop fetched [${response.body.id}]`);
        this.saveStop(fetchedStop);
      }
    } catch (error) {
      console.warn('[fetchStopById]', error);
      this.error = error;
    }

    return fetchedStop;
  }
}
