import React from 'react';
import Heading from '../Heading/Heading';
import TextInput from '../Inputs/TextInput';

export default function BankInformationForm({ heading, labels, formik }) {
  return (
    <section>
      <Heading className="section__title" level="h4" visual="h3">
        {heading}
      </Heading>
      {labels && formik && (
        <div className="inputs-container">
          <TextInput
            name="bankName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.bankName}
            error={formik.errors.bankName}
            label={labels.bank_name}
            required
          />
          <TextInput
            name="accountNumber"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.accountNumber}
            error={formik.errors.accountNumber}
            label={labels.account_number}
            required
          />
        </div>
      )}
    </section>
  );
}
