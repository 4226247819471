import BaseUIStore from '@Shared/mobx/BaseUIStore';
import { makeObservable } from 'mobx';

export default class Ui extends BaseUIStore {
  constructor(hydrateStore, rootStore) {
    super(rootStore);
    hydrateStore(this, ['showCookieNotice']);
    makeObservable(this);
  }
}
