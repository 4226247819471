import React from 'react';

export default function ExclamationIcon({ ...props }) {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66669 16C2.66669 8.63622 8.63622 2.66669 16 2.66669C19.5362 2.66669 22.9276 4.07144 25.4281 6.57193C27.9286 9.07242 29.3334 12.4638 29.3334 16C29.3334 23.3638 23.3638 29.3334 16 29.3334C8.63622 29.3334 2.66669 23.3638 2.66669 16ZM5.33335 16C5.33335 21.8911 10.109 26.6667 16 26.6667C18.829 26.6667 21.5421 25.5429 23.5425 23.5425C25.5429 21.5421 26.6667 18.829 26.6667 16C26.6667 10.109 21.8911 5.33335 16 5.33335C10.109 5.33335 5.33335 10.109 5.33335 16ZM16 24C16.7364 24 17.3334 23.4031 17.3334 22.6667C17.3334 21.9303 16.7364 21.3334 16 21.3334C15.2636 21.3334 14.6667 21.9303 14.6667 22.6667C14.6667 23.4031 15.2636 24 16 24ZM14.6667 9.33335C14.6667 8.59697 15.2636 8.00002 16 8.00002C16.7364 8.00002 17.3334 8.59697 17.3334 9.33335V18.6667C17.3334 19.4031 16.7364 20 16 20C15.2636 20 14.6667 19.4031 14.6667 18.6667V9.33335Z"
        fill="currentColor"
      />
    </svg>
  );
}
