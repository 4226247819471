import React from 'react';

export default function NextIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="white" />
      <path
        d="M9.53029 17.1363C9.65531 17.2624 9.82548 17.3334 10.003 17.3334C10.1805 17.3334 10.3507 17.2624 10.4757 17.1363L15.1364 12.4725C15.2624 12.3474 15.3333 12.1771 15.3333 11.9995C15.3333 11.8218 15.2624 11.6515 15.1364 11.5264L10.4757 6.86266C10.3068 6.69366 10.0607 6.62766 9.82998 6.68952C9.59928 6.75138 9.41908 6.9317 9.35726 7.16256C9.29544 7.39342 9.3614 7.63974 9.53029 7.80874L13.7249 11.9995L9.53029 16.1902C9.40424 16.3153 9.33334 16.4856 9.33334 16.6633C9.33334 16.8409 9.40424 17.0112 9.53029 17.1363Z"
        fill="black"
      />
    </svg>
  );
}
