import { observer } from 'mobx-react-lite';
import React, { useCallback } from 'react';
import { generatePath, useHistory } from 'react-router';
import useMobx from '@Shared/hooks/useMobx';
import PopupAlert from '@Shared/components/alerts/PopupAlert';

function NoticeAlerts() {
  const { notices, t, content } = useMobx();
  const history = useHistory();

  const onNoticeClick = useCallback(notice => {
    const noticeSlug = t(notice.slug, { fallback: false });

    notices.markNoticeAsSeen(notice.id);

    if (noticeSlug) {
      history.push(generatePath(content.getPagePathByTemplate('notices-page'), { slug: noticeSlug }));
    }
  }, []);

  const onNoticeClose = useCallback(notice => {
    notices.markNoticeAsSeen(notice.id);
  }, []);

  return notices.latestUrgentNotices?.map(notice => (
    <PopupAlert
      key={notice.id}
      variant="info"
      body={t(notice.summary)}
      title={t(notice.title)}
      onClose={() => onNoticeClose(notice)}
      onClick={() => onNoticeClick(notice)}
    />
  ));
}

export default observer(NoticeAlerts);
