import React, { useEffect, useRef } from 'react';
import { findFocusableElement } from '@Shared/utils/wcag';
import useTrapFocus from '@Shared/hooks/useTrapFocus';

const styles = {
  border: 0,
  margin: 0,
  padding: 0,
  position: 'absolute',
  opacity: 0,
  pointerEvents: 'none',
};

export function TrapFocus({ visible, triggerBtnRef, children }) {
  const hiddenInputRef = useRef();
  const isInitialRenderRef = useRef(true);

  useTrapFocus(() => hiddenInputRef.current.parentElement, visible);

  useEffect(() => {
    // Ignore initial render
    if (isInitialRenderRef.current) {
      isInitialRenderRef.current = false;
      return;
    }

    // If visibility prop has changed to false then move focus back to trigger button
    if (!visible && triggerBtnRef.current) findFocusableElement(triggerBtnRef.current)?.focus();

    // If visibility prop has changed to true then move focus inside trap
    if (visible && hiddenInputRef.current.parentElement) {
      findFocusableElement(hiddenInputRef.current.parentElement)?.focus();
    }
  }, [visible]);

  return (
    <>
      <input ref={hiddenInputRef} data-firstelement style={styles} />
      {children}
      <input data-lastelement style={styles} />
    </>
  );
}
