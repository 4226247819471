import React, { useEffect } from 'react';
import templates from '../config/templates';
import { useAppData } from '@optimistdigital/create-frontend/universal-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { AnimatePresence } from 'framer-motion';
import ErrorPage from './pages/ErrorPage';
import LocaleRouteProvider from './modules/Providers/LocaleRouteProvider';
import { useLocation } from 'react-router';
import { findFocusableElement } from '@Shared/utils/wcag';
import useMobx from '@Shared/hooks/useMobx';
import { ErrorBoundary } from '@sentry/react';

/**
 * Routing wrapper that listens to location changes and updates active page
 */
function Pages() {
  const { pageData } = useAppData();
  const location = useLocation();
  const { i18n, t, ui } = useMobx();

  // Reset focus to top of the page when page changes - WCAG
  useEffect(() => {
    // Get first element that can have focus in #react-app
    findFocusableElement(document.querySelector('#react-app'))?.focus();
    ui.setScreenReaderAnnouncement(t('a11y.loader.info-page-loading-finished'));
  }, [location.pathname, i18n.locale]);

  if (!Array.isArray(pageData.routes)) return <Redirect to="/500" />;
  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      <Switch>
        {pageData.routes.map(route => {
          return (
            <Route key={route.path} path={route.path} exact>
              {(...props) => {
                const TemplateComponent = templates[route.template];
                if (!TemplateComponent) return <Redirect to={{ pathname: '/500', state: { from: props.location } }} />;

                return (
                  <ErrorBoundary fallback={<ErrorPage status={500} />}>
                    <LocaleRouteProvider route={route}>
                      <TemplateComponent route={route} {...props} />
                    </LocaleRouteProvider>
                  </ErrorBoundary>
                );
              }}
            </Route>
          );
        })}
        <Route path="/500" exact render={props => <ErrorPage status={500} {...props} />} />
        <Route render={props => <ErrorPage status={404} {...props} />} />
      </Switch>
    </AnimatePresence>
  );
}

export default observer(Pages);
