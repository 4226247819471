import React, { useState } from 'react';
import { Tooltip } from '../ui/Tooltip';
import CurrencyIcon from '../../icons/CurrencyIcon';
import IconButton from '../inputs/IconButton';
import CollapsibleWrapper from '../ui/collapsible/CollapsibleWrapper';
import { useSharedData } from '../../Shared';
import HeaderCurrencySelectorItems from './HeaderCurrencySelectorItems';

const HeaderCurrencySelector = ({ device }) => {
  const { t, currency, currencies } = useSharedData();
  const [show, setShow] = useState(false);

  if (currencies.length <= 1) return null;

  return (
    <div className={`shared-header__currency-selector -${device}`}>
      {device === 'mobile' ? (
        <CollapsibleWrapper
          clicked={() => setShow(!show)}
          label={currency}
          icon={<CurrencyIcon color="var(--nav-icon-color)" />}
          isOpen={show}
          name="currency-select"
          chevronColor="var(--nav-icon-color)"
          ariaLabel={t('components.headerCurrencySelect.aria.label')}
        >
          <HeaderCurrencySelectorItems onItemClick={() => setShow(false)} />
        </CollapsibleWrapper>
      ) : (
        <Tooltip
          className="currency-selection-tippy"
          name="currency-select"
          tooltipStyle={{ padding: '17px' }}
          interactive
          arrow={false}
          disableButton
          onClose={() => setShow(false)}
          visible={show}
          html={<HeaderCurrencySelectorItems onItemClick={() => setShow(false)} />}
        >
          {props => <CurrencyButton {...props} onClick={() => setShow(true)} />}
        </Tooltip>
      )}
    </div>
  );
};

const CurrencyButton = props => {
  const { currency, t } = useSharedData();

  return (
    <IconButton
      className="uppercase"
      ariaLabel={t('components.headerCurrencySelect.aria.label')}
      icon={<CurrencyIcon color="var(--nav-icon-color)" />}
      label={currency}
      {...props}
    />
  );
};

export default HeaderCurrencySelector;
