import { changeElementLinksTarget } from '@Shared/utils/urls';
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';

export default function WysiwygSection({ subtitle, content }) {
  const contentRef = useRef(null);

  useEffect(() => {
    changeElementLinksTarget(contentRef.current, '_blank');
  }, []);

  return (
    <div className={classNames('content__text-section', { titled: subtitle })}>
      {subtitle && <h3 className="subtitle">{subtitle}</h3>}
      {content && <div className="content" ref={contentRef} dangerouslySetInnerHTML={{ __html: content }} />}
    </div>
  );
}
