import Image from '@Shared/components/ui/media/Image';
import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';

export default function ImagesSection({ images }) {
  if (!Array.isArray(images) || images.length <= 0) return null;

  return (
    <div
      className={classNames('content__images-section', {
        'single-image': images.length === 1,
        'multiple-images': images.length > 1,
      })}
    >
      {images.map((item, idx) => {
        const image = get(item, 'attributes.img', {});
        return <Image className="image" key={idx} img={image} />;
      })}
    </div>
  );
}
