import classNames from 'classnames';
import React from 'react';
import { CustomCheckboxContainer, CustomCheckboxInput } from '@reach/checkbox';

function Checkbox({ size = 'medium', checked, className, disabled, onClick, children, label, ...props }) {
  const handleChange = e => {
    if (disabled || !onClick) return;

    onClick(e.target.checked);
  };

  return (
    <label className={classNames('checkbox', { disabled, checked }, className)} aria-checked={checked} role="checkbox">
      <CustomCheckboxContainer
        className="checkbox__container"
        checked={checked}
        onChange={handleChange}
        disabled={disabled}
      >
        <CustomCheckboxInput {...props} />
        <div aria-hidden className={classNames(`checkbox__checkmark ${size}`, { checked, disabled })}>
          <div className="checkbox__check"></div>
        </div>
      </CustomCheckboxContainer>
      <span className="checkbox__children">{children || label}</span>
    </label>
  );
}

export default Checkbox;
