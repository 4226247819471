import React from 'react';

export default function PassportIcon({ color = 'currentColor' }) {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 3.86957V19.1739C4 21.2783 5.69412 23 7.76471 23H16.2353C18.3059 23 20 21.2783 20 19.1739V5.78261H5.78824C4.8 5.78261 4 4.96957 4 3.96522C4 3.2 4.51765 2.57826 5.22353 2.43478L18.1176 1V5.78261"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  );
}
