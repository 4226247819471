import React from 'react';
import Heading from '../Heading/Heading';
import TextInput from '../Inputs/TextInput';

export default function ContactInformationForm({ heading, labels, formik }) {
  return (
    <section>
      <Heading className="section__title" level="h4" visual="h3">
        {heading}
      </Heading>
      {labels && formik && (
        <div className="inputs-container">
          <TextInput
            name="contactAddress"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactAddress}
            error={formik.errors.contactAddress}
            label={labels.address}
            required
          />
          <TextInput
            name="companyPhone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.companyPhone}
            error={formik.errors.companyPhone}
            label={labels.company_phone}
            required
          />
          <TextInput
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.errors.email}
            label={labels.email}
            required
          />
          <TextInput
            name="invoiceEmail"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.invoiceEmail}
            error={formik.errors.invoiceEmail}
            label={labels.invoice_email}
            required
          />
          <TextInput
            name="contactPerson"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPerson}
            error={formik.errors.contactPerson}
            label={labels.contact_person}
            required
          />
          <TextInput
            name="contactPhone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.contactPhone}
            error={formik.errors.contactPhone}
            label={labels.contact_phone}
            required
          />
        </div>
      )}
    </section>
  );
}
