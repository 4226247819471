import Image from '@Shared/components/ui/media/Image';
import React, { useState, useMemo, useCallback } from 'react';
import useMobx from '@Shared/hooks/useMobx';
import Select from '../../Inputs/Select';
import queryString from 'query-string';
import SeoLink from '@Shared/components/links/SeoLink';
import { get } from 'lodash';

export default function DestinationGuide({ data }) {
  const { i18n, content, t } = useMobx();
  const [departureId, setDepartureId] = useState(get(data.departures, '[0].attributes.stop.id', null));

  const departures = useMemo(() => {
    return data.departures.map(({ attributes: departure }) => {
      const parsedDestinations = departure.destinations.map(({ attributes: destination }) => {
        return {
          ...destination,
          // Making life a little easier
          destinationTurnitStopId: destination.stop.turnit_stop?.stop_id,
          departureTurnitStopId: departure.stop.turnit_stop?.stop_id,
        };
      });

      return {
        ...departure,
        destinations: parsedDestinations,
        departureStopId: departure.stop.id,
      };
    });
  }, [data.departures]);

  const departureOptions = useMemo(() => {
    return departures.map(departure => {
      return { value: departure.stop.id, name: t(departure.stop.name) };
    });
  }, [departures, i18n.locale]);

  const generateWebshopJourneyLink = useCallback(({ destinationStopId, departureStopId }) => {
    if (!content.webshopUrl) return '/';

    const params = queryString.stringify({
      oStop: departureStopId,
      dStop: destinationStopId,
      fareClasses: 'BONUS_SCHEME_GROUP.ADULT,1',
    });

    return `${content.webshopUrl}/journeys?${params}`;
  }, []);

  const departureDestinations = useMemo(() => {
    if (!departureId) return [];

    const matchingDeparture = departures.find(destination => {
      return destination.departureStopId === departureId;
    });

    if (!matchingDeparture) return [];
    return matchingDeparture.destinations;
  }, [departures, departureId]);

  const handleDepratureChange = event => {
    setDepartureId(parseInt(event.target.value, 10));
  };

  if (!departureDestinations) return null;

  return (
    <div className="destination-guide">
      <div className="destination-guide__heading">{data?.title}</div>
      <span className="departure">
        <span className="departure__subheading">{data?.depart_from}</span>
        <Select name="departure" id="departures" onChange={handleDepratureChange} options={departureOptions} />
      </span>
      <div className="departures">
        {departureDestinations.map((destination, key) => {
          return (
            <a
              href={generateWebshopJourneyLink({
                destinationStopId: destination.destinationTurnitStopId,
                departureStopId: destination.departureTurnitStopId,
              })}
              className="destination-wrapper"
              key={`${destination.departureTurnitStopId}-${destination.destinationTurnitStopId}-${key}`}
            >
              <div className="image-wrapper">
                <Image img={destination.destination_img} />
              </div>
              <div className="stop-name">{t(destination.stop.name)}</div>
            </a>
          );
        })}
      </div>

      {/* Links generated for SEO crawlers */}
      <div className="departures__seo-links">
        {departures.map(departure => {
          return departure.destinations.map((destination, key) => {
            return (
              <SeoLink
                key={`${destination.departureTurnitStopId}-${destination.destinationTurnitStopId}-${key}`}
                href={generateWebshopJourneyLink({
                  destinationStopId: destination.destinationTurnitStopId,
                  departureId: destination.departureTurnitStopId,
                })}
              >
                {t(destination.stop.name)}
              </SeoLink>
            );
          });
        })}
      </div>
    </div>
  );
}
