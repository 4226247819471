import React from 'react';

export default function PrevIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="white" />
      <path
        d="M14.4697 6.8637C14.3447 6.73757 14.1745 6.66663 13.997 6.66663C13.8195 6.66663 13.6493 6.73757 13.5243 6.8637L8.86362 11.5275C8.73757 11.6526 8.66667 11.8229 8.66667 12.0005C8.66667 12.1782 8.73757 12.3485 8.86362 12.4736L13.5243 17.1373C13.6932 17.3063 13.9393 17.3723 14.17 17.3105C14.4007 17.2486 14.5809 17.0683 14.6428 16.8374C14.7046 16.6066 14.6386 16.3603 14.4697 16.1913L10.2751 12.0005L14.4697 7.80978C14.5958 7.68468 14.6667 7.51439 14.6667 7.33674C14.6667 7.15909 14.5958 6.9888 14.4697 6.8637Z"
        fill="black"
      />
    </svg>
  );
}
