import React from 'react';
import { useAppData } from '@optimistdigital/create-frontend/universal-react';
import Page from './Page';
import Heading from '../components/Heading/Heading';
import Body from '../components/Body/Body';
import useMobx from '@Shared/hooks/useMobx';
import MaxWidth from '@Shared/components/ui/MaxWidth';

export default function ErrorPage({ status = 404, message = null }) {
  const { t, content } = useMobx();

  const appData = useAppData();
  if (appData.serverContext) appData.serverContext.status = status;

  return (
    <Page className={['error-page', status].join(' ')}>
      <MaxWidth className="error-page__content">
        <Heading level="h3" visual="h1" underline>
          {status}
        </Heading>
        <Body large>{message || t(content.translations[`${status}_error`])}</Body>
      </MaxWidth>
    </Page>
  );
}
