import 'app/scss/entry.scss';
import { Helmet } from 'react-helmet-async';
import React, { useMemo } from 'react';
import Router from '@optimistdigital/create-frontend/universal-react/Router';
import Pages from './Pages';
import useMobx from '@Shared/hooks/useMobx';
import ScrollToTop from 'app/js/react/components/ScrollToTop';
import Header from '@Shared/components/header/Header';
import { PopupAlerts } from '@Shared/components/alerts/PopupAlert';
import PageLoader from './components/Loaders/PageLoader';
import { useAppData } from '@optimistdigital/create-frontend/universal-react';
import CookieNotice from './common/Gdpr/CookieNotice';
import config from '../helpers/config';
// eslint-disable-next-line no-unused-vars
import Footer from '@Shared/components/footer/Footer';
import { SharedCookieKeys } from '@Shared/constants';
import AnalyticScripts from './common/Helmet/AnalyticScripts';
import { A11yWidget } from '@turnit-ride/turnit-a11y-widget';
import ToastContainer from './components/Toasts/ToastContainer';
import PwaHistoryListener from '@Shared/components/pwa/PwaHistoryListener';
import ScreenReaderAnnouncer from '@Shared/components/toasts/ScreenReaderAnnouncer';
import { observer } from 'mobx-react-lite';
import SharedWebsiteProvider from './modules/Providers/SharedWebsiteProvider';
import AppPopup from './components/AppPopup';

function App() {
  const { content, i18n, cookies, t, ui, settings } = useMobx();
  const { pageData } = useAppData();

  const headerMenu = useMemo(() => content.getMenu('header-menu', i18n.locale) || {}, [i18n.locale]);
  const footerMenu = useMemo(() => content.getMenu('footer-menu', i18n.locale) || {}, [i18n.locale]);
  const footerRegion = useMemo(() => content.getRegion('footer-region') || {}, [i18n.locale]);
  const appRegion = useMemo(() => content.getRegion('app-popup-region') || {}, [i18n.locale]);
  const defaultA11yConfig = useMemo(() => cookies.get(SharedCookieKeys.A11Y_WIDGET), []);

  const onA11yConfChanged = conf => {
    settings.setHighContrast(conf.highContrast);
    cookies.setSharedCookie(SharedCookieKeys.A11Y_WIDGET, conf);
  };

  return (
    <SharedWebsiteProvider>
      <Helmet key="helmet">
        <html lang={i18n.locale}></html>
        <title>{content.seo.title || 'Turnit'}</title>
        <meta name="web-version" content={config.BUILD_VERSION} />
        <meta name="cms-version" content={content.meta.version} />
        <meta property="og:title" content={content.seo.title || 'Turnit'} />
        {content.seo.description && <meta name="description" content={content.seo.description} />}
        {content.seo.description && <meta property="og:description" content={content.seo.description} />}
        {content.seo.image && <meta property="og:image" content={content.seo.image} />}
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="site-wrapper" tabIndex="-1">
        <Router url={pageData.url}>
          <PwaHistoryListener />
          <AnalyticScripts />
          <Header menu={headerMenu} />
          <PopupAlerts />
          <ScreenReaderAnnouncer message={ui.screenReaderAnnouncement} />
          <ToastContainer />
          {content.meta.isCookieConsentEnabled && !settings.isTurnitApp && <CookieNotice />}
          <ScrollToTop type="fast" />
          <PageLoader />

          <Pages />
          {(settings.isTurnitApp ? content.meta.client !== 'ltg' : true) && (
            <Footer menu={footerMenu} region={footerRegion} />
          )}
        </Router>
      </div>
      {!settings.isTurnitApp && appRegion && <AppPopup data={t(appRegion?.data)} />}
      {!settings.isTurnitApp && (
        <A11yWidget
          theme={{ primary: '#000' }}
          defaults={defaultA11yConfig}
          siteWrapper="div.site-wrapper"
          onConfigurationChanged={c => onA11yConfChanged(c)}
          translations={{
            title: t('a11y.widget.title'),
            zoomIn: t('a11y.widget.zoom_in'),
            zoomOut: t('a11y.widget.zoom_out'),
            highContrastAndLinksUnderline: t('a11y.widget.high_contrast_underline_links'),
            reset: t('a11y.widget.reset'),
          }}
        />
      )}
    </SharedWebsiteProvider>
  );
}

export default observer(App);
