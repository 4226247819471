import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import RadioButton from '../inputs/RadioButton';
import IconButton from '../inputs/IconButton';
import LanguageIcon from '../../icons/LanguageIcon';
import { Tooltip } from '../ui/Tooltip';
import CollapsibleWrapper from '../ui/collapsible/CollapsibleWrapper';
import { useSharedData } from '../../Shared';
import { isClickEvent } from '../../utils/wcag';
import { callFlutterHandler } from '@Shared/utils/turnit-app';

const HeaderLocaleSelector = ({ disabled, device }) => {
  const { t, locales, setLocale, locale } = useSharedData();
  const [show, setShow] = useState(false);

  const localeKeys = Object.keys(locales);
  if (localeKeys.length <= 1) return null;

  return (
    <div className={`shared-header__locale-selector -${device}`}>
      {device === 'mobile' ? (
        <CollapsibleWrapper
          clicked={() => setShow(!show)}
          label={locale}
          icon={<LanguageIcon />}
          isOpen={show}
          name="header-locale-select"
          chevronColor="var(--nav-icon-color)"
          ariaLabel={t('components.headerLocaleSelect.aria.label')}
        >
          <ul className="remove-list-styles" role="radiogroup">
            {localeKeys.map(lKey => (
              <RadioButton
                key={lKey}
                height="16px"
                disabled={disabled}
                onClick={() => {
                  setLocale(lKey);
                  callFlutterHandler('LanguageChange', lKey);
                  setShow(false);
                }}
                name={`locale_${lKey}`}
                checked={lKey === locale}
              >
                {locales[lKey]}
              </RadioButton>
            ))}
          </ul>
        </CollapsibleWrapper>
      ) : (
        <Tooltip
          className="locale-selection-tippy"
          name="locale-selection"
          tooltipStyle={{ padding: '17px' }}
          interactive
          arrow={false}
          disableButton
          onClose={() => setShow(false)}
          visible={show}
          html={
            <ul className="remove-list-styles" role="radiogroup">
              {localeKeys.map(lKey => (
                <RadioButton
                  key={lKey}
                  height="16px"
                  disabled={disabled}
                  onClick={e => {
                    if (isClickEvent(e)) {
                      setLocale(lKey);
                      setShow(false);
                    }
                  }}
                  name={`locale_${lKey}`}
                  checked={lKey === locale}
                >
                  {locales[lKey]}
                </RadioButton>
              ))}
            </ul>
          }
        >
          {props => <LocaleButton {...props} onClick={() => setShow(true)} />}
        </Tooltip>
      )}
    </div>
  );
};

const LocaleButton = props => {
  const { locale } = useSharedData();

  return (
    <IconButton className="uppercase" ariaLabel="locale switcher" icon={<LanguageIcon />} label={locale} {...props} />
  );
};

export default observer(HeaderLocaleSelector);
