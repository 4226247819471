import React from 'react';
import Button from '../../../Button';

export default function ButtonSection({ button }) {
  return (
    <div className="content__button-section">
      <a
        href={button.button_target_static || button.button_target_page?.path}
        target={button.button_target_static ? '_blank' : '_self'}
      >
        <Button rounded="semi">{button.button_text}</Button>
      </a>
    </div>
  );
}
