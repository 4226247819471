import React, { useState, useEffect } from 'react';
import Image from '@Shared/components/ui/media/Image';
import CloseIcon from '@Shared/icons/CloseIcon';
import UAParser from 'ua-parser-js';
import { useSharedData } from '@Shared/Shared';

export default function AppPopup({ data }) {
  const [show, setShow] = useState(true);
  const [url, setUrl] = useState('#');
  const { t } = useSharedData();
  const OS = new UAParser().getOS().name;

  useEffect(() => {
    if (OS === 'iOS') {
      setUrl('itms-apps://apps.apple.com/lt/app/ltg-link/id1594730224');
    }
    if (OS === 'Android') {
      setUrl('market://details?id=app.tunit.tunit_flutter_mobile');
    }
  }, [OS]);

  return (
    show && (
      <div className="app-popup">
        <button className="close-icon" onClick={() => setShow(false)} aria-label={t('buttons.close')}>
          <CloseIcon />
        </button>
        <div className="icon-wrapper">
          <Image img={data.icon} alt={data.title} />
        </div>
        <div className="inner-wrapper">
          <div className="title">{data.title}</div>
          <div className="text">{data.text}</div>
        </div>
        <a href={url} target="_blank" rel="external">
          {t('buttons.open')}
        </a>
      </div>
    )
  );
}
