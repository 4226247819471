import React from 'react';
import classNames from 'classnames';
import { useSharedData } from '../Shared';

export default function DividerIcon({ ...props }) {
  const { divider } = useSharedData();

  return props.large && divider === 'ltg' ? (
    <svg
      width="5120"
      height="50"
      viewBox="0 0 5120 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMax slice"
      className={classNames('divider--ltg', props.className)}
    >
      <path
        d="M349.1 0.100006C341.3 0.100006 333.8 3.20001 328.3 8.70001L312.2 24.8L328.5 41.1C333.9 46.5 341.3 49.6 349 49.6H5120V0L349.1 0V0.100006Z"
        fill="#F8423A"
      />
      <path
        d="M296 8.69962C290.5 3.19962 283 0.0996094 275.2 0.0996094H0V49.6996H275.4C283.1 49.6996 290.4 46.5996 295.9 41.1996L312.2 24.8996L296 8.69962Z"
        fill="black"
      />
    </svg>
  ) : props.medium && divider === 'ltg' ? (
    <svg
      width="1024"
      height="24"
      viewBox="0 0 1024 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMax slice"
      className={classNames('divider--ltg', props.className)}
    >
      <path
        d="M168.491 0.0482941C164.727 0.0482941 161.107 1.54527 158.452 4.20121L150.682 11.9758L158.549 19.8471C161.155 22.4547 164.727 23.9517 168.443 23.9517L1024 23.9517V0H168.491V0.0482941Z"
        fill="#F8423A"
      />
      <path
        d="M142.863 4.20126C140.208 1.54532 136.588 0.0483398 132.824 0.0483398H0V24H132.92C136.636 24 140.16 22.5031 142.814 19.8954L150.681 12.0242L142.863 4.20126Z"
        fill="black"
      />
    </svg>
  ) : divider === 'ltg' ? (
    <svg
      width="5120"
      height="16"
      viewBox="0 0 5120 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMax slice"
      className={classNames('divider--ltg', props.className)}
    >
      <path
        d="M259.386 0.03228C256.875 0.03228 254.461 1.03027 252.69 2.8009L247.507 7.98399L252.755 13.2315C254.493 14.9699 256.875 15.9679 259.354 15.9679L5116 15.9678C5118.21 15.9678 5120 14.177 5120 11.9678V4C5120 1.79086 5118.21 3.0921e-08 5116 6.9064e-08L259.386 8.39233e-05V0.03228Z"
        fill="#F8423A"
      />
      <path
        d="M242.292 2.80084C240.521 1.03022 238.107 0.0322266 235.596 0.0322266H4C1.79087 0.0322266 0 1.82309 0 4.03223V12C0 14.2092 1.79086 16 4 16H235.66C238.139 16 240.489 15.0021 242.26 13.2636L247.507 8.01613L242.292 2.80084Z"
        fill="black"
      />
    </svg>
  ) : (
    <hr className={'divider--default ' + props.className} />
  );
}
