import React from 'react';

const IconButton = ({ icon, label, className, children, ariaLabel, ...props }) => {
  const hasText = !!children || !!label;

  return (
    <button aria-label={ariaLabel} className={['shared-icon-button', className].join(' ')} {...props}>
      <div className="_icon">{icon}</div>
      {hasText && (children || <span className="_label">{label}</span>)}
    </button>
  );
};

export default IconButton;
