import React from 'react';
import Chevron from '../../icons/Chevron';
import Image from '@Shared/components/ui/media/Image';
import { MenuItemLinkComponent } from '@Shared/utils/menu';

export const BANNER_ACTION_TYPES = {
  HOVER: 'banner-action-hover',
  BUTTON: 'banner-action-button',
  NONE: 'banner-action-none',
};
function Banner({ data, children, locale, className = '' }) {
  const target = data?.button_target ? data.button_target : '';

  return (
    <MenuItemLinkComponent
      className={['banner', className].join(' ')}
      url={(typeof target === 'object' && target[locale]) || '#'}
      aria-label="marketing banner"
    >
      <div className="banner__background">
        <Image img={data.background_image} />
      </div>
      <div className="banner__content">
        <span className="banner__title">{data.title[locale]}</span>
        <p className="banner__body">{data.subtitle[locale]}</p>
        <div className="banner__action">{children}</div>
      </div>
    </MenuItemLinkComponent>
  );
}

function BannerAction({ data, type, locale }) {
  const target = data?.button_target ? data.button_target : '';
  switch (type) {
    case BANNER_ACTION_TYPES.HOVER:
      return (
        <BannerHover
          title={data.slider_title[locale]}
          body={data.slider_subtitle[locale]}
          data={data}
          link={(typeof target === 'object' && target[locale]) || '#'}
          locale={locale}
        />
      );
    case BANNER_ACTION_TYPES.BUTTON:
      return (
        <BannerButton label={data.button_text[locale]} to={(typeof target === 'object' && target[locale]) || '#'} />
      );
    case BANNER_ACTION_TYPES.NONE:
      return null;
    default:
      return null;
  }
}

function BannerHover({ title, body, data, locale }) {
  return (
    <div className="banner-hover">
      <span className="banner-hover__title">{title}</span>
      <div className="banner-hover__content">
        <div className="banner-hover__body">{body}</div>
        <div className="banner-hover__link">
          <span>{data?.button_text[locale]}</span>
          <Chevron rotate={90} />
        </div>
      </div>
    </div>
  );
}

function BannerButton({ label, children, ...props }) {
  return (
    <button className="banner-button" {...props}>
      {label || children}
    </button>
  );
}

Banner.Hover = BannerHover;
Banner.Button = BannerButton;
Banner.Action = BannerAction;

export default Banner;
