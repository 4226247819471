import React from 'react';

export default function UserIcon({ size = '16', color = 'var(--nav-icon-color)' }) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00002 7.33333C9.47278 7.33333 10.6667 6.13943 10.6667 4.66667C10.6667 3.19391 9.47278 2 8.00002 2C6.52726 2 5.33335 3.19391 5.33335 4.66667C5.33335 6.13943 6.52726 7.33333 8.00002 7.33333ZM8.00002 3.33333C8.7364 3.33333 9.33335 3.93029 9.33335 4.66667C9.33335 5.40305 8.7364 6 8.00002 6C7.26364 6 6.66669 5.40305 6.66669 4.66667C6.66669 3.93029 7.26364 3.33333 8.00002 3.33333ZM2.66669 14C2.66669 11.0545 5.0545 8.66667 8.00002 8.66667C10.9455 8.66667 13.3334 11.0545 13.3334 14H12C12 11.7909 10.2092 10 8.00002 10C5.79088 10 4.00002 11.7909 4.00002 14H2.66669Z"
        fill={color}
      />
    </svg>
  );
}
