import { action, computed, makeObservable, observable } from 'mobx';

const SEEN_NOTICES_KEY = 'seenNotices';

export default class BaseNoticeStore {
  @observable seenUrgentNotices = [];
  @observable closedNotices = [];

  constructor() {
    makeObservable(this);

    this.seenUrgentNotices = this.getSeenUrgentNoticesFromLocalStorage();
  }

  @action markNoticeAsSeen(id) {
    this.seenUrgentNotices = [...this.seenUrgentNotices, id];
    if (typeof window !== 'undefined') localStorage.setItem(SEEN_NOTICES_KEY, this.seenUrgentNotices);
  }

  @action markNoticeAsClosed(id) {
    this.closedNotices = [...this.closedNotices, id];
  }

  @computed get latestUrgentNotices() {
    return this.unreadUrgentNotices.slice(0, 3);
  }

  @computed get unreadUrgentNotices() {
    return this.urgentNotices.filter(notice => !this.seenUrgentNotices.includes(notice.id));
  }

  getSeenUrgentNoticesFromLocalStorage() {
    if (typeof window === 'undefined') return [];
    return (window.localStorage?.getItem(SEEN_NOTICES_KEY) || '').split(',').map(Number).filter(Boolean);
  }
}
