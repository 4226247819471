import { observer } from 'mobx-react-lite';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import useMobx from '@Shared/hooks/useMobx';
import Chevron from '../../icons/Chevron';
import Heading from '../../components/Heading/Heading';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import PrevIcon from '../../icons/PrevIcon';
import NextIcon from '../../icons/NextIcon';

SwiperCore.use([Pagination, Navigation]);

function NoticesBoard({ notices = {}, title, seeMore }) {
  const {
    t,
    content,
    datetime: { format },
    i18n: { locale },
  } = useMobx();

  if (Object.values(notices).length <= 0) {
    return null;
  }

  return (
    <div className="notices-board">
      <div className="notices-board__heading">
        {title && (
          <Heading level="h2" className="notices-board__heading-title">
            {title}
          </Heading>
        )}
      </div>
      <div className="notices-board__body">
        <Swiper
          wrapperTag="ul"
          slidesPerView={1}
          spaceBetween={24}
          watchOverflow
          breakpoints={{
            768: {
              slidesPerView: 2,
              spaceBetween: 0,
            },
            1300: {
              slidesPerView: 3,
            },
          }}
          navigation={{
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          }}
          pagination={{
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
          }}
          onLock={() => {
            document.querySelector('.swiper-controls').classList.add('hidden');
          }}
          onUnlock={() => {
            document.querySelector('.swiper-controls').classList.remove('hidden');
          }}
          noSwipingClass="swiper-controls"
        >
          {notices.map(notice => {
            const noticeSlug = t(notice.slug, { fallback: false });

            return (
              <SwiperSlide tag="li" key={notice.id}>
                <div className="notices-board__item">
                  <p className="notices-board__item-title">{t(notice.title)}</p>
                  <p className="notices-board__item-body">{t(notice.summary)}</p>
                  <div className="notices-board__item-footer">
                    <span className="notices-board__item-footer-date">
                      {format(notice.created_at, 'MMM DD', locale)}
                    </span>
                    {noticeSlug && (
                      <Link
                        className="notices-board__item-footer-link"
                        to={generatePath(content.getPagePathByTemplate('notices-page'), { slug: noticeSlug })}
                      >
                        <span>{seeMore}</span>
                        <Chevron rotate={90} />
                      </Link>
                    )}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}

          <div className="swiper-controls">
            <button className="swiper-button-prev" aria-label={t('components.noticeBoard.swiper.aria.prev')}>
              <PrevIcon />
            </button>
            <div className="swiper-pagination-container">
              <div className="swiper-pagination"></div>
            </div>
            <button className="swiper-button-next" aria-label={t('components.noticeBoard.swiper.aria.next')}>
              <NextIcon />
            </button>
          </div>
        </Swiper>
      </div>
    </div>
  );
}

export default observer(NoticesBoard);
