export const goTo = url => {
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
};

export const generateWebshopUrl = url => {
  const deconstructedUrl = new URL(url);
  // yeet
  // deconstructedUrl.searchParams.append('redirectReferrer', window.location.hostname);

  return deconstructedUrl.toString();
};

export const goToWebshop = url => {
  return goTo(generateWebshopUrl(url));
};
