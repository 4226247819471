import React, { useMemo } from 'react';
import Chevron from '../../icons/Chevron';

export default function Select({
  className = '',
  onChange,
  children,
  value,
  placeholder,
  label,
  required,
  options,
  name,
  ...props
}) {
  const classNames = useMemo(() => {
    const _classNames = ['input-group select-wrapper'];
    if (className) _classNames.push(className);
    return _classNames;
  }, [className]);

  return (
    <div className={classNames.join(' ')}>
      <label>
        <div className="_label" htmlFor={name}>
          {label}
          {required && '*'}
        </div>
        <select className="" required={required} onChange={onChange} {...props}>
          {options.map((o, key) => {
            return (
              <option value={o.value || o.props.value} key={key}>
                {o.name || o.props.children}
              </option>
            );
          })}
        </select>
      </label>
      <div className="icon-wrapper">
        <Chevron rotate="180" />
      </div>
    </div>
  );
}
