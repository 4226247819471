import React, { useState } from 'react';
import { Portal } from 'react-portal';
import { useIsomorphicLayoutEffect } from 'react-use';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

function Toasts({ toasts = [], Comp }) {
  const [canRender, setCanRender] = useState();
  useIsomorphicLayoutEffect(() => setCanRender(true), []);

  if (!canRender) {
    // Avoid rendering while SSR
    return null;
  }

  if (!Comp) {
    console.warn('You have not defined a component for Toast');
    return null;
  }

  return (
    <Portal>
      <TransitionGroup className="shared-toast-container">
        {Object.values(toasts)
          .filter(Boolean)
          .map(toast => (
            <CSSTransition key={toast.id} classNames="toast--anim" timeout={250}>
              {<Comp toast={toast} />}
            </CSSTransition>
          ))}
      </TransitionGroup>
    </Portal>
  );
}

export default Toasts;
