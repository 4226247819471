import { action, computed, makeObservable, observable } from 'mobx';
import { get } from 'lodash';
import logWarning from '../../helpers/log';
import { Translations } from 'app/js/helpers/api/requests';
import { captureException } from '@sentry/browser';

const FALLBACK_LOCALE = 'dev';
export const SELECTED_COOKIE_NAME = 'selected_public_locale';

const fetchTranslations = async locale => {
  try {
    const { body } = await Translations.get(locale);
    return body;
  } catch (err) {
    console.error(err);
    captureException(new Error(`[I18n] Failed importing [${locale}] locale translations`), {
      extra: { error: get(err, 'body.message', err) },
    });
  }

  return null;
};

export default class I18n {
  @observable locale = FALLBACK_LOCALE;

  locales = [];
  translations = {};

  constructor(hydrateStore) {
    hydrateStore(this);
    makeObservable(this);
  }

  @computed get fallbackLocale() {
    return FALLBACK_LOCALE;
  }

  @computed get activeLocale() {
    return this.locales.find(l => l.slug === this.locale);
  }

  @action setTranslations(locale, translations) {
    this.translations[locale] = translations;
  }

  @action setLocale = async locale => {
    // Validate that locales exist and locale slug is valid and exists.
    if (Array.isArray(this.locales) && !this.locales.find(l => l.slug === locale)) {
      return;
    }

    if (!this.translations[locale]) {
      // Load translation
      const translations = await fetchTranslations(locale);
      this.saveTranslations(locale, translations);
    }

    this.rootStore?.cookies.setSharedCookie(SELECTED_COOKIE_NAME, locale);
    this.locale = locale;
  };

  async loadInitialTranslationFiles() {
    // Main file
    await this.setLocale(this.locale);

    // Fallback file
    if (!this.translations[this.fallbackLocale]) {
      const translations = await fetchTranslations(this.fallbackLocale);
      this.saveTranslations(this.fallbackLocale, translations);
    }
  }

  translate = (dataOrPath, options = [], fallback = true) => {
    if (!dataOrPath) return '';

    const shouldFallback = options.fallback !== undefined ? options.fallback : fallback;
    const translationValues = (Array.isArray(options) ? options : options.values) || [];

    if (typeof dataOrPath === 'object') {
      let translation = dataOrPath[this.locale];
      if (!translation && shouldFallback) translation = dataOrPath[this.fallbackLocale];
      return translation;
    }

    if (typeof dataOrPath === 'string') {
      const key = dataOrPath;

      let translation = get(this.translations, `${this.locale}`, {})[key];
      if (!translation) translation = get(this.translations, `${this.fallbackLocale}`, {})[key];
      if (!translation) return dataOrPath;
      return this.format(translation, translationValues);
    }

    return '';
  };

  @computed get localeKeyValues() {
    const localesObj = {};
    this.locales.map(locale => (localesObj[locale.slug] = locale.name));
    return localesObj;
  }

  saveTranslations(locale, translations = {}) {
    if (Object.keys(translations).length > 0) {
      this.setTranslations(locale, translations);
    }
  }

  format = (string, values = []) => {
    try {
      return string.replace(/%(\d+)/g, (_, m) => {
        return values[--m];
      });
    } catch (e) {
      logWarning('::Translations:: failed to add values(', values, ') for string(', string, ')');
      return string;
    }
  };
}
