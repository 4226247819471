import React from 'react';
import { Link } from 'react-router-dom';
import { useSharedData } from '../../Shared';
import { sanitizeUrl } from '../../utils/urls';

export default function WebshopLink({ href, to, children, ...props }) {
  const { isWebshop, webshopUrl } = useSharedData();
  const Anchor = isWebshop ? Link : 'a';
  const basePath = href || to;
  const path = isWebshop ? basePath : `${webshopUrl}/${sanitizeUrl(basePath)}`;
  const linkProps = isWebshop ? { to: path } : { href: path, rel: 'noreferrer noopener' };

  return (
    <Anchor {...linkProps} {...props}>
      {children}
    </Anchor>
  );
}
