import api from '.';
import { toJS } from 'mobx';

export const SiteContent = {
  getInitial: async (queryParams = {}) => await api.get('api/content/initial', { queryParams }),
  getPage: async (id, queryParams = {}) => await api.get(`api/content/pages/${id}`, { queryParams }),
};

export const SiteConfig = {
  getTheme: async () => await api.get('api/config/theme'),
};

export const NoticeContent = {
  getAll: async options => await api.get('api/notices', options),
  getLatest: async options => await api.get('api/notices/latest', options),
  get: async (slug, options) => await api.get(`api/notices/${slug}`, options),
};

export const BannerContent = {
  getAll: async (...props) => await api.get('api/banners', ...props),
};

export const StopsContent = {
  getAll: async options => await api.get('api/stops', options),
  get: async (id, options) => await api.get(`api/stops/${id}`, options),
};

export const BusinessFormContent = {
  sendFormData: async (values, pageId) => await api.post('api/submit', { jsonBody: { ...toJS(values), pageId } }),
};

export const FeedbackFormContent = {
  sendFormData: async formData =>
    await api.post('api/submit-feedback', {
      formBody: formData,
    }),
};

export const Turnit = {
  search: async options => await api.get('api/turnit/search', options),
  stops: async options => await api.get('api/turnit/stops', options),
  getAllTravelpasses: async () => await api.get('api/turnit/travel-passes'),
  getTravelPassByNumber: async (number, options) => await api.get(`api/turnit/travel-passes/${number}`, options),
  promoCode: {
    validate: async code => await api.get(`api/turnit/promo-code/${code}/validate`),
  },
};

export const Translations = {
  get: async locale => await api.get(`api/translations/${locale}`),
};
