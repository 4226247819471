import BaseSettingsStore from '@Shared/mobx/BaseSettingsStore';
import { action, makeObservable, observable } from 'mobx';

export default class Settings extends BaseSettingsStore {
  @observable highContrast = false;

  constructor(hydrateStore) {
    super();
    hydrateStore(this);
    makeObservable(this);
  }

  @action setHighContrast = highContrast => {
    this.highContrast = highContrast;
  };
}
