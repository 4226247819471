import React, { useEffect, useRef, useState } from 'react';
import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import { isClickEvent, isCloseEvent } from '../../utils/wcag';
import { TrapFocus } from './TrapFocus';

/**
 * Tooltip wrapper with accessibility support (close on esc, focus trap, move focus back when modal closes)
 */
export const Tooltip = ({
  children, // Tooltip triggers
  html, // Modal content
  name, // Used to generate unique ID-s to DOM nodes
  tooltipStyle = {},
  modalProps = {}, // [Required] Min req. props for accessibility
  buttonProps = {}, // [Required] Min req. props for accessibility, required only if "disableButton" is false
  getFocusRef, // [Optional] Function that should return DOM node, this is used to know where focus should be returned when modal closes
  wrapperClassName, // [Optional]
  closeDependencies = [], // [Optional] Any dependencies that close the modal on change
  onClose, // [Required on condition] Called when modal tries to close tooltip, required when "visible" prop is defined

  // Props below are for solving weird edge cases where default WCAG logic applied here is not feasible
  visible: visibleProp, // [Optional] By default visibility is handled by tooltip, if defined then also define "onClose"
  disableFocusHandling = false, // [Optional] Set to "true" if you don't want the focus to move in and out of tooltip automatically
  disableButton = false, // [Optional] Disables button with event listeners for more control
  disableFocusTrap = false, // [Optional] Disables focus trap, because trapping focus uses useEffect internally and this property cannot be changed after rendering

  ...tippyProps
}) => {
  const style = {
    padding: '19px 15px 19px 15px',
    fontSize: '16px',
    lineHeight: '19px',
    letterSpacing: '0.08px',
    maxWidth: '360px',
    ...tooltipStyle,
  };
  const tippyRef = useRef();
  const targetRef = useRef();
  const disableFocusTrapRef = useRef(disableFocusTrap); // Remember initial value so that hooks are not called conditionally
  const initialRenderRef = useRef(true);
  const initialInteractiveRef = useRef(tippyProps.interactive || false);
  const [localVisibility, setVisible] = useState(visibleProp || false);
  const visibilityRef = useRef(localVisibility);

  if (!!disableFocusTrap !== disableFocusTrapRef.current) {
    console.warn("Changing 'disableFocusTrap' value after initial render does not work because hooks depend on it!");
  }

  if (!!tippyProps.interactive !== initialInteractiveRef.current) {
    console.warn("Changing 'interactive' value after initial render does not work because hooks depend on it!");
  }

  // If "visibleProp" is not defined then we handle visibility ourselves
  let visible = visibleProp === void 0 ? localVisibility : visibleProp;
  // If tooltip is not "interactive" then we disable any visibility handling outside of tippy library
  if (!tippyProps.interactive) visible = void 0;

  // If tooltip is not reactive then we don't run hooks that are not necessary
  if (initialInteractiveRef.current) {
    // Closes modal when something in "closeDependencies" prop changes
    useEffect(() => {
      if (initialRenderRef.current) return;

      // Only hide when we control visibility in this component
      if (visibleProp === void 0) setVisible(false);
      if (onClose) onClose();
    }, closeDependencies);

    // Default WCAG behaviour for focus handling, can be disabled when "disableFocusHandling" is false
    useEffect(() => {
      // Disable focus handling if true
      if (disableFocusHandling) return null;

      // Close tooltip and take focus back when escape is pressed
      const onEscape = e => {
        if (isCloseEvent(e)) {
          // Only hide when we control visibility in this component
          if (visibleProp === void 0) setVisible(false);
          else onClose && onClose();
        }
      };

      visibilityRef.current = visible;

      // Only add event listeners when component is visible and remove them when not visible
      if (visible) window.addEventListener('keydown', onEscape);
      return () => visible && window.removeEventListener('keydown', onEscape);
    }, [visible]);
  }

  useEffect(() => {
    // Sets flag to false after rendering initially
    initialRenderRef.current = false;
  }, []);

  // Handles 4 different child cases
  let triggerElement;
  if (disableButton && typeof children === 'function') {
    // We dont wrap children with "button" element and call "children" prop instead
    const childFnProps = {
      onClick: () => setVisible(true),
      onKeyDown: e => isClickEvent(e) && setVisible(true),
      role: 'button',
      tabIndex: 0,
    };

    triggerElement = (
      <div className={`_tippy ${wrapperClassName}`} ref={targetRef}>
        {children(childFnProps)}
      </div>
    );
  } else if (!tippyProps.interactive && typeof children === 'function') {
    // Regular tooltip case - show tooltip on hover
    const childFnProps = {
      role: 'button',
      tabIndex: 0,
    };

    triggerElement = children(childFnProps);
  } else if (tippyProps.interactive && disableButton) {
    // We dont wrap children with "button" element but tooltip is interactive
    triggerElement = (
      <div className={`_tippy ${wrapperClassName}`} ref={targetRef}>
        {children}
      </div>
    );
  } else if (!tippyProps.interactive && typeof children !== 'function') {
    // NB! With this case WCAG support has to be added manually
    triggerElement = children;
  } else {
    // Should be used for most cases for triggering interactive modal/tooltip
    triggerElement = (
      <button
        type="button"
        className={`_tippy btn ${wrapperClassName}`}
        {...buttonProps}
        onClick={tippyProps.interactive ? () => setVisible(true) : void 0}
        ref={targetRef}
      >
        {children}
      </button>
    );
  }

  return (
    <Tippy
      maxWidth={'auto'}
      arrow={false}
      theme="light"
      {...tippyProps}
      onClickOutside={visibleProp === void 0 ? () => setVisible(false) : onClose}
      visible={visible}
      content={
        <div style={style} ref={tippyRef} id={`tooltip-${name}`} role="dialog" aria-live={visible} {...modalProps}>
          <TrapFocus triggerBtnRef={targetRef} visible={visible}>
            {html}
          </TrapFocus>
        </div>
      }
    >
      {triggerElement}
    </Tippy>
  );
};

Tooltip.propTypes = {
  arrow: PropTypes.bool,
  html: PropTypes.node,
  trigger: PropTypes.string,
  placement: PropTypes.string,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,

  // Emits warning if "visible" is defined but "onClose" is not
  visible: (props = {}) => {
    if (props.visible !== void 0 && !props.onClose) {
      console.warn(
        `Tooltip${name ? ` "${name}"` : ''} prop "onClose" is missing, "onClose" is required if "visible" is provided.`
      );
    }
  },
};

Tooltip.defaultProps = {
  arrow: false,
  placement: 'bottom',
  className: 'tippy-tooltip',
  wrapperClassName: '',
  appendTo: typeof document !== 'undefined' && document.body,
  zIndex: 99999,
};
