import React from 'react';

export default function CloseIconSmall({ ...props }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16.9999 7.99999C17.0023 7.59372 16.7586 7.22641 16.3834 7.07062C16.0082 6.91482 15.576 7.00153 15.2899 7.28999L11.9999 10.59L8.70994 7.28999C8.31782 6.89786 7.68206 6.89786 7.28994 7.28999C6.89782 7.68211 6.89782 8.31786 7.28994 8.70999L10.5899 12L7.28994 15.29C6.89782 15.6821 6.89782 16.3179 7.28994 16.71C7.68206 17.1021 8.31782 17.1021 8.70994 16.71L11.9999 13.41L15.2899 16.71C15.6821 17.1021 16.3178 17.1021 16.7099 16.71C17.1021 16.3179 17.1021 15.6821 16.7099 15.29L13.4099 12L16.7099 8.70999C16.8971 8.52127 17.0015 8.26579 16.9999 7.99999Z"
        fill="currentColor"
      />
    </svg>
  );
}
