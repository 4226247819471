import { action, makeObservable, observable } from 'mobx';
import { BannerContent } from '../../helpers/api/requests';

export default class Banners {
  @observable data = [];
  @observable error = null;

  constructor(hydrateStore) {
    hydrateStore(this);
    makeObservable(this);
  }

  @action saveBanner(banner) {
    this.data.push(banner);
  }

  @action saveBanners(banners = []) {
    banners.forEach(banner => this.saveBanner(banner));
  }

  @action setError(error = {}) {
    this.error = error;
  }

  @action clearError() {
    this.setError(null);
  }

  @action async fetchBanners(...props) {
    try {
      // Attempt to request notices
      const response = await BannerContent.getAll(...props);
      this.saveBanners(response.body);
    } catch (error) {
      // Log to errors
      console.error('[fetchBanners]', error);
      this.error = error;
    }
  }
}
