import React from 'react';

export default function HamburgerIcon({ className, color = 'var(--nav-text-color)' }) {
  return (
    <svg
      className={className}
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 12V9H31.5V12H4.5ZM4.5 19.5H31.5V16.5H4.5V19.5ZM4.5 27H31.5V24H4.5V27Z"
        fill={color}
      />
    </svg>
  );
}
