import React from 'react';
import { AppDataContext } from '@optimistdigital/create-frontend/universal-react';

export const usePageData = () => {
  const appData = React.useContext(AppDataContext);

  if (!appData) {
    throw new Error('[usePageData] Not in AppData context.');
  }

  return appData.pageData;
};

export default usePageData;
