import React, { useEffect, useState } from 'react';
import { useMountedState } from 'react-use';
import { useSharedData } from '../../Shared';
import ShoppingCartIcon from '../../icons/ShoppingCartIcon';
import SlimCheckmarkIcon from '../../icons/SlimCheckmarkIcon';

const HeaderBasketButton = () => {
  const [basketExpired, setBasketExpired] = useState(false);
  const { showBasket, basketValidUntilUTC, webshopUrl } = useSharedData();
  if (!showBasket || !basketValidUntilUTC || basketExpired) return null;

  return (
    <a className="shared__header-basket-button" href={`${webshopUrl}/pay`}>
      <div role="button" tabIndex={0} className="basket">
        <div className="combined-icons">
          <div className="icon">
            <ShoppingCartIcon />
          </div>
          <div className="counter-icon">
            <div className="checkmark-icon">
              <SlimCheckmarkIcon color="#fff" />
            </div>
          </div>
        </div>
        <TimeToComplete validUntilUTC={basketValidUntilUTC} basketHasExpired={() => setBasketExpired(true)} />
      </div>
    </a>
  );
};

const TimeToComplete = ({ validUntilUTC, basketHasExpired }) => {
  const [time, setTime] = useState(moment());
  const isMounted = useMountedState();

  useEffect(() => {
    const interval = setInterval(() => {
      if (isMounted()) setTime(moment());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const diffInSeconds = moment(validUntilUTC).diff(time, 'seconds');
  if (diffInSeconds <= 0) {
    if (basketHasExpired) basketHasExpired(true);
    return null;
  }

  const min = Math.floor(diffInSeconds / 60);
  const sec = diffInSeconds % 60;
  const timeToComplete = `${min}:${sec < 10 ? `0${sec}` : sec}`;

  return <div className="timer">{timeToComplete}</div>;
};

export default HeaderBasketButton;
