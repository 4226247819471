import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import { callFlutterHandler } from '../../utils/turnit-app';

export default function PwaHistoryListener() {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const canGoBack = history.length !== 1 && location.pathname !== '/';
    callFlutterHandler('SetCanGoBack', canGoBack);
  }, [history.length, location.pathname]);

  return null;
}
