import React from 'react';

export default function CurrencyIcon({ color = 'var(--primary)' }) {
  return (
    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12C20 14.1217 19.1571 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20ZM13.0005 17H11.0005V16H9.00052V14H13.0005V13H11.0005C9.89595 13 9.00052 12.1046 9.00052 11V10C9.00052 8.89546 9.89595 8.00003 11.0005 8.00003V7.00003H13.0005V8.00003H15.0005V10H11.0005V11H13.0005C14.1051 11 15.0005 11.8955 15.0005 13V14C15.0005 15.1046 14.1051 16 13.0005 16V17Z"
        fill={color}
      />
    </svg>
  );
}
