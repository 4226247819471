import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import Page from './Page';
import usePage from '../hooks/usePage';
import ContentLayout from '../common/Layout/ContentLayout';
import MaxWidth from '@Shared/components/ui/MaxWidth';
import { useFormik } from 'formik';
import useMobx from '@Shared/hooks/useMobx';
import * as Yup from 'yup';
import Button from '../components/Button';
import { FeedbackFormContent } from '../../helpers/api/requests';
import CheckIcon from '../icons/CheckIcon';
import Heading from '../components/Heading/Heading';
import TextInput from '../components/Inputs/TextInput';
import RadioButton from '@Shared/components/inputs/RadioButton';
import Checkbox from '../components/Checkbox/Checkbox';
import Textarea from '../components/Inputs/Textarea';
import { useDropzone } from 'react-dropzone';
import PassportIcon from '../icons/PassportIcon';
import classNames from 'classnames';

const FeedbackFormPage = observer(({ ...props }) => {
  const page = usePage();
  const data = page.data;

  const { i18n } = useMobx();

  const locale = i18n.locale;
  const [formSent, setFormSent] = useState(false);
  const [selectedComplaints, setSelectedComplaints] = useState([]);
  const [fileError, setFileError] = useState('');

  const clearComplaints = () => {
    setSelectedComplaints([]);
    formik.setFieldValue('complaints', []);
  };

  const toggleComplaint = complaint => {
    let currentComplaints = formik.values.complaints;

    if (currentComplaints.includes(complaint)) {
      currentComplaints = currentComplaints.filter(c => c !== complaint);
      formik.setFieldValue('complaints', currentComplaints);
      setSelectedComplaints(currentComplaints);
      return;
    }
    currentComplaints = [...currentComplaints, complaint];
    setSelectedComplaints(currentComplaints);
    formik.setFieldValue('complaints', currentComplaints);
  };

  const submitForm = async values => {
    setFileError('');

    if (
      (selectedComplaints.includes(data.compensation_delays) || selectedComplaints.includes(data.compensation_costs)) &&
      Object.keys(values.files).length === 0
    ) {
      setFileError(data.field_required);
      return;
    }

    values.complaints = JSON.stringify(values.complaints);

    const response = await FeedbackFormContent.sendFormData({
      ...values,
      pageId: page.id,
    });

    if (response.body.success) {
      setFormSent(true);
      window?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };
  const getAttachmentLabel = () => {
    return selectedComplaints.includes(data.compensation_delays) || selectedComplaints.includes(data.compensation_costs)
      ? data.add_attachments + '*'
      : data.add_attachments;
  };

  const formik = useFormik({
    initialValues: { feedbackType: 'request', complaints: selectedComplaints, lang: locale, files: {} },
    validationSchema: Yup.object({
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string()
        .email(data.invalid_email)
        .when('feedbackType', {
          is: 'complaint',
          then: Yup.string().email(data.invalid_email).required(data.field_required),
        }),
      phone: Yup.mixed()
        .typeError(data.invalid_phone)
        .when('feedbackType', {
          is: 'complaint',
          then: Yup.mixed().typeError(data.invalid_phone).required(data.field_required),
        }),
      feedback: Yup.string().max(5000, data.max_chars).required(data.field_required),
      feedbackType: Yup.string(),
      complaints: Yup.array().nullable(),
    }),
    onSubmit: values => {
      submitForm(values);
    },
  });

  const formFields = (
    <form onSubmit={formik.handleSubmit}>
      <div className="fields-wrapper">
        <Heading className="section__title" level="h4" visual="h3">
          {data.passenger_details_subtitle}
        </Heading>

        <div className="inputs-wrapper">
          <TextInput
            name="firstName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={formik.errors.firstName}
            label={data.first_name}
          />
          <TextInput
            name="lastName"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={formik.errors.lastName}
            label={data.last_name}
          />
          <TextInput
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            error={formik.errors.email}
            label={formik.values.feedbackType === 'complaint' ? data.email + '*' : data.email}
            required={formik.values.feedbackType === 'complaint'}
          />
          <TextInput
            name="phone"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            error={formik.errors.phone}
            label={formik.values.feedbackType === 'complaint' ? data.phone + '*' : data.phone}
            required={formik.values.feedbackType === 'complaint'}
          />
        </div>
      </div>
      <div className="fields-wrapper">
        <Heading className="section__title" level="h4" visual="h3">
          {data.feedback_subtitle}
        </Heading>
        <div className="radio-buttons">
          <RadioButton
            checked={formik.values.feedbackType === 'request'}
            onClick={() => {
              formik.setFieldValue('feedbackType', 'request');
              clearComplaints();
            }}
            height="20px"
            name={data.request}
          >
            {data.request}
          </RadioButton>
          <RadioButton
            checked={formik.values.feedbackType === 'complaint'}
            onClick={() => formik.setFieldValue('feedbackType', 'complaint')}
            height="20px"
            name={data.complaint}
          >
            {data.complaint}
          </RadioButton>
          <RadioButton
            checked={formik.values.feedbackType === 'gratitude'}
            onClick={() => {
              formik.setFieldValue('feedbackType', 'gratitude');
              clearComplaints();
            }}
            height="20px"
            name={data.gratitude}
          >
            {data.gratitude}
          </RadioButton>
        </div>
      </div>
      {formik.values.feedbackType === 'complaint' && (
        <div className="fields-wrapper">
          <Heading className="section__title" level="h4" visual="h3">
            {data.complaint_type_subtitle}
          </Heading>
          <div className="complaint-type-wrapper">
            <Checkbox
              label={data.buying_tickets}
              checked={!!selectedComplaints.find(c => c === data.buying_tickets)}
              onClick={() => toggleComplaint(data.buying_tickets)}
            ></Checkbox>
            <Checkbox
              label={data.discounts_and_concessions}
              checked={!!selectedComplaints.find(c => c === data.discounts_and_concessions)}
              onClick={() => toggleComplaint(data.discounts_and_concessions)}
            ></Checkbox>
            <Checkbox
              label={data.schedules}
              checked={!!selectedComplaints.find(c => c === data.schedules)}
              onClick={() => toggleComplaint(data.schedules)}
            ></Checkbox>
            <Checkbox
              label={data.additional_services}
              checked={!!selectedComplaints.find(c => c === data.additional_services)}
              onClick={() => toggleComplaint(data.additional_services)}
            ></Checkbox>
            <Checkbox
              label={data.loyalty}
              checked={!!selectedComplaints.find(c => c === data.loyalty)}
              onClick={() => toggleComplaint(data.loyalty)}
            ></Checkbox>
            <Checkbox
              label={data.customer_services}
              checked={!!selectedComplaints.find(c => c === data.customer_services)}
              onClick={() => toggleComplaint(data.customer_services)}
            ></Checkbox>
            <Checkbox
              label={data.cleanliness}
              checked={!!selectedComplaints.find(c => c === data.cleanliness)}
              onClick={() => toggleComplaint(data.cleanliness)}
            ></Checkbox>
            <Checkbox
              label={data.temperature}
              checked={!!selectedComplaints.find(c => c === data.temperature)}
              onClick={() => toggleComplaint(data.temperature)}
            ></Checkbox>
            <Checkbox
              label={data.situations_on_train}
              checked={!!selectedComplaints.find(c => c === data.situations_on_train)}
              onClick={() => toggleComplaint(data.situations_on_train)}
            ></Checkbox>
            <Checkbox
              label={data.quiet_area}
              checked={!!selectedComplaints.find(c => c === data.quiet_area)}
              onClick={() => toggleComplaint(data.quiet_area)}
            ></Checkbox>
            <Checkbox
              label={data.seating}
              checked={!!selectedComplaints.find(c => c === data.seating)}
              onClick={() => toggleComplaint(data.seating)}
            ></Checkbox>
            <Checkbox
              label={data.wifi}
              checked={!!selectedComplaints.find(c => c === data.wifi)}
              onClick={() => toggleComplaint(data.wifi)}
            ></Checkbox>
            <Checkbox
              label={data.quality_of_service}
              checked={!!selectedComplaints.find(c => c === data.quality_of_service)}
              onClick={() => toggleComplaint(data.quality_of_service)}
            ></Checkbox>
            <Checkbox
              label={data.bicycle_transportation}
              checked={!!selectedComplaints.find(c => c === data.bicycle_transportation)}
              onClick={() => toggleComplaint(data.bicycle_transportation)}
            ></Checkbox>
            <Checkbox
              label={data.covid}
              checked={!!selectedComplaints.find(c => c === data.covid)}
              onClick={() => toggleComplaint(data.covid)}
            ></Checkbox>
            <Checkbox
              label={data.delays}
              checked={!!selectedComplaints.find(c => c === data.delays)}
              onClick={() => toggleComplaint(data.delays)}
            ></Checkbox>
            <Checkbox
              label={data.compensation_delays}
              checked={!!selectedComplaints.find(c => c === data.compensation_delays)}
              onClick={() => toggleComplaint(data.compensation_delays)}
            ></Checkbox>
            <Checkbox
              label={data.compensation_costs}
              checked={!!selectedComplaints.find(c => c === data.compensation_costs)}
              onClick={() => toggleComplaint(data.compensation_costs)}
            ></Checkbox>
            <Checkbox
              label={data.other}
              checked={!!selectedComplaints.find(c => c === data.other)}
              onClick={() => toggleComplaint(data.other)}
            ></Checkbox>
          </div>
        </div>
      )}

      {selectedComplaints.includes(data.compensation_delays) && (
        <div className="additional-notice">
          <div className="subtitle">{data.compensation_delays}</div>
          <div className="notice-text" dangerouslySetInnerHTML={{ __html: data.compensation_delay_text }} />
        </div>
      )}
      {selectedComplaints.includes(data.compensation_costs) && (
        <div className="additional-notice">
          <div className="subtitle">{data.compensation_costs}</div>
          <div className="notice-text" dangerouslySetInnerHTML={{ __html: data.compensation_costs_text }} />
        </div>
      )}

      <div className="fields-wrapper">
        <Heading className="section__title" level="h4" visual="h3">
          {data.feedback + '*'}
        </Heading>
        <Textarea
          name="feedback"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.feedback}
          error={formik.errors.feedback}
          label={''}
          placeholder={data.feedback_placeholder}
          required
        ></Textarea>
      </div>

      <div className="fields-wrapper">
        <Heading className="section__title" level="h4" visual="h3">
          {getAttachmentLabel()}
        </Heading>
        <Dropzone data={data} formik={formik} fileError={fileError} />
      </div>

      <Button className={'form semi-rounded'} type="submit">
        {data.send}
      </Button>
    </form>
  );

  const successView = (
    <div className="success-view">
      <div className="success-wrapper">
        <div className="title">{data.form_sent}</div>
        <div className="icon-wrapper">
          <CheckIcon />
        </div>
        {data.success_text && <div className="success-text">{data.success_text}</div>}
      </div>
    </div>
  );

  return (
    <Page route={props.route} className={classNames({ '-success': formSent })}>
      <MaxWidth>
        <ContentLayout title={data?.title}>
          <ContentLayout.Body>
            {!formSent && data && formFields}
            {formSent && successView}
          </ContentLayout.Body>
        </ContentLayout>
      </MaxWidth>
    </Page>
  );
});

export default FeedbackFormPage;

function Dropzone({ data, formik, fileError }) {
  const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
    maxFiles: 5,
    maxSize: 5242880,
    multiple: true,
    onDrop: file => formik.setFieldValue('files', file),
  });

  return (
    <section className="file-input-container">
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <div className="files">
          {(acceptedFiles.length < 1 && (
            <div className="placeholder-wrapper">
              <PassportIcon />
              <p className="placeholder">{data.attachment_placeholder}</p>
              <p className="max-size">{data.max_size}</p>
            </div>
          )) ||
            acceptedFiles.map(file => (
              <div className="file" key={file.path}>
                {file.path}
              </div>
            ))}
        </div>
      </div>
      {fileRejections.map(({ errors }) =>
        errors.map(e => (
          <div className="_error-text" key={e.code}>
            {e.message}
          </div>
        ))
      )}
      <div className="_error-text">{fileError}</div>
    </section>
  );
}
