import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import Accordion from '../components/Accordion';
import Page from './Page';
import usePage from '../hooks/usePage';
import ContentLayout from '../common/Layout/ContentLayout';
import { debounce, get } from 'lodash';
import Heading from '../components/Heading/Heading';
import MaxWidth from '@Shared/components/ui/MaxWidth';

const FaqPage = observer(({ ...props }) => {
  const [selectedId, setSelectedId] = useState(0);
  const page = usePage();
  const data = page.data;
  const topics = (data?.content?.topics || []).filter(topic => topic.attributes.qa.length > 0) ?? [];

  useEffect(() => {
    // Update topics nav active state
    const sections = document.querySelectorAll('.topic');
    const sectionLinks = document.querySelectorAll('.content-layout__nav > a');

    const updateTopicsNavActive = () => {
      let current = sections?.[0]?.id;

      sections.forEach(section => {
        const sectionTop = section.offsetTop;

        if (scrollY >= sectionTop - 100) {
          current = section.id;
        }
      });

      sectionLinks.forEach(sectionLink => {
        const sectionLinkTarget = sectionLink.href.substring(sectionLink.href.indexOf('#') + 1);

        if (sectionLinkTarget === current) {
          sectionLink.classList.add('active');
        } else {
          sectionLink.classList.remove('active');
        }
      });
    };

    window.addEventListener('scroll', debounce(updateTopicsNavActive, 10));
  }, []);

  return (
    <Page route={props.route} className="faq-page">
      <MaxWidth>
        <ContentLayout title={data.title}>
          <ContentLayout.Nav>
            {topics.map((topic, key) => (
              <a
                href={`#${key}`}
                key={key}
                className={selectedId === key ? 'active' : ''}
                onClick={() => setSelectedId(key)}
              >
                {get(topic, 'attributes.topic')}
              </a>
            ))}
          </ContentLayout.Nav>
          <ContentLayout.Body>
            <div className="topics">
              {topics.map((topic, key) => (
                <div className="topic" key={key} id={key}>
                  <Heading className="topic__title" level="h4" visual="h3">
                    {get(topic, 'attributes.topic')}
                  </Heading>
                  {(get(topic, 'attributes.qa') ?? []).map((qa, i) => {
                    const question = get(qa, 'attributes.question');
                    const answer = get(qa, 'attributes.answer');
                    return (
                      <Accordion title={question} content={answer} key={i} forceOpen={(key === 0 && i === 0) || ''} />
                    );
                  })}
                </div>
              ))}
            </div>
          </ContentLayout.Body>
        </ContentLayout>
      </MaxWidth>
    </Page>
  );
});

export default FaqPage;
