import React, { useEffect, useMemo, useRef } from 'react';
import { Portal } from 'react-portal';
import UAParser from 'ua-parser-js';
import { observer } from 'mobx-react-lite';
import CloseIcon from '../../icons/CloseIcon';
import MobileDividerIcon from '../../icons/MobileDividerIcon';
import { usePwaModalListener } from '../pwa/PwaModalProvider';
import { CSSTransition } from 'react-transition-group';
import HeaderLogo from '../header/HeaderLogo';
import { useSharedData } from '../../Shared';
import useTrapFocus from '../../hooks/useTrapFocus';
import { findFocusableElement, isCloseEvent } from '../../utils/wcag';

const MobilePopup = ({
  content,
  show = false,
  sidebar = false,
  toggle,
  children,
  header,
  className = '',
  isNestedModal,
  closeModals,
  withDivider,
  parentRef,
  visible,
}) => {
  const { t } = useSharedData();
  const { removeModalPwaListener, addModalPwaListener } = usePwaModalListener();
  const modalRef = useRef();
  const headerRef = useRef();
  const hasBackButton = header;

  useTrapFocus(modalRef, visible);

  const focusParent = () => findFocusableElement(parentRef?.current)?.focus();
  const focusModal = () => findFocusableElement(modalRef.current)?.focus();

  const onEscape = e => isCloseEvent(e) && handleSoftClose();

  const handleSoftClose = () => {
    if (!hasBackButton) removeModalPwaListener();
    if (toggle) toggle();
    else if (closeModals) closeModals();
    focusParent();
  };

  const handleHardClose = () => {
    if (!hasBackButton) removeModalPwaListener();
    if (closeModals) closeModals();
    if (toggle) toggle();
    focusParent();
  };

  const lockBodyScroll = () => {
    const os = new UAParser().getOS();
    document.body.style.overflow = 'hidden';
    document.body.style.height = '100%';
    document.body.style.width = '100%';
    if (os === 'iOS') document.body.style.position = 'fixed';
  };

  const enableBodyScroll = () => {
    const os = new UAParser().getOS();
    document.body.style.overflow = 'auto';
    if (os === 'iOS') document.body.style.position = 'initial';
  };

  const handlePwaCloseCallback = () => {
    if (!hasBackButton) removeModalPwaListener();
    if (toggle) toggle();
    else if (closeModals) closeModals();
    findFocusableElement(parentRef?.current)?.focus();
  };

  useEffect(() => {
    if (show) {
      lockBodyScroll();
      focusModal();
      if (!hasBackButton) addModalPwaListener(handlePwaCloseCallback);
      window.addEventListener('keydown', onEscape);
    }

    return () => {
      if (show) {
        if (!isNestedModal) enableBodyScroll();
        if (!hasBackButton) removeModalPwaListener();
        window.removeEventListener('keydown', onEscape);
      }
    };
  }, [show]);

  const HeaderRender =
    !!header && header !== true ? (
      header
    ) : (
      <div className="header-wrapper">
        <HeaderLogo />

        <button
          className="close-button"
          onClick={handleHardClose}
          aria-label={t('components.mobileMenu.toggleClose.aria.label')}
          aria-expanded="true"
        >
          <CloseIcon />
        </button>
      </div>
    );

  const classNames = useMemo(() => {
    const _cls = ['shared-mobile-popup'];
    if (sidebar) _cls.push('-sidebar');
    if (header) _cls.push('-back-btn');
    if (className) _cls.push(className);
    return _cls.join(' ');
  }, [className, sidebar, header]);

  return (
    <CSSTransition classNames="popup-slide--anim" timeout={250} in={show} unmountOnExit>
      <Portal>
        <div ref={modalRef} className={classNames}>
          <div className="_content-scroll-wrap">
            {header && (
              <div ref={headerRef} className="popup-header-wrapper">
                {HeaderRender}
              </div>
            )}
            <div className="_content">
              {content || children}
              {withDivider && <MobileDividerIcon className="divider -mobile" large />}
            </div>
          </div>
        </div>
      </Portal>
    </CSSTransition>
  );
};

export default observer(MobilePopup);
