import React from 'react';

// Crawlable navigation link does is not visible or clickable by user.
export default function SeoLink(props) {
  return (
    <a className="links__seo" aria-hidden tabIndex="-1" {...props}>
      {props.children}
    </a>
  );
}
