import 'app/scss/entry.scss';
import React from 'react';
import { hydrateInitialStore } from '../mobx/hydrateInitialStore';
import { MobxStoreProvider } from '@Shared/hooks/useMobx';
import templates from '../config/templates';
import { matchPath } from 'react-router';
// eslint-disable-next-line no-unused-vars
import RootStore from '../mobx/stores';
import App from './App';

/**
 *
 * @param {{store: RootStore}} {store}
 */
function Main({ store }) {
  return (
    <MobxStoreProvider store={store}>
      <App />
    </MobxStoreProvider>
  );
}

export default Main;

/**
 * This function gets called once in the server, and in the client whenever the page changes.
 * The result ends up in the AppData.
 */
Main.getPageData = async (location, { store, ...props }) => {
  await store.content.setLoading(true);
  const params = new URLSearchParams(location.search);
  const previewToken = params.get('preview');

  // Ask initial data only once on the server, or client if SSR failed
  if (__TARGET__ === 'node' || window.__OCF_APP_DATA__.skippedSSR) {
    await hydrateInitialStore({
      store,
      previewToken,
    });
  }

  const routes = store.content.routes;
  const decodeLocation = decodeURIComponent(location.pathname);

  try {
    const currentRoute = routes.find(route => matchPath(decodeLocation, { path: route.path, exact: true }));
    if (!currentRoute) throw new Error('No route found');

    const template = templates[currentRoute.template];
    if (!template) throw new Error('No template found');

    // Temporary fix for webshop locale sharing

    let pageId = currentRoute.id;
    const isHomePage = currentRoute.path === '/';
    const localePageId = currentRoute.localeIds[store.i18n.locale];
    if (isHomePage && localePageId) pageId = localePageId;

    let currentPage = store.content.pages[pageId];
    if (pageId && !currentPage) currentPage = await store.content.fetchPageById(pageId, { preview: previewToken });
    store.content.setCurrentPage(currentPage);
    store.content.setCurrentMatch(null);
    if (currentPage?.locale) await store.i18n.setLocale(currentPage.locale);

    // Calls getPageData on the page that will be rendered, so it can fetch custom data if it needs to
    let pageData;
    const match = matchPath(decodeLocation, { path: currentRoute.path, exact: true });

    if (template.getPageData) {
      pageData = await template.getPageData({
        store,
        page: currentPage,
        match,
      });
    }

    store.content.setLoading(false);
    return prevState => ({
      ...pageData,
      location,
      config: prevState.config || props.config,
      url: (prevState.location && prevState.location.pathname) || location.pathname,
      routes: prevState.routes || routes,
      route: currentRoute,
      page: currentPage,
      store,
    });
  } catch (error) {
    console.error('[ERROR]', error.stack, location);

    return prevState => ({
      ...prevState,
      page: { data: {} },
      config: prevState.config || props.config,
      routes: prevState.routes || routes,
      url: (prevState.location && prevState.location.pathname) || location.pathname,
      store: prevState.store || store,
      error: error.statusCode || 500,
    });
  } finally {
    store.content.setLoading(false);
  }
};
