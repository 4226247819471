import React from 'react';

export default function ShoppingCartIcon({ color = 'var(--nav-icon-color)', rotate = 0 }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style={{ transform: `rotate(${rotate}deg)` }}>
      <g fill={color}>
        <polygon fill="none" points="7.35 11 7.85 14 18.1 14 18.44 11 7.35 11" />
        <polygon fill="none" points="18.66 9 18.88 7 6.68 7 7.01 9 18.66 9" />
        <circle cx="7" cy="20" r="2" />
        <circle cx="18" cy="20" r="2" />
        <path d="M5.87,14.33a2,2,0,0,0,2,1.67H18.11a2,2,0,0,0,2-1.78l.78-7a2,2,0,0,0-2-2.22H6.35L6.13,3.67A2,2,0,0,0,4.15,2H2V4H4.15ZM18.1,14H7.85l-.5-3H18.44Zm.78-7-.22,2H7L6.68,7Z" />
      </g>
    </svg>
  );
}
