import Image from '@Shared/components/ui/media/Image';
import { isExternalUrl, isWebshopUrl, isWebsiteUrl } from '@Shared/utils/urls';
import Chevron from 'app/js/react/icons/Chevron';
import classNames from 'classnames';
import { get } from 'lodash';
import React from 'react';

export default function LinksSection({ links = [] }) {
  return (
    <div className="content__links-section">
      {Object.values(links).map(({ attributes: link }, key) => {
        const hasImage = get(link, 'img.id', false);

        const isExternalLink = isExternalUrl(link.destination);
        const isWebshopLink = isWebshopUrl(link.destination);
        const isWebsiteLink = isWebsiteUrl(link.destination);
        const linkTarget = isWebshopLink || isWebsiteLink || !isExternalLink ? '_self' : '_blank';

        return (
          <a
            className={classNames('link', { 'with-image': hasImage })}
            target={linkTarget}
            href={link.destination}
            key={key}
          >
            {hasImage && <Image className="image" img={link.img} />}
            {link.icon && <Image className="icon" img={link.icon} />}
            <div className="content">
              {link.title && <div className="pre-title">{link.title}</div>}
              {link.text && <div className="text">{link.text}</div>}
            </div>
            {link.chevron && (
              <span className="icon-wrapper">
                <Chevron rotate="90" />
              </span>
            )}
          </a>
        );
      })}
    </div>
  );
}
