import React from 'react';
import Image from '@Shared/components/ui/media/Image';
import useMobx from '@Shared/hooks/useMobx';
import MapIcon from '../icons/MapIcon';

export default function Station({ data, serviceSubtitle }) {
  const { i18n } = useMobx();
  const locale = i18n.locale;

  return (
    <div className="station">
      <div className="image-wrapper">
        <Image img={data.stop_image} />
      </div>
      <div className="content">
        <div className="name">{data.name[locale]}</div>
        {data.turnit_stop.address.street && (
          <div className="address">
            <MapIcon />
            <span className="street">{data?.turnit_stop.address.street}</span>
          </div>
        )}
        {(data?.services.length > 0 || data?.services_near.length > 0) && (
          <div className="label">{serviceSubtitle}</div>
        )}
        <div className="services">
          {data?.services.map((service, key) => {
            return (
              <div className="service" key={key} aria-label={`service ${service.name[locale]}`}>
                <Image className="icon" img={service.icon} />
              </div>
            );
          })}
          {data?.services_near.map((service, key) => {
            return (
              <div className="service" key={key} aria-label={`service ${service.name[locale]}`}>
                <Image className="icon" img={service.icon} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
