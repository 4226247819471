import React from 'react';
import ContentLayout from '../../common/Layout/ContentLayout';
import Heading from '../../components/Heading/Heading';
import Body from '../../components/Body/Body';
import { NavLink } from 'react-router-dom';
import useMobx from '@Shared/hooks/useMobx';
import { generatePath, Redirect } from 'react-router';
import { observer } from 'mobx-react-lite';
import usePageData from '../../hooks/usePageData';
import ScrollToTop from '../../components/ScrollToTop';

function NoticePageContent() {
  const { notices, t, content } = useMobx();
  const { status, notice } = usePageData();
  const noNoticeFound = status === 'NOT_FOUND' || (status !== 'FOUND' && status !== 'UPDATE_PATH');

  if (!status) return null;
  if (noNoticeFound) return <Redirect to={{ pathname: '/404', state: { cause: 'NOTICE_NOT_FOUND' } }} />;
  // if (status === 'UPDATE_PATH') history.replace(generatePath(match.path, { slug: t(notice.slug) })); // move this fn to useEffect

  return (
    <ContentLayout title={t(notice.title)}>
      <ScrollToTop />
      <ContentLayout.Nav>
        {notices.notices.slice(0, 15).map(link => {
          return (
            // exact on ka katki kui urlis pole slugi
            <NavLink
              key={link.id}
              to={generatePath(content.getPagePathByTemplate('notices-page'), { slug: t(link.slug) })}
              exact
            >
              {t(link.title)}
            </NavLink>
          );
        })}
      </ContentLayout.Nav>
      <ContentLayout.Body>
        <Heading level="h4">{t(notice.summary)}</Heading>
        <Body large asHtml style={{ marginTop: 24 }}>
          {t(notice.body, { fallback: false })}
        </Body>
      </ContentLayout.Body>
    </ContentLayout>
  );
}

export default observer(NoticePageContent);
