import React, { createContext, useContext } from 'react';

export const StoreContext = createContext(null);

export const MobxStoreProvider = ({ store, children }) => {
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};

export default function useMobx() {
  const store = useContext(StoreContext);
  if (!store) throw new Error('Store is not initialized!');
  return store;
}
