import React from 'react';

export default function WarningIcon({ color = 'currentColor' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <g fill={color}>
        <path
          fill="none"
          d="M12,4a8,8,0,1,0,8,8A8,8,0,0,0,12,4Zm0,14a1,1,0,1,1,1-1A1,1,0,0,1,12,18Zm1-4a1,1,0,0,1-2,0V7a1,1,0,0,1,2,0Z"
        />
        <circle cx="12" cy="17" r="1" />
        <path d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" />
        <path d="M12,6a1,1,0,0,0-1,1v7a1,1,0,0,0,2,0V7A1,1,0,0,0,12,6Z" />
      </g>
    </svg>
  );
}
