import { useEffect, useRef } from 'react';
import { findFocusableElement } from '../utils/wcag';

/*
  NB! Only one trap can be visible at once if they are not nested.

  Usage example:

  // triggerRef is reference to the element that triggered the modal
  const SomeModal = ({ visible, setVisible, children, triggerRef }) => {
    const dropdownRef = useRef()

    useTrapFocus({ outerRef: dropdownRef, visible })

    useEffect(() => {
      // Close tooltip and take focus back to where it was before modal was opened
      const onEscape = e => isCloseEvent(e) && setVisible(false)

      // Only add event listeners when component is visible
      if (visible) window.addEventListener('keydown', onEscape)

      // If visibility prop has changed to false then move focus back to where it was before modal was opened
      if (!visible && triggerRef.current) findFocusableElement(triggerRef.current)?.focus()

      // Remove event listeners when component is not visible
      return () => visible && window.removeEventListener('keydown', onEscape)
    }, [visible])

    if (!visible) return null

    return (
      <div ref={dropdownRef}>
        <input data-firstelement />
        {children}
        <input data-lastelement />
      </div>
    )
  }
 */
export default function useTrapFocus(outerRef, visible) {
  const listenFocus = useRef(true);

  useEffect(() => {
    let listenFocusChange;

    // Only add event listener when component is visible, otherwise we pollute event system with empty listeners
    if (visible) {
      const ref = typeof outerRef === 'function' ? outerRef() : outerRef?.current;

      // Cant work without valid DOM node
      if (ref) {
        // Watches for focus change - if we see focus on either [data-(first/last)element] then we know we have to reset
        listenFocusChange = e => {
          if (!listenFocus.current) {
            return;
          }

          // listener can still run even when "visible" is false, ex. after pressing "Tab"
          if (!visible) return;

          if (document.activeElement.hasAttribute('data-lastelement')) {
            const focusElements = findFocusableElement(ref, { returnAll: true });
            if (!focusElements?.length) {
              console.warn('Did not find any elements in "outerRef" that could have focus!', ref);
              return;
            }

            // Jump back to first element
            e.preventDefault();
            e.stopPropagation();
            listenFocus.current = false;
            setTimeout(() => {
              focusElements[0].focus();
              listenFocus.current = true;
            }, 100);
          }

          if (document.activeElement.hasAttribute('data-firstelement')) {
            const focusElements = findFocusableElement(ref, { returnAll: true });
            if (!focusElements?.length) {
              console.warn('Did not find any elements in "outerRef" that could have focus!', ref);
              return;
            }

            // Jump back to last element
            e.preventDefault();
            e.stopPropagation();
            listenFocus.current = false;
            setTimeout(() => {
              focusElements[focusElements.length - 1].focus();
              listenFocus.current = true;
            }, 100);
          }
        };
      }

      document.addEventListener('focus', listenFocusChange, true);
    }

    return () => {
      if (visible) document.removeEventListener('focus', listenFocusChange, true);
    };
  }, [visible]);
}
