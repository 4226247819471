import classNames from 'classnames';
import React from 'react';

export default function Button({
  variant = 'primary',
  loading = false,
  squared = false,
  rounded = false,
  disabled = false,
  inverted = false, // Invert hover and active state
  height = 48,
  children,
  className,
  ...props
}) {
  return (
    <button
      className={classNames(
        'button',
        { squared, loading, inverted, [`${rounded}-rounded`]: rounded },
        variant,
        className
      )}
      disabled={loading || disabled}
      style={{ height }}
      {...props}
    >
      {children}
    </button>
  );
}
