import React, { useState } from 'react';
import { Tooltip } from '../ui/Tooltip';
import ChevronIcon from '../../icons/ChevronIcon';
import { useSharedData } from '../../Shared';
import UserIcon from '../../icons/UserIcon';
import WebshopLink from '../links/WebshopLink';
import { Link } from 'react-router-dom';
import PowerIcon from '../../icons/PowerIcon';

export const UserDropdown = ({ closeModals, isAdminAccount, setShowModal }) => {
  const [show, setShow] = useState(false);
  const { t, userName } = useSharedData();

  return (
    <div className="user-action-dropdown">
      <Tooltip
        name="sign-in-tooltip"
        wrapperClassName="sign-in-tooltip"
        maxWidth={350}
        html={
          <RoleSwitchdropDown
            closeModals={closeModals}
            setShow={setShow}
            isAdminAccount={isAdminAccount}
            setShowModal={setShowModal}
          />
        }
        interactive
        onClose={() => setShow(false)}
        visible={show}
        arrow
        disableButton
      >
        <span className="username" onClick={() => setShow(true)}>
          <div className="_icon">
            <UserIcon />
          </div>
          {userName || t('components.header.myAccount')}
          <div className="_icon">
            <ChevronIcon rotate={180} color={'var(--nav-text-color)'} />
          </div>
        </span>
      </Tooltip>
    </div>
  );
};

const RoleSwitchdropDown = ({ closeModals, setShow, setShowModal, isAdminAccount }) => {
  const { t, handleLogout } = useSharedData();

  return (
    <div className="header-account-dropdown">
      <WebshopLink
        className="_link"
        to="/account/dashboard"
        key="dashboard"
        onClick={() => {
          closeModals;
          setShow(false);
        }}
      >
        <span className="account-label">{t('components.header.myAccount')}</span>
        <div className="_icon">
          <ChevronIcon rotate={90} color={'var(--black)'} />
        </div>
      </WebshopLink>
      {isAdminAccount && (
        <WebshopLink
          className="_link"
          to="/admin/dashboard"
          key="admin-dashboard"
          onClick={() => {
            closeModals;
            setShow(false);
          }}
        >
          <span className="account-label">{t('components.header.business-account')}</span>
          <div className="_icon">
            <ChevronIcon rotate={90} color={'var(--black)'} />
          </div>
        </WebshopLink>
      )}
      <div className="link-wrapper">
        <Link
          className="_link"
          onClick={async () => {
            await handleLogout();
            closeModals();
            setShowModal(false);
          }}
          to="/"
          key="home"
        >
          <div className="_icon">
            <PowerIcon />
          </div>
          {t('components.header.signout')}
        </Link>
      </div>
    </div>
  );
};
